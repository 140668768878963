<template>
  <v-container class="section-md text-center text-sm-left">
    <v-row>
      <v-col class="col-10">
        <v-row class="mb-2" justify="start">
          <p class="text-main-title mb-1">DONNÉES PERSONNELLES</p>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Politique de confidentialité</p>
            </v-row>
            <v-row>
              <p class="text-style text-justify">
                  {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} s’engage à ce que la collecte et le traitement de vos données personnelles effectués à partir du site {{$store.getters.statusClient ? 'abs-group.fr':'autobilan-systems.com' }}
                soient conformes à la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l’informatique, aux fichiers et aux libertés (dite « Loi Informatique et Libertés »)
                et du Règlement (UE) n° 2016/679 du 27 avril 2016 dit « Règlement général sur la protection des données » (ou « RGDP »).
                Cette politique décrit la manière dont {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} s’engage à collecter, utiliser et protéger vos données personnelles.
              </p>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Pourquoi vos données sont-elles collectées ?</p>
            </v-row>
            <v-row>
              <p class="text-style mb-0 text-justify">
                  {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} s’engage à traiter vos données personnelles dans le strict respect de ces finalités, et n’entend en aucun cas les utiliser à d’autres fins.
                Vos données servent uniquement à des fins légitimes portées à votre connaissance et restent strictement confidentielles.
                Sur notre site {{$store.getters.statusClient ? 'abs-group.fr':'autobilan-systems.com' }}, les finalités pour lesquelles vos données sont collectées sont les suivantes :
              </p>
              <v-col class="mt-0 pt-0 col-10 text-justify">
              <ul class="my-1 text-style text-justify">
                <li>• Répondre à vos demandes d’information</li>
                <li>• Gérer vos candidatures pour un éventuel recrutement</li>
                <li>• Prendre en compte vos réclamations clients</li>
                <li>• Réaliser des statistiques d’audience du site internet</li>
                <li>• Permettre une expérience de navigation optimale</li>
              </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Quelles catégories de données sont collectées ?</p>
            </v-row>
            <v-row>
              <p class="text-style mb-0 text-justify">
                Les données personnelles collectées par le {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} sont uniquement nécessaires à la réalisation des finalités des traitements mis en œuvre. {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }}
                s’engage à ne pas collecter plus de données que nécessaire.
                Nous ne collectons que des données pertinentes adéquates et nécessaires.
                Les catégories de données collectées sont les suivantes :
              </p>
            </v-row>
            <v-row class="mb-1">
              <ul class="my-1 text-style">
                <li>• Civilité : nom, prénom</li>
                <li>• Contact : adresse email, téléphone</li>
              </ul>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Qui est destinataire de vos données ?</p>
            </v-row>
            <v-row>
              <p class="text-style text-justify">
                Seules les personnes habilitées ont accès à vos données
                L’accès à vos données personnelles est strictement limité aux personnes habilitées et déterminées d'{{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} et de notre/nos prestataire (s) ; partenaire ou
                sous-traitant dont les dispositions techniques et organisationnelles pour la protection de vos données sont établies. Ces personnes sont celles qui, de par leur fonction,
                sont légitimes à se voir communiquer vos données afin de réaliser les finalités qui vous ont été préalablement décrites.
                Le site {{$store.getters.statusClient ? 'abs-group.fr':'autobilan-systems.com' }} propose un lien hypertexte vers les réseaux sociaux Facebook, Twitter, LinkedIn, permettant de visiter sa page sur le réseau social concerné mais
                il n’y a pas de dépôt de cookies. N’ayant pas de contrôle sur l’usage de ces fonctionnalités par ces acteurs, nous vous invitons à prendre connaissance de la politique de gestion
                des données personnelles propre au réseau social concerné.
                Tout prestataire, partenaire ou sous-traitant amené, à cette occasion, à prendre connaissance de ces données à caractère personnel sera soumis à une obligation de confidentialité.
                Ils devront notamment s’interdire d’utiliser pour leur propre compte ou encore de communiquer à des tiers tout ou partie de ces données.
                Vos données personnelles ne seront jamais vendues, partagées, communiquées ou transférées à un tiers non autorisé par la présente politique de protection des données personnelles.
              </p>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Combien de temps sont conservées vos données collectées ?</p>
            </v-row>
            <v-row>
              <p class="text-style text-justify">
                Vos données sont conservées uniquement le temps nécessaire Conformément aux dispositions légales en vigueur, {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} ne conserve vos données personnelles
                que le temps nécessaire aux finalités pour lesquelles elles sont collectées et traitées.
              </p>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Comment {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} protège vos données personnelles ?</p>
            </v-row>
            <v-row>
              <p class="text-style text-justify">
                Vos données sont sécurisées {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }} met en place des moyens de sécurisation physique et logique afin de protéger vos données personnelles des accès non autorisés,
                de l’usage impropre, de la divulgation, de la perte et de la destruction. Il est toutefois de votre responsabilité de vous assurer que les terminaux que vous utilisez sont sécurisés de manière
                adéquate et protégés contre les logiciels malveillants tels que les chevaux de Troie et les virus. Vous êtes informé du fait que, sans des mesures de sécurité adéquates, vous courez le risque
                que vos données soient captées ou divulguées à des tiers non autorisés.
              </p>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" class="ml-1">
          <v-col class="col-9">
            <v-row>
              <p class="text-title mb-0">Quels sont vos droits sur vos données et comment les exercer ?</p>
            </v-row>
            <v-row>
              <p class="text-style text-justify">
                Vous gardez le contrôle sur vos données. Conformément aux dispositions de la Loi Informatique et Libertés et du RGPD, vous disposez, à tout moment, d’un droit d’accès, de rectification,
                de limitation, d’effacement des données personnelles vous concernant. Vous disposez également d’un droit d’opposition, pour motif légitime à leur
                traitement ultérieur et du droit de retirer votre consentement à tout moment. Vous pouvez exercer vos droits à tout moment auprès d'{{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }},
                par voie postale à l’adresse suivante : {{$store.getters.statusClient ? 'ABS-GROUP':'AutoBilan-Systems' }}, Arteparc – Bat F, Route de la Côte d’Azur, Lieudit Le Canet de Meyreuil - 13590 MEYREUIL
                ou par email à l’adresse suivante {{$store.getters.statusClient ? 'contact@abs-group.fr':'contact@autobilan-systems.com' }} Vous disposez également du droit d’introduire une réclamation auprès de la Commission
                Nationale de l’Informatique et des Libertés.<br><br>
                Si vous ne souhaitez pas faire l'objet de prospection commerciale téléphonique,
                vous pouvez vous inscrire gratuitement sur la liste d'opposition au démarchage téléphonique Bloctel.
                Pour en savoir plus sur les modalités d'inscription, consultez le site Internet www.bloctel.gouv.fr.
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextCGUComponent",
  mounted() {
    this.$vuetify.goTo(0)
  }
}
</script>

<style scoped>
.text-style{
  font-family: Lato, sans-serif;
  color: #8f8f8f;
  font-size: 15px!important;
}
.text-title{
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.78px;
  text-decoration-color: rgb(70,71,74);
}
.text-main-title{
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-decoration-color: rgb(70,71,74);
}
</style>
