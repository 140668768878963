<template>
    <div>
        <section class="section-full text-left bg">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Informatique</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                                <router-link v-bind:to="{name:'hub'}">
                                    <button>Accueil</button>
                                </router-link>
                            </li>
                            <li v-show="idSelected == null" class="active">Services</li>
                            <li v-show="idSelected !== null">
                                <button v-on:click="redirectRoot">Services</button>
                            </li>
                            <li v-if="idSelected !== null" class="active">{{ articleSelected.titre }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!--Section popular categories 1-->
        <v-container class="mt-5 mb-16">
            <v-row justify="center" v-if="idSelected == null">
                <v-col cols="8">
                    <v-row>
                    <span class="text-title">Les logiciels métiers « Mercure VL & PL »</span><br>
                    </v-row>
                    <v-row class="pt-0 mt-0 ml-2">
                        <hr>
                    </v-row>
                    <v-row class="pt-5">
                    <span class="text-style">
                          Des outils pour assurer l’exploitation de son ou ses centres, gérer l’activité à distance, permettre à vos clients de prendre rendez-vous en ligne, accroître la visibilité, la compétitivité et le service client et piloter le système qualité de vos centres de contrôle depuis votre bureau.
                      </span>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-0 my-0">
                <v-col cols="8" class="py-0 my-0">

                </v-col>
            </v-row>
            <v-row class="text-center" v-if="idSelected == null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" class="pt-1" v-for="article in articles.slice(0, 3)" :key="article.id">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <v-row class="text-center" v-if="idSelected == null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" class="pt-0" v-for="article in articles.slice(3)" :key="article.id">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <v-row v-if="idSelected !== null">
                <ArtComponent :theme="articleSelected"></ArtComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import ArtComponent from '../../components/ArtComponent.vue'

    export default {
        name: "Informatique",
        components :{
            ArtComponent
        },
        props: ['art', 'id','nameUrl'],
        mounted(){
            this.$vuetify.goTo(0)
            this.root = document.documentElement
            this.$store.commit('setStatusClient',false)
            this.root.style.setProperty('--btn-color', '#89B951')
            this.root.style.setProperty('--options-orange', '#89B951')

            if(this.nameUrl){
                this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==this.nameUrl)[0]
                this.idSelected = this.articleSelected.id
            }

        },
        methods:{
            redirectRoot(){
                this.resetArticle()
                this.$router.push(`/informatique`)
            },
            setArticles(currentNameUrl){
                this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==currentNameUrl)[0]
                this.idSelected = this.articleSelected.id
            },
            setArt(val){
                this.articleSelected = val
                this.idSelected = val.id
                this.$router.push(`/informatique/${val.nameUrl}`)
            },
            resetArticle(){
                this.idSelected= null
            }
        },
        watch:{
            'id':{
                handler(){
                    if(this.id===null){
                        this.resetArticle()
                    }
                }
            },
            'nameUrl':{
                handler(){
                    if(this.nameUrl){
                        this.setArticles(this.nameUrl)
                    }
                }
            }
        },
        data() {
            return {
                root:null,
                idSelected:null,
                articleSelected :'',
                articles: [
                    {
                        id: 1,
                        nameUrl:'mercure-vl',
                        img: '../images/img/Mercure-VL.png',
                        texte: "Logiciels métiers pour l'exploitation des centres de contrôle technique indépendants.",
                        titre: "Mercure VL",
                        textHighLight:"",
                        textList:[
                            "Connexion au planning de rendez-vous.",
                            "Base documentaire règlementaire.",
                            "Gestion automatique des compteurs d'exception.",
                            "Avertissement de l'utilisation de méthodes spécifiques.",
                            "Aperçu réel du procès-verbal avant édition.",
                            "Exportation de la comptabilité.",
                            "Relances papier/mail/SMS.",
                            "Possibilité de scanner des documents présentés.",
                            "TSP nouvelle génération avec tablette 7 pouces (prise de photo simple et rapide, réseau Wifi, autonomie 2 jours en utilisation intensive...)."
                        ],
                        clefs: [
                            {
                                title: "test",
                                icon: "linecons-small57 icon icon-lg",
                                fact: "test test test"
                            }
                        ]
                    },
                    {
                        id: 2,
                        nameUrl:'mercure-pl',
                        img: "../images/img/Mercure-PL.png",
                        texte: "Logiciels métiers pour l'exploitation des centres de contrôle technique indépendants.",
                        textList:[
                            "Connexion au planning de rendez-vous.",
                            "Base documentaire règlementaire.",
                            "Gestion automatique des compteurs d'exception.",
                            "Avertissement de l'utilisation de méthodes spécifiques.",
                            "Aperçu réel du procès-verbal avant édition.",
                            "Exportation de la comptabilité.",
                            "Relances papier/mail/SMS.",
                            "Possibilité de scanner des documents présentés.",
                            "TSP nouvelle génération avec tablette 7 pouces (prise de photo simple et rapide, réseau Wifi, autonomie 2 jours en utilisation intensive...)."
                        ],
                        titre: "Mercure PL",
                        textHighLight:"",
                    },
                    {
                        id: 3,
                        nameUrl:'mercure-rdv',
                        img: "../images/img/I-mercureRdv.jpeg",
                        texte:"Logiciel spécialisé dans la prise de rendez-vous, Mercure RDV assure depuis n'importe quel ordinateur relié à internet la gestion des RDV, VL et PL sur le même planning, et plus encore : ",
                        textList:[
                            "Expédition des SMS de confirmation de rendez-vous.",
                            "Gestion des lignes de contrôle.",
                            "Gestion des utilisateurs du planning.",
                            "Gestion des apporteurs d'affaires web.",
                            "Consultation des procès-verbaux et factures pour les professionnels."
                        ],
                        titre: "Mercure RDV",
                        textHighLight:"",
                    },
                    {
                        id: 4,
                        nameUrl:'mercure-web',
                        img: "../images/img/Mercure-Web.png",
                        texte: "Notre solution informatique pour accroitre la visibilité, la compétitivité et le service client.",
                        titre: "Mercure Web",
                        textList:[
                            "Un site internet pour l'image, la communication et de nouveaux services pour les clients.",
                            "Informations pratiques.",
                            "Réservation en ligne. ",
                            "Paiement en ligne."
                        ],
                        textHighLight:"",
                    },
                    {
                        id: 5,
                        nameUrl:'mercure-qualité',
                        img: "../images/img/I-mercureQualite.jpeg",
                        texte: "Pour piloter le système qualité de vos centres de contrôle technique.",
                        titre: "Mercure Qualité",
                        textList:[
                            "Un accès à la base documentaire règlementaire.",
                            "Une gestion informatisée des documents qualité avec la possibilité de vérifier et d'approuver informatiquement ces derniers.",
                            "Un suivi de la consultation des documents règlementaires et qualité par votre personnel.",
                            "Un suivi des procès-verbaux, du personnel et du matériel.",
                            "Un rappel automatique des entretiens et étalonnages des matériels, et de l'ensemble des tâches du SMQ à réaliser.",
                            "Une gestion et un suivi informatisés des non-conformités.",
                            "Une gestion et un suivi informatisés des réclamations client.",
                            "Une gestion et un suivi informatisés des audits et des visites DREAL.",
                            "La possibilité de dématérialiser l'ensemble des documents (attestation de stage, PV d'étalonnage, certificat de conformité ...).",
                            "Un forum et un chat pour dialoguer entre professionnels."
                        ],
                        textHighLight:"",
                    },
                    {
                        id: 6,
                        nameUrl:'mercure-access',
                        img: "../images/img/mercure_access.png",
                        texte: "Solution pour la gestion de l'activité à distance.",
                        titre: "Mercure Access",
                        textList:[
                            "Visualisation des informations du logiciel depuis n'importe quel ordinateur relié à internet.",
                            "Tableau de bord et statistiques d'activité des centres",
                            "Edition comptable.",
                            "Relances centralisées, relances automatisées, relances SMS et relances courrier à 4 ans."
                        ],
                        textHighLight:"",
                    },
                ],
            }
        }
    }
</script>

<style scoped>
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 17px!important;
    }
    .bg
    {
        background-image: url("../../../public/images/bandeaux/bandeau-informatique.jpg");
    }
    .text-title{
        font-family: Lato, sans-serif!important;
        font-size: 25px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
        color: #8f8f8f;
    }
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 17px!important;
    }
</style>
