<template>
    <div>
        <section class="section-full text-left bg" style="height: 250px">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Mon alerte Contrôle Technique</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-md text-left">
            <v-container class="container mb-0 pb-0">
                <h3 style="font-size: 1.6em">Je souhaite recevoir une alerte m'informant de la date de réalisation de mon contrôle technique.</h3>
                <hr>
                <v-form ref="form" v-model="valid" class="mb-5" v-if="sendData==false" lazy-validation>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="mt-0 pt-0" elevation="4">
                                    <v-card-title class="pa-0 ma-0 ml-4 mb-3 mt-4">
                                        <v-row class="pa-0 ma-0 mt-3">
                                            <v-col class="col-12 pa-0 ma-0">
                                                <span class="ma-0 pa-0 rounded text-title">Vos Informations personnelles</span>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                </v-card-text>
                                <v-row class="justify-center">
                                    <v-col class="col-10 px-5">
                                        <!--                                  Selection genre/société-->
                                        <v-radio-group row class="justify-center" v-model="civility" :rules="basicRules" v-on:change="resetNames">
                                            <v-row justify="start" align="center">
                                                <v-radio class="mr-5" label="Madame" value="Madame"></v-radio>
                                                <v-radio label="Monsieur" value="Monsieur"></v-radio>
                                                <v-radio :class="$vuetify.breakpoint.xsOnly ? 'mr-16 mt-5' : ''" label="Autre : société, association..." value="Autre"></v-radio>
                                            </v-row>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="10" md="5" class="px-3" v-if="civility!=='Autre'">
                                        <v-text-field v-model="lastName" :rules="nameRules" label="Nom" required></v-text-field>
                                    </v-col>

                                    <v-col cols="10" md="5" class="px-3" v-if="civility!=='Autre'">
                                        <v-text-field v-model="firstName" :rules="prenomRules" label="Prénom" required></v-text-field>
                                    </v-col>

                                    <v-col cols="10" md="10" class="px-3" v-if="civility==='Autre'">
                                        <v-text-field v-model="denomination" :rules="denominationRules" label="Dénomination" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="pb-5">
                                    <v-col cols="10" md="5" class="px-3">
                                        <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                    </v-col>
                                    <v-col cols="10" md="5" class="px-3">
                                        <v-text-field v-model="phone" :rules="phoneRules" label="Téléphone mobile" required></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row justify="center">
                        <v-col class="col-6" v-if="returnMessage!=null">
                            <v-alert dense text type="success" v-if="returnMessage==true">
                                Un email de confirmation vous a été envoyé
                            </v-alert>
                            <v-alert dense outlined type="error" v-if="returnMessage==false">
                                Cet email est déjà utilisé
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row justify="center" v-if="vehicules.length > 0">
                        <v-col cols="10">
                            <span class="ma-0 pa-0 h4">Vos Vehicules</span>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center" v-if="vehicules.length > 0" v-for="(elem,index) in vehicules" v-bind:key="index">
                        <v-col class="col-10">
                            <v-card class="pa-4" align="end">
                                <v-card-text class="mt-0 pt-0" elevation="4">
                                    <v-card-title class="pa-0 ma-0 ml-4 mb-3 mt-4">
                                        <v-row>
                                            <v-col class="col-10">
                                                <v-row align="end">
                                                    <span class="rounded text-title">Véhicule  {{index+1}}</span>
                                                </v-row>
                                            </v-col>
                                            <v-col>
                                                <v-row justify="end">
                                                    <v-btn fab x-small color="error" v-on:click="deleteVehicule(elem,index)">
                                                        X
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                </v-card-text>
                                <v-row class="justify-center">
                                    <v-col class="col-12 px-5 py-0">
                                        <!-- <v-row align="center" justify="center" class="ma-0 pt-3">-->
                                        <v-radio-group row class="justify-start" v-model="elem.immatriculationOrigin" v-on:change="checkRadioImma(elem)" mandatory>
                                            <v-radio label="Immatriculation française" value="true"></v-radio>
                                            <v-radio label="Immatriculation étrangère" value="false"></v-radio>
                                            <v-menu open-on-hover bottom offset-y rounded="2">
                                                <template v-slot:activator="{ on, attr }">
                                                    <v-btn v-on="on" icon v-bind="attr">
                                                        <v-icon>
                                                            {{ help }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list class="pa-0 ma-0">
                                                    <v-card class="pa-3">
                                                        <p class="text-body pa-0 ma-0">
                                                            Pour obtention d’une carte grise française.
                                                        </p>
                                                    </v-card>
                                                </v-list>
                                            </v-menu>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <!--                          Infos Vehicule-->
                                <v-row align="center" justify="center" class="ma-0 pt-3">

                                    <!--                              Immatriculation-->
                                    <v-col sm="12" md="4" lg="6" xl="4" class="ma-0 pt-0 pb-0">
                                        <v-text-field class="ma-0 pt-2 pb-0" v-model="elem.immatriculation" v-on:keyup="editImmatriculation(elem)"  label="Immatriculation" :rules="elem.immatriculationOrigin === 'true' ? immatriculationFrRules : immatriculationForeignRules" required></v-text-field>
                                    </v-col>

                                    <!--                              Marque-->
                                    <v-col class="ma-0 pt-0 pb-0" sm="12" md="4" lg="6" xl="4">
                                        <v-autocomplete class="ma-0 pt-2 pb-0"  v-model="elem.vehiculeMarque" :rules="basicRules"  :items="$store.getters.vehicules" item-text="ma_lib" placeholder="Marque Véhicule" required return-object></v-autocomplete>
                                    </v-col>

                                    <!--                              Modèle-->
                                    <v-col class="ma-0 pt-0 pb-0" sm="12" md="4" lg="12" xl="4">
                                        <v-autocomplete class="ma-0 pt-2 pb-0" v-model="elem.modeleVehicule" :rules="basicRules" :items="getModeles(elem)"  item-text="movl_lib_modele" placeholder="Modèle Véhicule" required></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row align="center" justify="start" class="ma-0 pt-3">
                                    <v-col cols="3" class="ma-0 pt-0 pb-0 px-5">
                                        <v-row>
                                            <span>Votre véhicule est-il neuf ?</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="justify-start ma-0 pa-0">
                                    <v-col cols="6">
                                        <v-radio-group row class="justify-center" v-model="elem.isNew" mandatory>
                                                    <v-radio label="Oui" :value="true"></v-radio>
                                                    <v-radio label="Non" :value="false"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>

                                    <v-row align="center" class="justify-start ma-0 pa-0">
                                    <v-col cols="12" lg="6" xl="5" class="py-0 my-0">
                                        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" required mandatory>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="dateVehicule" outlined :rules="basicRules" prepend-icon="mdi-calendar" locale="fr" clearable :label="elem.isNew ? 'Date de première mise en circulation' : 'Date du dernier contrôle technique périodique'" readonly v-bind="attrs" v-on="on" @click:clear="date = null" required mandatory></v-text-field>
                                            </template>
                                            <v-date-picker v-on:change="changeDateVehicule(elem)" :rules="basicRules" outlined v-model="elem.dateVehicule" @change="menu1 = false" :allowed-dates="allowedDate" locale="fr" :first-day-of-week="1" required mandatory></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </v-col>
                    </v-row>


                    <v-row justify="center">
                        <v-col cols="10" class="px-7">

                            <v-row class="my-5" justify="start">
                                <v-col>
                                    <v-row justify="start">
                                        <v-btn
                                                class="rounded-pill"
                                                large
                                                depressed
                                                color="var(--btn-color)"
                                                v-on:click="addVehicule"
                                        >
                                            <span style="color: white">Ajouter un véhicule</span>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row justify="end">
                                        <v-btn
                                                large
                                                depressed
                                                color="var(--btn-color)"
                                                v-on:click="validate"
                                                class="rounded-pill"
                                        >
                                            <span style="color: white">Valider</span>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row justify="center">
                    <v-col class="col-6" v-if="sendData==true">
                        <v-alert
                                dense
                                text
                                type="success"
                        >
                            Un email de confirmation vous a été envoyé
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-dialog
                            class="ma-0 pa-0"
                            v-model="dialogWarningVehicule"
                            width="unset"
                            persistent
                    >
                        <v-alert
                                class="ma-0 "
                                prominent

                                type="error"
                        >Vous devez ajouter au moins un véhicule</v-alert>
                    </v-dialog>
                </v-row>
            </v-container>
        </section>


    </div>
</template>

<script>
    import {mdiHelpCircle} from '@mdi/js'

    export default {
        name: "AlerteCtComponent",
        computed:{
            computedDateFormatted(elem){
                return this.formatDate(elem.dateVehicule)
            }
        },
        data(){
            return{
                alertNbVehicule:false,
                help:mdiHelpCircle,
                denomination:'',
                sendData:false,
                menu1:'',
                dateVehicule: '',
                datePicker:'',
                returnMessage:null,
                civility:'Monsieur',
                firstName:'',
                lastName:'',
                email:'',
                numVoie:'',
                libelleVoie:'',
                complementAdresse:'',
                phone:'',
                codepostal:'',
                ville:'',
                vehicules:[],
                nbVehicules:0,
                valid: false,
                dialogWarningVehicule:false,
                nameRules: [
                    v => !!v || 'Name is required',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ],
                show1: false,
                show2: true,
                show3: false,
                show4: false,
                password: '',
                passwordConfirmation:'',
                basicRules: [v => !!v || 'Champs vide'],
                denominationRules: [
                    v => !!v || 'Saisir votre dénomination ',
                    v => /^([A-ZÉÈÊ ]{2,}$|^[A-ZÉÈÊ ]{2,}-[A-ZÉÈÊ ]{1,}$)/.test(v) || 'Dénomination non valide'
                ],
                passwordRules:[
                    v => !!v || 'Saisir votre mot de passe',
                    v => /^[A-ZÉÈÊa-zéèê~"#'{(-|`_ç^à@)°=}+^¨$£%ùµ*?,;.:!§]{8,}$/.test(v) || '8 caractères nécessaires'
                ],
                passwordConfirmationRules:[
                    v => !!v || 'Saisir votre confirmation de mot de passe',
                    v=> v == this.password || 'Ne correspond pas au mot de passe ',
                    v => /^[A-ZÉÈÊa-zéèê~"#'{(-|`_ç^à@)°=}+^¨$£%ùµ*?,;.:!§]{8,}$/.test(v) || 'Confirmation mot de passe non valide'
                ],
                immatriculationFrRules:[
                    v => !!v || 'Une immatriculation est nécessaire',
                    v => /^\d{3,4} [A-Z]{2,3} \d{2,3}$/.test(v) || /^[A-Z]{2}-\d{3,4}-[A-Z]{2}$/.test(v) || 'Format-SIV : AA-999-AA Format-FNI : 999 AAA 999 ou 9999 AA 999',
                ],
                immatriculationForeignRules:[
                    v => !!v || 'Une immatriculation est nécessaire',
                ],
                prenomRules:[
                    v => !!v || 'Saisir votre prénom',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || 'Prénom non valide'

                ],
                codepostalRules:[
                    v => !!v || 'Saisir votre Code Postal',
                    v => /^[0-9]{5}$/.test(v) && v.length <=5 || 'Code Postal non valide'

                ],
                numVoieRules:[
                    v => !!v || 'Saisie le numéro de rue,avenue...',
                    v => /^[0-9]{1,4}$/.test(v) || /^[0-9]{1,4} [A-Za-z]+$/.test(v) || 'Code Postal non valide'
                ],
                libelleVoieRules:[
                    v => !!v || 'Saisir votre avenue,rue,boulevard...',
                    v => /^[A-ZÉÈÊa-zéèê]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèê]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèê]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || / [A-ZÉÈÊa-zéèê]{1}[a-zéèê]+$/.test(v)
                        || /-[A-ZÉÈÊa-zéèê]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèê]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || 'Libellé voie non valide'
                ],
                villeRules:[
                    v => !!v || 'Saisir votre ville',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || / [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || 'Ville non valide'

                ],
                emailRules: [
                    v => !!v || 'Un E-mail est nécessaire',
                    v => /.+@.+\..+/.test(v) || 'E-mail non valide',
                ],
                nameRules: [
                    v => !!v || 'Saisir votre nom',
                    v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

                ],
                phoneRules: [
                    v => !!v || 'Un téléphone est nécessaire',
                    v => /^0[67]\d{8}$/.test(v) && v.length <=10|| 'Mobile non valide',
                ],//[0-9] = \d
            }
        },
        methods:{
            changeDateVehicule(elem){
                const currentDate = new Date(elem.dateVehicule)
                this.dateVehicule = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`
            },
            allowedDate(val){
                const today = new Date()
                const currentDate = new Date(val.split('-')[0],parseInt(val.split('-')[1])-1,val.split('-')[2])
                return currentDate < today
            },
            formatDate(date){
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            getMonth: value => parseInt(value.match(/(?<=-)\d+/)[0]),
            getYear: value => parseInt(value.match(/\d+(?=-)/)[0]),
            resetNames(){
                this.firstName = ''
                this.lastName = ''

            },
            addVehicule(){
                this.nbVehicules +=1
                const vehicule = {
                    immatriculationOrigin:true,
                    immatriculation:'',
                    vehiculeMarque:'',
                    modeleVehicule:'',
                    isNew:'',
                    dateVehicule:''
                }
                this.vehicules = [...this.vehicules,vehicule]
            },
            deleteVehicule(elem,index){
                this.vehicules = [...this.vehicules.slice(0,index),...this.vehicules.slice(index+1)]
            },
            checkRadioImma(elem){
                elem.immatriculation=''
            },
            getModeles(elem){
                return elem.vehiculeMarque ? elem.vehiculeMarque.modeles : []
            },
            editImmatriculation(elem){
                if(/[a-z]/.test(elem.immatriculation))
                    elem.immatriculation = elem.immatriculation.toString().toUpperCase()
                if(elem.immatriculationOrigin === "true"){
                    if(/^[A-Z]{2}$/.test(elem.immatriculation)){
                        elem.immatriculation = elem.immatriculation.concat('-')
                    }
                    if(/^[A-Z]{2}-\d{3}$/.test(elem.immatriculation))
                        elem.immatriculation = elem.immatriculation.concat('-')

                    if(/^\d{4}$/.test(elem.immatriculation))
                        elem.immatriculation = elem.immatriculation.concat(' ')

                    if(/^\d{3,4} [A-Z]{3}$/.test(elem.immatriculation))
                        elem.immatriculation = elem.immatriculation.concat(' ')
                }

            },
            async validate(){
                this.$refs.form.validate()

                if(this.$refs.form.validate() && this.vehicules.length===0){
                    this.dialogWarningVehicule = true
                    setTimeout(()=>{
                        this.dialogWarningVehicule=false
                    },2500)

                }


                if(this.$refs.form.validate() && this.vehicules.length>0){

                    const object = {
                        civility : this.civility,
                        lastName:this.lastName,
                        firstName:this.firstName,
                        email:this.email,
                        phone:this.phone,
                        vehicules:this.vehicules,

                    }

                    this.sendData = true
                }
            }
        },
        mounted() {
            this.$vuetify.goTo(0)
            this.$store.dispatch('getVehicules')
        },
        watch:{
            'denomination':{
                handler(value){
                    this.denomination = value.toString().toUpperCase()
                }
            },
            'password':{
                handler(){
                    this.passwordConfirmation=''
                }
            },
            deep:true,
            'firstName':{
                handler(value){
                    if(/^[a-zéèê]{1}$/.test(value)){
                        this.firstName=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                }
            },
            'ville':{
                handler(value){
                    if(/^[a-zéèê]{1}$/.test(value)){
                        this.ville=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)
                        || / [A-ZÉÈÊa-zéèê]{1}$/.test(value)
                        || /-[A-ZÉÈÊa-zéèê]{1}$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.ville = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.ville = value.substr(0,value.length-1).concat(char)
                    }

                }
            },
            'lastName':{
                handler(value){
                    this.lastName = value.toString().toUpperCase()
                }
            },
        }
    }
</script>

<style scoped>
    .bg {
        background-image: url("../../../public/images/bandeaux/monalertect.jpeg");
    }

    .text-intro{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-title{
        color: black;
        text-decoration-color: rgb(70,71,74);
    }
</style>
