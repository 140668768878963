<template>
    <v-container class="my-9 py-9">
        <!--BOURSE CT-->
        <!--<v-row class="ml-1 mt-1 pl-1 pt-1" justify="start">
            <span class="text-main-title">Mon budget CT</span>
        </v-row>-->



        <div>
            <v-dialog v-model="fieldDialog" width="650">
                <v-card class="pa-0 ma-0">
                    <field-form :field="field" @refresh="refreshClient"></field-form>
                </v-card>
            </v-dialog>
        </div>
        <v-row justify="center" class="px-5 mx-5 py-5">
            <v-col cols="12">
                <v-row justify="start">
                    <h3 style="font-size: 1.6em">Mon budget CT</h3><br>


                </v-row>
                <v-row>
                    <hr>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="6" class="mr-1 elevation-10">
                <v-row class="pa-2">
                    <v-card
                            width="100%"
                            elevation="0"
                    >
                        <v-card-text>
                            <v-row class="ml-1 mt-1 pl-1 pt-1">
                                <span class="text-main-title">Informations générales</span>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({civility:$store.getters.user.civility})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Civilité </span>
                                </v-col>
                                <v-col cols="3">
                                    <span class="field">{{client.civility}}</span>
                                </v-col>
                                <v-col cols="5">
                                    <v-row justify="end">
                                                    <span class="field">
                                                        <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                                    </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({lastName:client.lastName})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Nom </span>
                                </v-col>
                                <v-col cols="3">
                                    <span class="field">{{$store.getters.user.lastName}}</span>
                                </v-col>
                                <v-col cols="5">
                                    <v-row justify="end">
                                            <span class="field">
                                                <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                            </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" v-add-class-hover="'hover-field'" class="row-pointer px-3" v-on:click="changeField({firstName:client.firstName})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Prénom </span>
                                </v-col>
                                <v-col cols="3">
                                    <span class="field">{{$store.getters.user.firstName}}</span>
                                </v-col>
                                <v-col cols="5">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-row>
                <!--COORDONNES-->
                <v-row class="pa-2">
                    <v-card
                            width="100%"
                            elevation="0"
                    >
                        <v-card-text>
                            <v-row class="ml-1 mt-1 pl-1 pt-1">
                                <span class="text-main-title">Coordonnées</span>
                            </v-row>
                            <v-row align="center" class="px-3">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Email </span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field text-truncate">{{$store.getters.user.email}}</span>
                                </v-col>
                                <!-- <v-col cols="1">
                                     <v-row justify="end">
                                                     <span class="field">
                                                         <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                                     </span>
                                     </v-row>
                                 </v-col>-->
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" v-add-class-hover="'hover-field'" class="row-pointer px-3" v-on:click="changeField({phone:$store.getters.user.phone})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Téléphone</span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field">{{$store.getters.user.phone}}</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-row>
                <!--ADRESSE-->
                <v-row class="pa-2">
                    <v-card
                            elevation="0"
                            width="100%"
                    >
                        <v-card-text>
                            <v-row class="ml-1 mt-1 pl-1 pt-1">
                                <span class="text-main-title">Adresse</span>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({num_rue:$store.getters.user.num_rue})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Numéro voie</span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field">{{client.num_rue}}</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({nom_rue:$store.getters.user.nom_rue})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Libellé voie</span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field">{{client.nom_rue}}</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({complement_adresse:$store.getters.user.complement_adresse})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Complément adresse</span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field">{{client.complement_adresse}}</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({code_postal:client.code_postal})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Code postal</span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field">{{$store.getters.user.code_postal}}</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider class="ma-0 pa-0" col="12"></v-divider>
                            </v-row>
                            <v-row v-ripple align="center" class="row-pointer px-3" v-add-class-hover="'hover-field'" v-on:click="changeField({ville:$store.getters.user.ville})">
                                <v-col cols="4" class="text-truncate">
                                    <span class="field">Ville</span>
                                </v-col>
                                <v-col cols="7" class="text-truncate">
                                    <span class="field">{{$store.getters.user.ville}}</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-row justify="end">
                                <span class="field">
                                    <v-icon  size="35">{{ icons.mdiChevronRight }}</v-icon>
                                </span>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
            <v-col cols="4" class="ml-1 elevation-10"style="background-color: var(--btn-color)">
                <v-row class="pa-3 mt-0" justify="center">
                    <v-col style="background-color: white" class="rounded-xl">
                        <v-alert
                                v-if="$store.getters.user.state_bct==1 && $store.getters.user.amount_bct ==0 "
                                outlined
                                type="warning"
                                prominent
                                border="left"
                        >
                            Vous n'avez pas encore souscrit à votre bourse contrôle technique

                        </v-alert>
                        <v-col v-if="$store.getters.user.amount_bct>0">
                            <v-row><span class="text-title">Solde de ma bourse CT</span></v-row>
                            <v-row><span class="text-style rounded-pill pa-2" style="background-color: #F24C27;color: white">{{$store.getters.user.amount_bct ?(parseFloat($store.getters.user.amount_bct)/100).toFixed(2) : '0.00'}}&euro;</span></v-row>
                            <v-row v-if="$store.getters.user.state_bct==0"><span class="text-title"> Prochaine mensualité le : {{getNextPayment()}}</span></v-row>
                            <v-row class="pt-2" justify="center" v-if="$store.getters.user.state_bct==0">
                                <v-btn v-on:click="unsubscribeModal" style="background-color:var(--btn-color);color: white;font-size: 14px" class="btn btn-sushi btn-sm mr-2 pa-2 ma-0 px-2 rounded-pill"><span class="pa-2" style="font-size: 14px">Se désabonner</span></v-btn>
                            </v-row>
                        </v-col>
                        <v-row class="mt-0 pt-0 mb-2" justify="center" v-if="$store.getters.user.state_bct==1">
                            <v-btn v-on:click="subscribe" style="background-color:var(--btn-color);color: white;font-size:14px" class="btn btn-sushi btn-sm mr-2 pa-1 ma-0 px-2 rounded-pill">Souscrire</v-btn>
                        </v-row>
<!--                        <v-row class="mt-0 pt-0 mb-2" justify="center" v-if="$store.getters.user.state_bct == 1 ">
                            <v-btn v-on:click="subscribe" style="background-color:var(&#45;&#45;btn-color);color: white;font-size:14px!important;" class="btn btn-sushi btn-sm mr-2 pa-1 ma-0 px-2 rounded-pill"><span style="font-size: 14px">Se réabonner</span></v-btn>
                        </v-row>-->

                    </v-col>
                </v-row>
                <v-row>
                    <v-dialog max-width="1200" class="my-10" v-model="paymentFormulesDialog">
                        <v-card style="overflow: hidden;background-color: var(--btn-color)" class="ma-0 pa-0 rounded-xl" >
                            <payment-formules></payment-formules>
                        </v-card>
                    </v-dialog>
                </v-row>
                <v-row class="mt-2">
                    <hr class="myDivider">
                </v-row>


                <v-row justify="center">
                    <v-col><span class="text-title-1" >Mes rendez-vous</span></v-col>
                </v-row>
                <div style="background-color: #F24C27">
                    <v-row class="px-2 py-0 my-0">
                        <v-col>
                            <v-row justify="start" class="px-2 py-2">
                                <span class="text-style-table">Date heure</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table">Centre</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table" style="text-transform: capitalize">état</span>
                            </v-row>
                        </v-col>
                        <!--<v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table" style="text-transform: capitalize"></span>
                            </v-row>
                        </v-col>-->
                    </v-row>
                </div>
                <div class="border-table">
                    <v-row class="px-2 py-0 my-0" v-for="(rdv,index) in $store.getters.user.rdv" :key="index" v-if="$store.getters.user.rdv">
                        <v-col>
                            <v-row justify="start" class="px-2 py-2">
                                <span class="text-style-table">{{formatHeureRdv(rdv.heure_rdv,rdv.date_rdv)}}</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table">{{rdv.ctr_nom}}</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table" style="text-transform: capitalize">{{rdv.status_payment==0 ? 'Payé':'Non payé'}}</span>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 py-0 my-0" justify="center" v-if="!$store.getters.user.rdv">
                        <span class="text-style-table">Aucun rendez-vous </span>
                    </v-row>
                </div>
                <v-row class="mt-2">
                    <hr class="myDivider">
                </v-row>
                <v-row justify="center" class="mt-1">
                    <v-col><span class="text-title-1" >Mes prélèvements</span></v-col>
                </v-row>
                <div style="background-color: #F24C27">
                    <v-row class="px-2 py-0 my-0">
                        <v-col>
                            <v-row justify="start" class="px-2 py-2">
                                <span class="text-style-table">Date</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table">Montant</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table" style="text-transform: capitalize">état</span>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
                <div class="border-table">
                    <v-row class="px-2 py-0 my-0" v-for="(data,index) in $store.getters.user.bct" :key="index" v-if="client.bct">
                        <v-col>
                            <v-row justify="start" class="px-2 py-2">
                                <span class="text-style-table">{{formatDate(data.payment_date)}}</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table">{{((data.amount)/100).toFixed(2)}}€</span>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row justify="center" class="px-2 py-2">
                                <span class="text-style-table" style="text-transform: capitalize">{{getStatusBCT(data.status_bct)}}</span>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 py-0 my-0" justify="center" v-if="!$store.getters.user.bct">
                        <span class="text-style-table">Aucun prélèvement </span>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog
                    class="ma-0 pa-0"
                    v-model="paymentBCTSuccess"
                    width="unset"
            >
                <v-alert
                        class="ma-0 "
                        prominent

                        type="success"
                >Votre paiement a été effectué avec succès. Un email de confirmation vous a été envoyé.</v-alert>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                    class="ma-0 pa-0"
                    v-model="paymentBCTFailure"
                    width="unset"
            >
                <v-alert
                        class="ma-0 "
                        prominent

                        type="error"
                >Votre paiement a échoué, nous vous invitons à essayer de nouveau.</v-alert>
            </v-dialog>
        </v-row>
        <div class="text-center">
            <v-dialog
                    v-model="unsubscribeDialog"
                    width="500"
            >

                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        <span style="font-size: 14px">Se désabonner</span>
                    </v-card-title>

                    <v-card-text class="pa-4 text-justify">
                        Vous êtes sur le point de vous désabonner. Nous vous informons que votre abonnement sera suspendu et que le solde de votre cagnotte est conservé pendant 1 an après votre dernier prélèvement. Vous pouvez durant cette période vous réabonner à tout moment.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="white"
                                text
                                @click="unsubscribe"
                                class="btn btn-sushi btn-sm mr-2 pa-1 ma-0 px-2 rounded-pill"

                        >
                            <span style="font-size: 14px">Accepter</span>
                        </v-btn>
                        <v-btn
                                color="white"
                                text

                                style="font-size: 14px;background-color: red!important;"
                                @click="unsubscribeDialog = false"
                                class="mr-2 pa-1 ma-0 px-2 rounded-pill"
                        >
                            Annuler
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
    import { mdiChevronRight } from '@mdi/js';
    import fieldForm from "./fieldForm";
    import paymentFormulesBCT from "../payment/paymentFormulesBCT";
    export default {
        name: "bctSpace",
        components:{
            'field-form':fieldForm,
            'payment-formules':paymentFormulesBCT
        },
        data(){
            return{
                unsubscribeDialog:false,
                paymentBCTSuccess:false,
                paymentBCTFailure:false,
                paymentFormulesDialog:false,
                reveal:false,
                client:'',
                fieldDialog:false,
                field:'',
                bct:'',
                icons:{
                    mdiChevronRight
                },
                jourSemaine : ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
                mois : ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
            }
        },
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    /* el.removeEventListener('mouseenter');
                     el.removeEventListener('mouseleave')*/
                }
            }
        },
        methods:{
            unsubscribeModal(){
                this.unsubscribeDialog = true
            },
            async unsubscribe(){
                 await this.$store.dispatch("disableBct",localStorage.getItem('token'))
                const result = await this.$store.dispatch("getData",localStorage.getItem('token'))
                this.unsubscribeDialog = false
            },
            getStatusBCT(status){
                return status ==0 ?'Payé' : 'échoué'
            },
            formatDate(value){
                const currentDate = new Date(value)
                return `${this.setDigitFormat(currentDate.getDate())}/${this.setDigitFormat(currentDate.getMonth()+1)}/${currentDate.getFullYear()}`
            },
            formatDateCreneau(value){
                const currentDate = new Date(value)
                return `${this.setDigitFormat(currentDate.getDate())}/${this.setDigitFormat(currentDate.getMonth()+1)}/${currentDate.getFullYear()} à ${this.setDigitFormat(currentDate.getHours())}:${this.setDigitFormat(currentDate.getMinutes())}`
            },
            formatHeureRdv(heure,date){
                const currentDate = new Date(date)
                return`${this.setDigitFormat(currentDate.getDate())}/${this.setDigitFormat(currentDate.getMonth()+1)}/${currentDate.getFullYear()} à ${heure.match(/^[0-9]{2}:[0-9]{2}/)[0]} `
            },
            setDigitFormat(value){
                return value.toString().length ===1 ? '0'+value : value
            },
            getNextPayment(){
                if(this.$store.getters.user.bct.length > 0){
                    const {payment_date}= this.$store.getters.user.bct[this.$store.getters.user.bct.length-1]
                    const date = new Date(payment_date)
                    date.setMonth(date.getMonth()+1)
                    return `${date.getDate().toString().length===1 ? '0'+date.getDate().toString():date.getDate().toString()}/${date.getMonth().toString().length===1 ? '0'+(parseInt(date.getMonth())+1):parseInt(date.getMonth())+1}/${date.getFullYear()}`
                }

            },
            changeField(field){
                if(Object.getOwnPropertyNames(field)[0]!=='email'){
                    this.field = field
                    this.fieldDialog = true
                }
            },
            async subscribe(){
                this.paymentFormulesDialog=true
                /*if(this.client.email!== undefined){
                    const {webToken} = await this.$store.dispatch("initBctPayment",{email:this.client.email})
                    window.location.href = `https://sandbox-webkit.lemonway.fr/absgroup/dev/?moneyintoken=${webToken}`
                }*/
            },
            async refreshClient(data){
                const result = await this.$store.dispatch("getData",localStorage.getItem('token'))
                this.bct=result
                this.client = result
                this.fieldDialog=false


            }
        },
        async mounted() {

            const response = await this.$store.dispatch("getData",localStorage.getItem('token'))
            if(!this.$store.getters.user){
                this.$router.push({name:'BudgetCT'})
            }else {

                const {response_wkToken} = this.$route.query
                if(response_wkToken!==undefined){
                    const {payed} = await this.$store.dispatch("initBctRec",{reference:response_wkToken})
                    payed ? this.paymentBCTSuccess = true: this.paymentBCTFailure=true
                    const response = await this.$store.dispatch("getData",localStorage.getItem('token'))
                    this.$router.push({name:'perso-user'})

                }


                const sortByDateDesc = (date1,date2)=>{
                    if(new Date(date1.payment_date) < new Date(date2.payment_date)) return 1
                    if(new Date(date1.payment_date) > new Date(date2.payment_date)) return -1
                    return 0

                }

                if(response.bct){
                    const dataSorted = response.bct.sort(sortByDateDesc).slice(0,10)
                    response.bct=dataSorted
                    this.bct= response.bct
                }
                if(response === undefined)
                    this.$router.push('/budgetct')
                this.client = response


            }


        }
    }
</script>

<style scoped>
    .row-pointer {
        cursor: pointer;
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
    .field{
        font-family: Lato, sans-serif!important;
        font-size: 15px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .hover-field{
        background-color: #F5F5F5!important;
    }


    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 22px!important;
        font-weight: bold;
    }
    .text-title{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-title-1{
        font-family: Lato, sans-serif!important;
        font-size: 22px;
        color: white;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-style-table{
        font-family: Lato, sans-serif;
        color: white;
        font-size: 18px!important;
        font-weight: bold;
    }
    .border-table{
        border-bottom: 1px solid #F24C27;
        border-right:1px solid #F24C27;
        border-left:1px solid #F24C27;
    }
    .myDivider{ display: block; height: 1px;
        border: 0; border-top: 5px solid white;
        margin: 1em 0; padding: 0;
        width: 100%;
    }

</style>
