<template>
    <div>
        <section class="section-full text-left bg">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Qui sommes-nous ?</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                                <router-link v-bind:to="{name:'hub'}">
                                    <button>Accueil</button>
                                </router-link>
                            </li>
                            <li v-show="idSelected == null" class="active">Services</li>
                            <li v-show="idSelected !== null">
                                <button v-on:click="redirectRoot">Services</button>
                            </li>
                            <li v-if="idSelected !== null" class="active">{{ articleSelected.titre }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!--Section popular categories 1-->
        <v-container class="mt-5 mb-16">
            <v-row class="text-center" v-if="idSelected == null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" v-for="article in articles" :key="article.id">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <v-row v-if="idSelected !== null">
                <ArtComponent :theme="articleSelected"></ArtComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ArtComponent from "../../components/ArtComponent.vue";

export default {
    name: "Societe",
    components :{
        ArtComponent
    },
    props: ['art', 'id','nameUrl'],
    mounted(){
        this.root = document.documentElement
        this.$store.commit('setStatusClient',false)
        this.root.style.setProperty('--btn-color', '#89B951')
        this.root.style.setProperty('--options-orange', '#89B951')

        if(this.nameUrl){
            this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==this.nameUrl)[0]
            this.idSelected = this.articleSelected.id
        }

    },
    methods:{
        redirectRoot(){
            this.resetArticle()
            this.$router.push(`/qui-sommes-nous`)
        },
        setArticles(currentNameUrl){
            this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==currentNameUrl)[0]
            this.idSelected = this.articleSelected.id
        },
        setArt(val){
            this.articleSelected = val
            this.idSelected = val.id
            this.$router.push(`/qui-sommes-nous/${val.nameUrl}`)
        },
        resetArticle(){
            this.idSelected= null
        }
    },
    watch:{
        'id':{
            handler(){
                 if(this.id===null){
                   this.resetArticle()
               }
            }
        },
        'nameUrl':{
            handler(){
                if(this.nameUrl){
                    this.setArticles(this.nameUrl)
                }
            }
        }
    },
    data() {
        return {
            root:null,
            idSelected:null,
            articleSelected :'',
            articles: [
                {
                    id: 1,
                    img: '../images/img/S-Notre-savoir-faire.jpg',
                    texte:"Une solution globale intégrée, sans aucune sous-traitance, et un accompagnement quotidien personnalisé, pour suivre, répondre et développer votre centre de contrôle technique. Faire le choix d’AutoBilan-Systems, c’est l'assurance d'un interlocuteur direct, vous apportant toutes les solutions métiers au bon fonctionnement et développement de votre installation. <br><br>Les équipes d’AutoBilan-Systems sont à votre service pour répondre à toutes vos questions d’ordre technique, qualité, réglementaire et administrative. Les compétences, le sérieux et l’indépendance d’AutoBilan-Systems en font une référence dans le domaine du contrôle technique. <br><br>Plus de 30 collaborateurs qualifiés font de vos besoins et de votre réussite, leur priorité. La forte croissance d’AutoBilan-Systems est le reflet de la satisfaction de nos clients, nous les remercions pour leur confiance et les assurons de notre engagement. <br><br>Notre métier c’est aussi de vous accompagner dans le temps et de vous apporter les conseils et les solutions métiers qu’il vous faut au bon moment. AutoBilan-Systems, c'est plus de 15 ans d’expérience au service des professionnels du contrôle technique. ",
                    titre: "Notre savoir-faire",
                    nameUrl:'savoir-faire',
                    textHighLight:"",
                },
                {
                    id: 2,
                    img: '../images/img/S-controle-technique.jpg',
                    texte:
                        "AutoBilan-Systems apporte au contrôle technique, des véhicules légers (VL) et poids lourds (PL), tous les atouts de l’indépendance alliés à la sécurité et à l’appui de vrais professionnels du métier. AutoBilan-Systems c’est la rapidité et l’efficacité, de bons atouts pour votre développement. <br><br>En optimisant votre quotidien nous vous apportons rentabilité et gain de temps. Nous sommes à vos côtés de A à Z dans votre projet et vous apportons les solutions nécessaires pour le concrétiser. ",
                    titre: "Le contrôle technique",
                    nameUrl:'controle-technique',
                    textHighLight:"",
                },
                {
                    id: 3,
                    img: '../images/img/S-actualite.jpeg',
                    texte:
                        "AutoBilan-Systems a ouvert ses deux premiers sites de formation initiale d’inspection de véhicules légers (VL) et poids lourds (PL) (certification Qualiopi du processus de formation). Nos formateurs vous accompagnent de la théorie à la pratique. La formation initiale dispensée au sein de la société et par les formateurs d’AutoBilan-Systems pourra également faciliter, à nos clients, le recrutement des contrôleurs à l’issue de leur formation. Une équipe pour tous vos besoins AutoBilan-Systems  s’agrandit et accueille ses nouveaux stagiaires. Tous les nouveaux programmes de formation, comme ceux déjà élaborés, sont approuvés par le Ministre chargé des Transports. AutoBilan-Systems c’est plus de 30 collaborateurs qualifiés et compétents à votre service.",
                    titre: "Actualités",
                    nameUrl:'actualite',
                    textHighLight:"",
                },
                {
                    id: 4,
                    img: '../images/img/S-partenaires.jpg',
                    texte:
                        "Notre priorité, c’est vous. AutoBilan-Systems met à votre disposition tout son savoir-faire afin de vous accompagner dans votre projet de création d’un centre de contrôle technique indépendant, véhicules légers (VL) ou poids lourds (PL), de rachat ou de changement d’enseigne.  <br><br>AutoBilan-Systems vous apporte toutes les solutions métiers en interne grâce à une équipe compétente, disponible, réactive et stable. Le choix de l’indépendance c’est le choix de la liberté et de l’autonomie. <br><br><span class='font-weight-bold'>Plus de 300 centres de contrôle technique VL et PL nous font confiance dans la gestion globale de leurs installations. Pourquoi pas vous ? </span>",
                    titre: "Partenaires",
                    nameUrl:'partenaires',
                    textHighLight:"",
                },
            ],
        };
    },
};
</script>

<style scoped>
#articles
{
    text-align: center;
}
.bg
{
    background-image: url("../../../public/images/bandeaux/bandeau-quiSommesNous.jpeg");
    background-position: center;
}

</style>
