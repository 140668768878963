import axios from "axios";
import {userStore} from "./client/store";
import {paymentStore} from "./payment/store"

export const zonefrStore = {
    state:{
        cvc:false,
        currentClientPosition:'',
        communes:[],
        modName:"Zone Fr",
        centres:[],
        centresSelected:[],
        currentSearch:{},
        prestationSelected:{},
        dateSelected:'',
        dateHoraireSelected:'',
        vehicules:[],
        centreSelected:'',
        timeCentreSelected:'',
        positionClientFromGeo:'',
        allCentresWithDistances:[],
        retourTimeSlot:0,
        retourFormReservation:0,
        dispoDays:[],
        reservationVehiculesInformations:'',
        reservationClientInformations:'',
        promotionsSelected:[],
        finalPrice:'',
        registerCounter:0,
        retourFromFormPayment:0
    },
    getters:{
        cvc:state=>state.cvc,
        communes: state => state.centres.map((data)=>({...data,custom:data.adr_code_postal+' '+data.adr_ville.toUpperCase()})).sort((a,b)=>parseInt(a.adr_code_postal) < parseInt(b.adr_code_postal) ? -1 :0),
        centres: state => state.centres,
        currentSearch: state => state.currentSearch,
        centresSelected: state => state.centresSelected,
        prestationSelected: state => state.prestationSelected,
        dateSelected: state => state.dateSelected,
        dateHoraireSelected : state => state.dateHoraireSelected,
        vehicules: state => state.vehicules,
        centreSelected: state => state.centreSelected,
        timeCentreSelected: state => state.timeCentreSelected,
        positionClientFromGeo: state => state.positionClientFromGeo,
        allCentresWithDistances: state => state.allCentresWithDistances,
        retourTimeSlot: state => state.retourTimeSlot,
        retourFormReservation : state => state.retourFormReservation,
        dispoDays : state => state.dispoDays,
        reservationVehiculesInformations : state => state.reservationVehiculesInformations,
        reservationClientInformations : state => state.reservationClientInformations,
        promotionsSelected:state=>state.promotionsSelected,
        finalPrice:state=>state.finalPrice,
        registerCounter:state=>state.registerCounter,
        retourFromFormPayment:state=> state.retourFromFormPayment
    },
    mutations:{
        setCVC(state,value){
            state.cvc = value
        },
        incRegisterCounter(state){
            state.registerCounter += 1
        },
        setFinalPrice(state,value){
            state.finalPrice= value
        },
        setPromotionsSelected(state,value){
            state.promotionsSelected=value
        },
        setReservationVehiculesInformations(state,value){
            state.reservationVehiculesInformations =value
        },
        setReservationClientInformations(state,value){
            state.reservationClientInformations = value
        },
        setRetourFromPayment(state){
            state.retourFromFormPayment +=1
        },
        setRetourFormReservation(state,value){
            state.retourFormReservation += value
        },
        setRetourTimeSlot(state,value){
            state.retourTimeSlot +=value
        },
        setAllCentresWithDistances(state,centres){
            state.allCentresWithDistances = centres
        },
        setTimeCentreSelected(state){
            state.timeCentreSelected += 1
        },
        setCentres(state, centres){
            state.centres = centres
        },
        setCentre(state,centre){
            state.centreSelected = centre
        },
        setVehicules(state, vehicules){
            state.vehicules = vehicules
        },
        setCurrentSearch(state, item){
            state.currentSearch = item
        },
        setCentresSelected(state, item){
            state.centresSelected = item
        },
        setPropertyOfSearch(state,value){
            state.currentSearch = value
        },
        setPrestationSelected(state,prestation){
            state.prestationSelected = prestation
        },
        setDateSelected(state,date){
            state.dateSelected = date
        },
        setdateHoraireSelected(state,date){
            state.dateHoraireSelected = date
        },
        setLocationFromGeo(state,object){
            state.positionClientFromGeo = object
        },
        setCurrentSearchDate(state, date){
            state.currentSearch.date = date
        },
        setDispoDays(state, dispos){
            state.dispoDays = dispos
        }
    },
    actions:{
        async getCentresZones(state){
            const centres = (await axios.get(`${process.env.VUE_APP_CURRENT_URL}centres-zones`)).data
            state.commit("setCentres", centres)
        },
        async getCentresFiltered(state,object){
            const centresResult = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}centres`,object)).data
            state.commit("setCentresSelected",centresResult)

        },
        async getVehicules(state){
            const vehicules = (await axios.get(`${process.env.VUE_APP_CURRENT_URL}vehicules`)).data

            const vehiculeUsed = vehicules.filter((data)=>data.nb!== undefined)
            const vehiculeUnused = vehicules.filter((data)=> data.nb===undefined)

            const descending = (a, b) =>{
                if(parseInt(a.nb) < parseInt(b.nb)) return 1;
                if(parseInt(a.nb) > parseInt(b.nb)) return -1;
                return 0
            }
            const ascending = (a, b) =>{
                if(parseInt(a.nb) < parseInt(b.nb)) return -1;
                if(parseInt(a.nb) > parseInt(b.nb)) return 1;
                return 0
            }
            const vehiculeUsedSorted = vehiculeUsed.slice().sort(descending)
            state.commit("setVehicules", [...vehiculeUsedSorted,...vehiculeUnused])
        },
        async addRdv(sate,object){
            const rdv = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}addrdv`,object)).data

        },
        async reverseGeoCoding(state,object){
            const {data} = await axios.post(`${process.env.VUE_APP_CURRENT_URL}reverse-geo`,{latitude: object.coords.latitude,longitude: object.coords.longitude})
            return data
        },
        async getDistanceWithCoord(state,object){
            return await axios.post(`${process.env.VUE_APP_CURRENT_URL}coord`, object)
        },
        async getDispo(state,object){
            const result = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}getdispo`,object))
            return result.data.length > 0 ? result.data[0].Jour : false
        },
        async setLock(state,object){
            const result =  (await axios.post(`${process.env.VUE_APP_CURRENT_URL}insert-lock`, object))
            return result.data
        },
        async getTarifCentre(state,object){
            const result = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}centre-tarif`,object))
            return result.data
        }

    },
    modules:{
        userStore: {...userStore},
        paymentStore: {...paymentStore}
    }
}



