<template>
    <v-container>
        <v-row>
            <v-col class="col-10">
                <v-row class="mb-2" justify="start">
                    <p class="text-main-title mb-1">Mentions Légales</p>
                </v-row>
                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Dénomination</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                {{$store.getters.statusClient ? 'ABS-GROUP' :'AUTOBILAN-SYSTEMS'}}<br>
                                SAS - Sociétés par actions simplifiées<br>
                                {{$store.getters.statusClient ? 'N° SIRET 812 527 182 00024' :'N° SIRET 334 821 097 00089'}}<br>
                                {{ $store.getters.statusClient ? 'Code APE 70.22Z':'Code APE 7120A'}}<br>
                                {{$store.getters.statusClient ? '' :'Déclaration d\'activité enregistrée sous le n°93131769313 du préfet de région de PACA'}}
                            </p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Siège social</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Arteparc – Bat F<br>
                                Route de la Côte d’Azur, Lieudit Le Canet de Meyreuil<br>
                                13590 MEYREUIL<br>
                                Téléphone : 05 63 73 16 97
                            </p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Nom du directeur de la publication</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Fabien LEGAY, Dirigeant
                            </p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Hébergement</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Ce site est hébergé par la société <a href="https://www.kalanda.net/" target="_blank">KALANDA</a><br>
                                94 AV DE LA SARRIETTE à LA CIOTAT (13600)
                            </p>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "mentionsLegalesComponent",
        mounted() {
            this.$vuetify.goTo(0)
        }
    }
</script>

<style scoped>
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 15px!important;
    }
    .text-title{
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
</style>
