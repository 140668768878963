<template>
    <div>
                <CarrousselComponent :condition="true"></CarrousselComponent>
                <FormSearchRDV></FormSearchRDV>
                <CentreComponent class="mt-3" v-if="centresSelected.length > 0"></CentreComponent>
                <DailyScheduler></DailyScheduler>
                <TimeSlotComponent  style="margin-top: 210px!important;"></TimeSlotComponent>
                <FormReservationComponent></FormReservationComponent>
                <FormPayment v-if="this.$store.getters.finalPrice"></FormPayment>
    </div>
</template>

<script>
import CentreComponent from "./CentreComponent";
import FormSearchRDV from "./FormSearchRDV";
import DailyScheduler from "./DailyScheduler";
import TimeSlotComponent from "./TimeSlotComponent"
import FormReservationComponent from "./FormReservationComponent";
import CarrousselComponent from "../../components/CarrousselComponent";
import FormPayment from "./payment/FormPayment";
import colorsHandler from '../../libraries/colorsHandler'
export default {
    name: "ParticulierPage",
    components: {
        FormSearchRDV,
        CentreComponent,
        DailyScheduler,
        TimeSlotComponent,
        FormReservationComponent,
        CarrousselComponent,
        FormPayment
    },
    methods:{
        getStatusClient(data){
            this.$emit("statusClient",data)
        },
        showData(data){
            this.centres = data
        },
        updateSearchObject(data){
            this.searchObject = data
        },
    },
    data(){
        return{
            centresSelected:[],
            searchObject:"",
            root:null,
            carroussel : [
              { bg: "images/ct-img-4.jpeg", titre: "ABS-GROUP", texte: "Le contôle technique autrement" },
              { bg: "images/ct-img-9.jpeg", titre: "Mon Budget AUTO", texte: "Les méngages français consacrent 11% de leur budget total à l'automobile,<br> soitt 3807&#8364;/an" },
              { bg: "images/ct-img-1.jpeg", titre: "Le Contrôle Technique Volontaire", texte: "Pour la sécurité de ma famille, pour celle des autres automobilistes, pour l'environnement... J'agis !" }
            ]
        }
    },
    watch:{
        '$store.getters.centresSelected':{
            handler(){
                this.centresSelected = this.$store.getters.centresSelected
            }
        }
    },
  mounted() {
      window.scrollTo(0, 0)
      this.root = document.documentElement
      colorsHandler.setStatusClientColors()(true,this)
      this.$store.commit('setStatusClient',true)

  },
    created() {

    }
}
</script>

<style scoped>

</style>
