<template>
    <v-container>
        <v-row class="mb-2">
            <v-col class="col-10">
                <v-row class="mb-2" justify="start">
                    <p class="text-main-title mb-1">Conditions générales d’utilisation</p>
                </v-row>
                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 1 : Objet</p>
                        </v-row>
                        <v-row class="py-2" v-if="$store.getters.statusClient">
                            <span class="text-style text-justify">
                                Les présentes CGU ou Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site abs-group.fr (ci-après dénommé « le site »).
                            </span>
                            <span class="text-style text-justify mt-2">
                                Constituant le contrat entre la société ABS-GROUP, l’Utilisateur, l’accès au site doit être précédé de l’acceptation de ces CGU. L’accès à cette plateforme signifie l’acceptation des présentes CGU.
                            </span>

                        </v-row>
                        <v-row class="py-2" v-else>
                            <span class="text-style text-justify">
                                Les présentes CGU ou Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site autobilan-systems.com (ci-après dénommé « le site »).
                            </span>
                            <span class="text-style text-justify mt-2">
                                Constituant le contrat entre la société AUTOBILAN-SYSTEMS, l’Utilisateur, l’accès au site doit être précédé de l’acceptation de ces CGU. L’accès à cette plateforme signifie l’acceptation des présentes CGU.
                            </span>
                        </v-row>

                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 2 : Mentions légales</p>
                        </v-row>
                        <v-row class="py-2" v-if="$store.getters.statusClient">
                            <span class="text-style text-justify">
                                L’édition du site abs-group.fr est assurée par la société ABS-GROUP inscrite au RCS sous le numéro 812 527 182, dont le siège social est localisé au Bâtiment F - Route de la Côte d'Azur - Lieudit Le Canet de Meyreuil - 13590 MEYREUIL, France Métropolitaine.
                            </span>
                            <span class="text-style text-justify mt-2">
                                L’hébergeur du site abs-group.fr est la société KALANDA : 94 AV DE LA SARRIETTE à LA CIOTAT (13600)
                            </span>
                        </v-row>
                        <v-row class="py-2" v-else>

                            <span class="text-style text-justify">
                                L’édition du site autobilan-systems.com est assurée par la société AUTOBILAN-SYSTEMS inscrite au RCS sous le numéro 334 821 097, dont le siège social est localisé au Bâtiment F - Route de la Côte d'Azur - Lieudit Le Canet de Meyreuil - 13590 MEYREUIL, France Métropolitaine.
                            </span>
                            <span class="text-style text-justify mt-2">
                                L’hébergeur du site autobilan-systems.com est la société KALANDA : 94 AV DE LA SARRIETTE à LA CIOTAT (13600)
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 3 : Accès au site</p>
                        </v-row>
                        <v-row class="py-2" v-if="$store.getters.statusClient">
                            <span class="text-style text-justify">
                                Le site abs-group.fr permet d’accéder aux services suivants :
                            </span>
                            <ul class="text-style text-justify">
                                <li>• Prendre un rendez-vous (pour réaliser un Contrôle technique)</li>
                                <li>• Mon budget CT (Contrôle Technique)</li>
                                <li>• Mon alerte CT (Contrôle Technique)</li>
                                <li>• Les centres recrutent</li>
                            </ul>
                            <span class="text-style text-justify py-1">
                                Le site est accessible gratuitement depuis n’importe où par tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…) sont à la charge de l’utilisateur.
                            </span>
                            <span class="text-style text-justify py-1">
                                L’accès aux services dédiés aux utilisateurs s’effectue à l’aide d’un identifiant et d’un mot de passe.
                            </span>
                            <span class="text-style text-justify py-1">
                                Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.
                            </span>
                        </v-row>
                        <v-row class="py-2" v-else>
                            <span class="text-style text-justify">
                                Le site autobilan-systems.com permet d’accéder aux services suivants :
                            </span>
                            <ul class="text-style text-justify">
                                <li>• Véhicules Légers (suivi & rachat, système qualité, veille règlementaire, assistance, procès-verbaux, audits & formations)</li>
                                <li>• Poids Lourds (suivi & rachat, système qualité, veille règlementaire, assistance, procès-verbaux, audits & formations)</li>
                                <li>• Audits</li>
                                <li>• Formations</li>
                                <li>• Informatique</li>
                            </ul>
                            <span class="text-style text-justify py-1">
                                Le site est accessible gratuitement depuis n’importe où par tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…) sont à la charge de l’utilisateur.
                            </span>
                            <span class="text-style text-justify py-1">
                                L’accès aux services dédiés aux utilisateurs s’effectue à l’aide d’un identifiant et d’un mot de passe.
                            </span>
                            <span class="text-style text-justify py-1">
                                Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 4 : Collecte des données</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Pour la création du compte de l’Utilisateur, la collecte des informations au moment de l’inscription sur le site est nécessaire et obligatoire. Conformément à la loi n°78-17 du 6 janvier relative à l’informatique, aux fichiers et aux libertés, la collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.<br>
                                Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l’Utilisateur dispose du droit d’accéder, de rectifier, de supprimer et d’opposer ses données personnelles. L’exercice de ce droit s’effectue par :
                            </span>
                            <ul class="my-1 text-style text-justify">
                                <li>• Le formulaire de contact ;</li>
                                <li>• Son espace client.</li>
                            </ul>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 4 : Collecte des données</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Pour la création du compte de l’Utilisateur, la collecte des informations au moment de l’inscription sur le site est nécessaire et obligatoire. Conformément à la loi n°78-17 du 6 janvier relative à l’informatique, aux fichiers et aux libertés, la collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.
                            </span>
                            <span class="text-style text-justify py-1">
                                Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l’Utilisateur dispose du droit d’accéder, de rectifier, de supprimer et d’opposer ses données personnelles. L’exercice de ce droit s’effectue par :
                            </span>
                            <ul class="my-1 text-style text-justify">
                                <li>• Le formulaire de contact ;</li>
                                <li>• Son espace client.</li>
                            </ul>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 5 : Propriété intellectuelle</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Les marques, logos ainsi que les contenus du site abs-group.fr (illustrations graphiques, textes…) sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.
                            </span>
                            <span class="text-style text-justify py-1">
                                La reproduction et la copie des contenus par l’Utilisateur requièrent une autorisation préalable du site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 5 : Propriété intellectuelle</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Les marques, logos ainsi que les contenus du site autobilan-systems.com (illustrations graphiques, textes…) sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.
                            </span>
                            <span class="text-style text-justify py-1">
                                La reproduction et la copie des contenus par l’Utilisateur requièrent une autorisation préalable du site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 6 : Responsabilité</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie de la fiabilité des sources.
                            </span>
                            <span class="text-style text-justify py-1">
                                Les informations diffusées sur le site abs-group.fr sont présentées à titre purement informatif . En dépit des mises à jour régulières, la responsabilité du site ne peut être engagée en cas de modification des dispositions administratives et juridiques apparaissant après la publication. Il en est de même pour l’utilisation et l’interprétation des informations communiquées sur la plateforme.
                            </span>
                            <span class="text-style text-justify py-1">
                                Le site décline toute responsabilité concernant les éventuels virus pouvant infecter le matériel informatique de l’Utilisateur après l’utilisation ou l’accès à ce site.
                            </span>
                            <span class="text-style text-justify py-1">
                                Le site ne peut être tenu pour responsable en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.
                            </span>
                            <span class="text-style text-justify py-1">
                                La garantie totale de la sécurité et la confidentialité des données n’est pas assurée par le site. Cependant, le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 6 : Responsabilité</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie de la fiabilité des sources.
                            </span>
                            <span class="text-style text-justify py-1">
                                Les informations diffusées sur le site autobilan-systems.com sont présentées à titre purement informatif. En dépit des mises à jour régulières, la responsabilité du site ne peut être engagée en cas de modification des dispositions administratives et juridiques apparaissant après la publication. Il en est de même pour l’utilisation et l’interprétation des informations communiquées sur la plateforme.
                            </span>
                            <span class="text-style text-justify py-1">
                                Le site décline toute responsabilité concernant les éventuels virus pouvant infecter le matériel informatique de l’Utilisateur après l’utilisation ou l’accès à ce site.
                            </span>
                            <span class="text-style text-justify py-1">
                                Le site ne peut être tenu pour responsable en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.
                            </span>
                            <span class="text-style text-justify py-1">
                                La garantie totale de la sécurité et la confidentialité des données n’est pas assurée par le site. Cependant, le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 7 : Liens hypertextes</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Le site peut être constitué de liens hypertextes. En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable du contenu des pages web relatives à ces liens.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Article 7 : Liens hypertextes</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Le site peut être constitué de liens hypertextes. En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable du contenu des pages web relatives à ces liens.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 8 : Cookies</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Lors des visites sur le site, l’installation automatique d’un cookie sur le logiciel de navigation de l’Utilisateur peut survenir.
                            </span>
                            <span class="text-style text-justify py-1">
                                Les cookies correspondent à de petits fichiers déposés temporairement sur le disque dur de l’ordinateur de l’Utilisateur. Ces cookies sont nécessaires pour assurer l’accessibilité et la navigation sur le site. Ces fichiers ne comportent pas d’informations personnelles et ne peuvent pas être utilisés pour l’identification d’une personne.
                            </span>
                            <span class="text-style text-justify py-1">
                                L’information présente dans les cookies est utilisée pour améliorer les performances de navigation sur le site <a :href="url" target="_blank">abs-group.fr</a>
                            </span>
                            <span class="text-style text-justify py-1">
                                En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation peut s’effectuer via les paramètres du logiciel de navigation.
                            </span>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">Google Analytics</a>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 8 : Cookies</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify py-1">
                                Lors des visites sur le site, l’installation automatique d’un cookie sur le logiciel de navigation de l’Utilisateur peut survenir.
                            </span>
                            <span class="text-style text-justify py-1">
                                Les cookies correspondent à de petits fichiers déposés temporairement sur le disque dur de l’ordinateur de l’Utilisateur. Ces cookies sont nécessaires pour assurer l’accessibilité et la navigation sur le site. Ces fichiers ne comportent pas d’informations personnelles et ne peuvent pas être utilisés pour l’identification d’une personne.
                            </span>
                            <span class="text-style text-justify py-1">
                                L’information présente dans les cookies est utilisée pour améliorer les performances de navigation sur le site <a :href="url+'/pro'" target="_blank">autobilan-systems.com</a>.
                            </span>
                            <span class="text-style text-justify py-1">
                                En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation peut s’effectuer via les paramètres du logiciel de navigation.
                            </span>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">Google Analytics</a>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 9 : Publication par l’Utilisateur</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="py-1 text-style text-justify">Le site abs-group.fr permet aux utilisateurs de publier des commentaires.</span>
                            <span class="py-1 text-style text-justify">Dans ses publications, le membre est tenu de respecter les règles de la Netiquette ainsi que les règles de droit en vigueur.</span>
                            <span class="py-1 text-style text-justify">Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans avoir à fournir de justification.</span>
                            <span class="py-1 text-style text-justify">Le membre garde l’intégralité de ses droits de propriété intellectuelle. Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle. Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé. Cela concerne notamment le droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.</span>
                            <span class="py-1 text-style text-justify">À chaque utilisation, l’éditeur s’engage à mentionner le nom du membre à proximité de la publication.</span>
                            <span class="py-1 text-style text-justify">L’Utilisateur est tenu responsable de tout contenu qu’il met en ligne. L’Utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes. Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.</span>
                            <span class="py-1 text-style text-justify">La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 9 : Publication par l’Utilisateur</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="py-1 text-style text-justify">Le site autobilan-systems.com permet aux utilisateurs de publier des commentaires.</span>
                            <span class="py-1 text-style text-justify">Dans ses publications, le membre est tenu de respecter les règles de la Netiquette ainsi que les règles de droit en vigueur.</span>
                            <span class="py-1 text-style text-justify">Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans avoir à fournir de justification.</span>
                            <span class="py-1 text-style text-justify">Le membre garde l’intégralité de ses droits de propriété intellectuelle. Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle. Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé. Cela concerne notamment le droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.</span>
                            <span class="py-1 text-style text-justify">À chaque utilisation, l’éditeur s’engage à mentionner le nom du membre à proximité de la publication.</span>
                            <span class="py-1 text-style text-justify">L’Utilisateur est tenu responsable de tout contenu qu’il met en ligne. L’Utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes. Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.</span>
                            <span class="py-1 text-style text-justify">La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.</span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 10 : Durée du contrat</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 10 : Durée du contrat</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1" v-if="$store.getters.statusClient">
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 11 : Droit applicable et juridiction compétente</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="start" class="ml-1" v-else>
                    <v-col class="col-9 mt-3">
                        <v-row>
                            <p class="text-title mb-0">Article 11 : Droit applicable et juridiction compétente</p>
                        </v-row>
                        <v-row class="py-2">
                            <span class="text-style text-justify">
                                Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux.
                            </span>
                        </v-row>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "CGUComponent",
        data(){
          return {
              url: ''
          }
        },
        mounted() {
            this.url = process.env.VUE_APP_URL_PARTICULIER
            this.$vuetify.goTo(0)
        }
    }
</script>

<style scoped>
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 15px!important;
    }
    .text-title{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
</style>
