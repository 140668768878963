module.exports={
    setStatusClientColors:()=>{

        return (statusClient,current)=>{
        if(!statusClient){
            current.root.style.setProperty('--btn-color', '#89B951')
            current.root.style.setProperty('--btn-transparent-focus', '#666666')
            current.root.style.setProperty('--links-btn-hover', '#666666')
            current.root.style.setProperty('--darker-green', '#1C1C1C')
            current.root.style.setProperty('--other-green', '#1C1C1C')
            current.root.style.setProperty('--status-color', '#1F5578')
            current.root.style.setProperty('--white_background', '#FAFAFF')
            current.root.style.setProperty('--black', '#1C1C1C')
            current.root.style.setProperty('--red-background', '#1C1C1C')
            current.root.style.setProperty('--blue-infos', '#EEF0F2')

            current.root.style.setProperty('--options-orange', '#89B951')

            current.root.style.setProperty('--grey-choice', '#1C1C1C')
            current.root.style.setProperty('--white-focus', '#FAFAFF')
            current.root.style.setProperty('--white-selector', '#FAFAFF')

            current.root.style.setProperty('--text-color', '#1C1C1C')
            current.root.style.setProperty('--coordonates-link', '#1C1C1C')
            current.root.style.setProperty('--link-color', '#89B951')
            current.root.style.setProperty('--mark', '#89B951')
            current.root.style.setProperty('--darker-grey', '#1C1C1C')

            current.root.style.setProperty('--h6-foot', '#0A0A0A')
            current.root.style.setProperty('--titles', '#0A0A0A')

            current.root.style.setProperty('--bg-footer', '#EEF0F2')
            current.root.style.setProperty('--bg-color-footer-down', '#EEF0F2')

            current.root.style.setProperty('--bg-color-header-up', '#89B951')
            current.root.style.setProperty('--bg-color-header', '#FAFAFF')
            current.root.style.setProperty('--white-bot-header', '#89B951')
        }else{
            current.root.style.setProperty('--status-color', '#89BA51')
            current.root.style.setProperty('--btn-color', '#025578')
            current.root.style.setProperty('--btn-transparent-focus', '#666666')
            current.root.style.setProperty('--links-btn-hover', '#666666')
            current.root.style.setProperty('--darker-green', '#1C1C1C')
            current.root.style.setProperty('--other-green', '#1C1C1C')

            current.root.style.setProperty('--white_background', '#FAFAFF')
            current.root.style.setProperty('--black', '#1C1C1C')
            current.root.style.setProperty('--red-background', '#1C1C1C')
            current.root.style.setProperty('--blue-infos', '#EEF0F2')

            current.root.style.setProperty('--options-orange', '#025578')

            current.root.style.setProperty('--grey-choice', '#1C1C1C')
            current.root.style.setProperty('--white-focus', '#FAFAFF')
            current.root.style.setProperty('--white-selector', '#FAFAFF')

            current.root.style.setProperty('--text-color', '#1C1C1C')
            current.root.style.setProperty('--coordonates-link', '#1C1C1C')
            current.root.style.setProperty('--link-color', '#025578')
            current.root.style.setProperty('--mark', '#025578')
            current.root.style.setProperty('--darker-grey', '#1C1C1C')

            current.root.style.setProperty('--h6-foot', '#0A0A0A')
            current.root.style.setProperty('--titles', '#0A0A0A')

            current.root.style.setProperty('--bg-footer', '#EEF0F2')
            current.root.style.setProperty('--bg-color-footer-down', '#EEF0F2')

            current.root.style.setProperty('--bg-color-header-up', '#025578')
            current.root.style.setProperty('--bg-color-header', '#FAFAFF')
            current.root.style.setProperty('--white-bot-header', '#025578')
        }
        }
    }


}
