<template>
    <v-row class="justify-end">
        <v-col
                class="py-2 col-12"
        >
            <v-btn-toggle
                    tile
                    color=""
                    group
            >
                <b-button v-add-class-hover="'hover-opacity'" style="color:white;font-weight: bold;background-color: #025578" value="left" class="py-0 btn  btn-sm text-caption mr-1" :class="!$store.getters.statusClient ? 'button-opacity' : '' "  @click="redirectClient('particulier')">
                    Particulier
                </b-button>

                <button v-add-class-hover="'hover-opacity'" style="color:white;font-weight: bold;background-color: #89B951" value="center" class="btn btn-sm text-caption" :class="$store.getters.statusClient ? 'button-opacity' : '' "  @click="redirectClient('pro')">
                    Professionnel
                </button>

            </v-btn-toggle>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "SwapStatusClient",
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    el.removeEventListener('mouseenter');
                    el.removeEventListener('mouseleave')
                }
            }
        },
        data(){
            return{
                statusClient: true,
                root:null,
            }
        },
        mounted(){
            this.root = document.documentElement
        },
        methods: {
            setColors(color) {
                this.root.style.setProperty('--options-orange', color)
                this.root.style.setProperty('--btn-color', color)
            },
            redirectClient(url){
                window.location.href = url === 'particulier' ? process.env.VUE_APP_URL_PARTICULIER : process.env.VUE_APP_URL_PRO
            },
            toggleClassFalse(){

                this.statusClient = false
                this.$emit('clicked',this.statusClient)
                this.setColors("#89B951")
                this.$router.push({name:'Pro'})
            }
        }
    }
</script>

<style scoped>
.button-opacity{
    opacity: 0.5;
    color: white!important;
}
.hover-opacity{
    opacity: 1;
}

</style>
