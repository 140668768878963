<template>
    <div>
        <section class="section-full text-left bg">
            <div class="container">
                <div class="row">
                    <div class="">
                        <p class="text-main-title-bg mb-1">Page de Contact</p>
                    </div>
                </div>
            </div>
        </section>
        <!--Section Contact Us 2-->
        <v-container class="pt-8">
            <v-row justify="center">
                <v-col cols="12" md="7" sm="12" class="pa-6">
                    <v-row align="start">
                        <v-col class="col-12">
                            <v-row>
                                <p class="text-main-title mb-1">Contactez-nous</p>
                            </v-row>
                            <hr/>
                            <v-row class="mt-1">
                                <p class="text-style text-justify">
                                    Vous pouvez nous contacter de la manière qui vous convient. Nous sommes disponibles 24h sur 24 et 7j sur 7 par e-mail. Vous pouvez également utiliser le formulaire de contact ci-dessous.
                                </p>
                            </v-row>
                        </v-col>
                        <v-form v-model="valid">
                            <v-row>
                                <v-col cols="6">
                                    <v-radio-group
                                        v-model="statusClient"
                                        row
                                    >
                                        <v-radio
                                            label="Particulier"
                                            :value="true"
                                        ></v-radio>
                                        <v-radio
                                            label="Professionnel"
                                            :value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="6" v-if="!statusClient">
                                    <v-select
                                        :items="servicesPro"
                                        label="Choisir le service"
                                        :rules="servicesRules"
                                        outlined
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row justify="center" align="center">
                                <v-col cols="6">
                                    <v-text-field outlined v-model="lastName" :rules="nameRules" label="Nom" required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field outlined v-model="firstName" :rules="prenomRules" label="Prénom" required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field outlined v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field outlined v-model="phone" :rules="phoneRules" label="Téléphone mobile" required></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea outlined name="input-7-4" label="Message" v-model="motif" :rules="motifRules"></v-textarea>
                                </v-col>
                                <v-col cols="6">
                                    <b-button block style="color:white;background-color: var(--btn-color)!important;" @click="validate" :disabled="!valid" class="rounded-pill">Envoyer</b-button>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-row>
                </v-col>
                <v-divider class="px-3" vertical></v-divider>
                <v-col cols="12" sm="12" md="3">
                    <v-row>
                        <v-col class="col-md-12 col-sm-6 pa-6">
                            <dl class="contact-info">
                                <v-row class="pl-3 pb-2">
                                    <dt>
                                        <span class="h4 border-bottom">Téléphone</span>
                                    </dt>
                                </v-row>
                                <v-divider style="width: 100%"  class="pa-0 ma-0"></v-divider>
                                <v-row class="mt-2 pl-3">
                                    <dd>
                                        <a href="callto:#" class="text-light">05 63 73 16 97</a>
                                    </dd>
                                </v-row>
                            </dl>
                        </v-col>
                        <v-col class="col-md-12 col-sm-6 pa-6">
                            <dl class="contact-info">
                                <v-row class="pl-3 pb-2">
                                    <dt>
                                        <span class="h4 border-bottom">E-mail</span>
                                    </dt>
                                </v-row>
                                <v-divider style="width: 100%"  class="pa-0 ma-0"></v-divider>
                                <v-row class="mt-2 pl-3">
                                    <dd>
                                        <a href="mailto:#" class="text-sushi">{{$store.getters.statusClient ?'contact@abs-group.fr': 'contact@autobilan-systems.com'}}</a>
                                    </dd>
                                </v-row>
                            </dl>
                        </v-col>
                        <v-col class="col-md-12 col-sm-6 pa-6">
                            <dl class="contact-info">
                                <v-row class="pl-3 pb-2">
                                    <dt>
                                        <span class="h4 border-bottom">Réseaux</span>
                                    </dt>
                                </v-row>
                                <v-divider style="width: 100%"  class="pa-0 ma-0"></v-divider>
                                <v-row class="mt-2 pl-3">
                                    <dd>
                                        <ul class="pa-0 list-inline">
                                            <li>
                                                <a href="">
                                                    <v-icon size="25" color="blue darken-2">
                                                        {{ icons.mdiLinkedin }}
                                                    </v-icon>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <v-icon size="25" color="blue">
                                                        {{ icons.mdiFacebook }}
                                                    </v-icon>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <v-icon size="25" color="red darken-2">
                                                        {{ icons.mdiGoogle }}
                                                    </v-icon>
                                                </a>
                                            </li>
                                        </ul>
                                    </dd>
                                </v-row>
                            </dl>
                        </v-col>
                        <v-col class="col-md-12 col-sm-6 pa-6">
                            <address class="address">
                                <v-row class="pl-3 pb-2">
                                    <dt>
                                        <span class="h4 border-bottom">Adresse</span>
                                    </dt>
                                </v-row>
                                <v-divider style="width: 100%"  class="pa-0 ma-0"></v-divider>
                                <v-row class="mt-2 pl-3">
                                    <dd>
                                        <p>Arteparc – Bat F Route de la Cote d’Azur Lieudit Le Canet de Meyreuil 13590 MEYREUIL</p>
                                    </dd>
                                </v-row>
                            </address>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <!--Section Google map-->
        <section class="text-left">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1578.1424985912151!2d5.523251630265814!3d43.49553346604962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ae6f0d0c7064d5%3A0xd8d39704f8da1cdc!2sAuto%20Bilan%20Systems!5e0!3m2!1sfr!2sfr!4v1637161387379!5m2!1sfr!2sfr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </section>
    </div>
</template>

<script>
import { mdiAccount, mdiAt, mdiCellphone,mdiFacebook, mdiLinkedin, mdiGoogle } from "@mdi/js";

export default {
    name: "ContactUsForm",
    data() {
        return {
            servicesPro:['Audit','Formation','Réglementation','Informatique'],
            statusClient:'',
            root: null,
            lastName: '',
            firstName: '',
            email: '',
            phone: '',
            motif: '',
            valid: false,
            icons:{
                mdiAccount,mdiAt,mdiCellphone,mdiLinkedin,mdiGoogle,mdiFacebook
            },
            nameRules: [
                v => !!v || 'Saisir votre nom',
                v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

            ],
            servicesRules:[
                v => !!v || 'Choisir un service',
            ],
            motifRules:[
                v => !!v || 'Saisir un message',
            ],
            prenomRules:[
                v => !!v || 'Saisir votre prénom',
                v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || 'Prénom non valide'

            ],
            emailRules: [
                v => !!v || 'Un E-mail est nécessaire',
                v => /.+@.+\..+/.test(v) || 'E-mail non valide',
            ],
            phoneRules: [
                v => !!v || 'Un téléphone est nécessaire',
                v => /^0[67]\d{8}$/.test(v) && v.length <=10|| 'Mobile non valide',
            ],
        }
    },
    methods: {
        validate(){
        },
        setColors(color) {
            this.root.style.setProperty('--options-orange', color)
            this.root.style.setProperty('--btn-color', color)
        }
    },
    watch: {
        'lastName': {
            handler(value) {
                this.lastName = value.toString().toUpperCase()
            }
        },
        'firstName': {
            handler(value) {
                if (/^[a-zéèê]{1}$/.test(value)) {
                    this.firstName = value.toString().toUpperCase()
                }
                if (/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)) {
                    const char = value.substr(value.length - 1).toUpperCase()
                    this.firstName = value.substr(0, value.length - 1).concat(char)
                }

                if (/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)) {
                    const char = value.substr(value.length - 1).toLowerCase()
                    this.firstName = value.substr(0, value.length - 1).concat(char)
                }
            }
        },
        'statusClient':{
            handler(value){
                value ? this.setColors("#025578"):this.setColors("#89B951")
                this.$store.commit('setStatusClient',value)
            }
        }
    },
    mounted() {
        this.$vuetify.goTo(0)
        this.root = document.documentElement
        this.$store.commit('syncStatusClient')
        this.$store.getters.statusClient ? this.setColors("#025578"):this.setColors("#89B951")
        this.statusClient = this.$store.getters.statusClient
    }
}
</script>

<style scoped>
.bg {
    background-image: url("/images/bandeau-pl.png");
}
.text-style{
    font-family: Lato, sans-serif;
    color: #8f8f8f;
    font-size: 15px!important;
}
.text-title{
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.78px;
    text-decoration-color: rgb(70,71,74);
}
.text-main-title{
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0.5px;
    text-decoration-color: rgb(70,71,74);
}
.text-main-title-bg{
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 25px;
    font-weight: 900;
    letter-spacing: 0.5px;
    text-decoration-color: rgb(70,71,74);
}
</style>
