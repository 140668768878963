<template>
    <v-container>
        <v-row>
            <v-col sm="3">
                <h4 class="pa-2">Informations Légales</h4>
                <v-list nav dense>
                    <v-list-item-group mandatory color="primary" v-model="indexSubject">
                        <v-list-item v-for="(item, i) in items" :key="i" @click="setItem(item)">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name" ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-btn style="background-color: var(--btn-color)" dark class="ma-2" @click="dialog2 = !dialog2">
                    Contact
                </v-btn>
                <v-dialog v-model="dialog2" max-width="500px">
                    <v-card>
                        <v-card-title :class="$store.getters.statusClient ? 'abs-group' : 'auto-bilan-systems'">
                            <v-row justify="center" class="pa-3" style="color: white" >
                                Contact
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>
                                <v-row justify="center">
                                    <v-col class="col-10">
                                        <v-row class="justify-start">
                                        </v-row>
                                        <v-row justify="start" align="center" class="mt-5 pt-2">
                                            <v-col class="col-1 mr-3">
                                                <v-icon  size="20">{{ icons.mdiAccount }}</v-icon>
                                            </v-col>
                                            <v-col class="col-4 ma-1">
                                                <v-row>
                                                    <v-text-field
                                                            v-model="lastName"
                                                            :rules="nameRules"
                                                            label="Nom"
                                                            required
                                                    ></v-text-field>
                                                </v-row>
                                            </v-col>
                                            <v-col class="col-4 ma-1">
                                                <v-row>
                                                    <v-text-field
                                                            v-model="firstName"
                                                            :rules="prenomRules"
                                                            label="Prénom"
                                                            required
                                                    ></v-text-field>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="start" align="center" class="mt-0 pt-0">
                                            <v-col class="col-1 mr-3">
                                                <v-icon  size="20">{{ icons.mdiAt }}</v-icon>
                                            </v-col>
                                            <v-col class="col-5 ma-2">
                                                <v-row>
                                                    <v-text-field
                                                            v-model="email"
                                                            :rules="emailRules"
                                                            label="E-mail"
                                                            required
                                                    ></v-text-field>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="start" align="center" class="mt-0 pt-0">
                                            <v-col class="col-1 mr-3">
                                                <v-icon  size="20">{{ icons.mdiCellphone }}</v-icon>
                                            </v-col>
                                            <v-col class="col-5 ma-2">
                                                <v-row>
                                                    <v-text-field
                                                            v-model="phone"
                                                            :rules="phoneRules"
                                                            label="Téléphone mobile"
                                                            required
                                                    ></v-text-field>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="start" align="center" class="mt-0 pt-0">
                                            <v-col class="col-12 ma-2 mt-5">
                                                <v-row>
                                                    <v-textarea
                                                            outlined
                                                            name="input-7-4"
                                                            label="Motif"
                                                            v-model="motif"
                                                            :rules="motifRules"

                                                    ></v-textarea>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center">
                                            <b-button style="color:white;background-color: var(--btn-color)" @click="validate" :disabled="!valid" class="btn btn-sushi btn-sm">Envoyer</b-button>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col sm="9">
                <CGUComponent v-if="indexSubject===0"></CGUComponent>
                <CookiesUseComponent v-if="indexSubject===1"></CookiesUseComponent>
                <mentionsLegalesComponent v-if="indexSubject===2"></mentionsLegalesComponent>
                <RGPDComponent v-if="indexSubject===3"></RGPDComponent>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CGUComponent from "./CGUComponent";
    import RGPDComponent from "./RPGDComponent";
    import CookiesUseComponent from "./CookiesUseComponent";
    import mentionsLegalesComponent from "./mentionsLegalesComponent";
    import { mdiAccount,mdiAt,mdiCellphone } from '@mdi/js';

    export default {
        name: "InformationsLegalesComponent",
        components: {
            RGPDComponent,
            CookiesUseComponent,
            CGUComponent,
            mentionsLegalesComponent
        },
        props:['selected'],
        data() {

            return {
                root: null,
                valid:false,
                motif:'',
                nameRules: [
                    v => !!v || 'Saisir votre nom',
                    v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

                ],
                motifRules:[
                    v => !!v || 'Saisir un motif d\'envoie',
                ],
                prenomRules:[
                    v => !!v || 'Saisir votre prénom',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || 'Prénom non valide'

                ],
                emailRules: [
                    v => !!v || 'Un E-mail est nécessaire',
                    v => /.+@.+\..+/.test(v) || 'E-mail non valide',
                ],
                phoneRules: [
                    v => !!v || 'Un téléphone est nécessaire',
                    v => /^0[67]\d{8}$/.test(v) && v.length <=10|| 'Mobile non valide',
                ],
                firstName: '',
                lastName: '',
                desc: '',
                email: '',
                phone: '',
                icons:{
                    mdiAccount,mdiAt,mdiCellphone
                },
                items: [
                    {
                        name: "Conditions Générales d'Utilisation",
                        link: "CGU"
                    },
                    {
                        name: "Utilisations des Cookies",
                        link: "Politiques-des-cookies"
                    },
                    {
                        name: "Mentions Légales",
                        link: "Mentions-Legales"
                    },
                    {
                        name: "Politique RGPD",
                        link: "RGPD"
                    }
                ],
                selectedItem: 0,
                dialog2: false,
                rules: {
                    phoneNumber: [val => (val || '').length === 10 || 'Numéro saisi invalide'],
                    mail: [val => (val || '').length > 0 || 'Adresse saisie invalide'],
                    desc: [val => (val || '').length > 0 || 'Merci de renseigner votre demande'],
                    name: [val => (val || '').length > 0 || 'Merci de renseigner vos informations'],
                },
                snackbar: false,
                indexSubject:0
            }
        },
        beforeRouteUpdate(to,from,next){
            this.indexSubject = this.items.findIndex(i => i.link === to.query.subject)
            this.$vuetify.goTo(0)
            next()
        },
        computed: {

        },
        methods: {
            setItem(item){
                this.indexSubject = this.items.findIndex(i => i.name === item.name)
                this.$vuetify.goTo(0)
            },
            submit () {
                this.snackbar = true
            },
            validate(){
                this.$refs.form.validate()
            },
            setColors(color) {
                this.root.style.setProperty('--options-orange', color)
                this.root.style.setProperty('--btn-color', color)
            }
        },
        mounted() {
            this.indexSubject =this.items.findIndex(i => i.link === this.$route.query.subject)
            this.$vuetify.goTo(0)
        },
        watch:{
            'indexSubject':{
              handler(){
              }
            },
            'lastName':{
                handler(value){
                    this.lastName = value.toString().toUpperCase()
                }
            },
            'firstName':{
                handler(value){
                    if(/^[a-zéèê]{1}$/.test(value)){
                        this.firstName=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                }
            },
        },
    }
</script>

<style scoped>
    .abs-group{
        background-color: #1F5578!important;
    }
    .auto-bilan-systems{
        background-color: #89BA51!important;
    }
    .abs-group-button{
        background-color: #025578!important;
    }
    .auto-bilan-systems-button{
        background-color: #89B951!important;
    }
</style>
