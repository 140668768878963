<template>
    <div>
        <section class="section-full text-left bg" style="height: 250px">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Budget Contrôle Technique</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <div>
            <v-dialog v-model="dialogLogin" width="650">
                <v-card class="pa-0 ma-0">
                    <login-component :paramReturn="false" :paramRegister="true" @recoverPassword="recoverPassword"></login-component>
                </v-card>
            </v-dialog>
        </div>
        <div>
            <v-dialog v-model="dialogEmail" width="650">
                <v-card class="pa-0 ma-0">
                    <dialogEmail @closeDialog = "closeDialogRecoverEmail"></dialogEmail>
                </v-card>
            </v-dialog>
        </div>
        <div>
            <v-dialog v-model="dialogRegister" width="850">
                <v-card class="pa-0 ma-0">
                    <FormRegisterComponent @closeDialogRegister="closeDialogRegister"></FormRegisterComponent>
                </v-card>
            </v-dialog>
        </div>
        <!--Section Welcome-->
        <section class="section-md text-left">
            <v-container class="container mb-0 pb-0">
                <h3 style="font-size: 1.6em">MA BOURSE CT, la première cagnotte de gestion du budget « contrôles » des véhicules</h3>
                <hr>
                <v-row justify="center" class="mb-0 pb-0">
                    <v-col cols="12" sm="12" md="6" lg="3" class="elevation-10">
                        <v-row justify="center" align="center" class="pa-sm-10">
                            <v-col md="12" lg="12" xl="5" >
                                <v-row class="justify-sm-center pt-sm-0 mt-sm-0">
                                    <v-img
                                            style=" transform: scaleX(-1);"
                                            max-height="140"
                                            max-width="140"
                                            src="images/budgetct/piggy_bank.png"
                                    > </v-img>
                                </v-row>
                            </v-col>
                            <v-col lg="5">
                                <v-row justify="center" align="center" class="ml-1" v-if="!$store.getters.authenticate">
                                    <b-button  @click="connexion" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill"><span class="text-lg-caption">Se connecter</span></b-button>
                                    <b-button  @click="register" style="color:white" class="mt-2 btn btn-sushi btn-sm mr-2 rounded-pill"><span class="text-lg-caption" >Créer mon compte</span></b-button>
                                </v-row>
                                <v-row justify="center" align="center" class="ml-3" v-else>
                                    <b-button  @click="myAccount" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Mon compte</b-button>
                                    <b-button  @click="deconnexion" style="color:white" class="mt-2 btn btn-sushi btn-sm mr-2 rounded-pill">Se déconnecter</b-button>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="8" lg="8" class="elevation-10 ml-4 pa-10">
                        <p class="text-justify text-style">
                            <span style="font-weight: bolder; font-size: 1em">
                              Les ménages français consacrent 11% de leur budget total à l’automobile, soit 3.807€/an. 81% d'entre eux possèdent au moins une voiture et 35% deux, d’après l’Insee.
                            </span><br><br>
                                        Les frais liés à l’entretien des voitures ne cessent de progresser ces dernières années.
                                        ABS-GROUP, vous propose « Ma bourse CT », la première cagnotte d'anticipation et de gestion du budget « contrôle technique » des véhicules.
                                        Cette somme est utilisable dans l'ensemble des Centres de Contrôle Technique partenaires ABS-GROUP.
                                        Plus de 300 centres, à votre service, sur toute la France.
                                    </p>
                                    <!--<v-btn outlined v-on:click="more = true">En savoir plus</v-btn>-->
                    </v-col>


                </v-row>
                <v-dialog max-width="600" v-model="more">
                    <v-card class="pa-3">
                        <v-col>
                            <h3>Un Contrôle volontaire, c'est :</h3>
                            <hr>
                            <ul style="list-style: disc; color: black">
                                <li><span class="text-style">Le diagnostic d'un spécialiste sur l'état de votre véhicule</span></li>
                                <li><span class="text-style">L'assurance de rouler en toute sécurité, l'esprit tranquille</span></li>
                                <li><span class="text-style">Une alerte des éventuels travaux ou entretiens à effectuer</span></li>
                                <li><span class="text-style">Un pré-contrôle avant le contrôle règlementaire pour éviter la contre-visite</span></li>
                            </ul>
                        </v-col>
                    </v-card>
                </v-dialog>
                <v-row align="center" justify="center">
                    <v-col lg="12">
                        <v-img v-if="$vuetify.breakpoint.lgOnly" src="images/graphBudgetCt/barreFlecheBudgetCT-lg.png"></v-img>
                        <v-img v-if="$vuetify.breakpoint.mdOnly" src="images/graphBudgetCt/barreFlecheBudgetCT-md.png"></v-img>
                        <v-img v-if="$vuetify.breakpoint.smOnly" src="images/graphBudgetCt/barreFlecheBudgetCT-sm.png"></v-img>
                        <v-img v-if="$vuetify.breakpoint.xlOnly" src="images/graphBudgetCt/barreFlecheBudgetCT-xl.png"></v-img>
                        <v-img v-if="$vuetify.breakpoint.xsOnly" src="images/graphBudgetCt/barreFlecheBudgetCT-xs.png"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <!--Section Our Services-->
        <section class="section-md bg-gray text-center text-sm-left text-left py-4" style=" background-color: var(--btn-color)">
            <v-container>
                <v-row justify="center">
                    <h5 class="my-2 white--text">NOS 3 FORMULES D'ABONNEMENT</h5>
                </v-row>
                <v-row justify="center">
                    <v-col lg="11">
                        <v-row justify="center" class="mb-3">
                            <v-col lg="12">
                                <v-row justify="center">
                                    <v-col v-for="(colors, index) in items" :key="index" col="4">
                                        <v-card :color="colors.card" class="rounded-xl">
                                            <v-card-text class="pa-0 ma-0">
                                                <v-row class="pa-0 ma-0 rounded" justify="center">
                                                        <h6 class="my-2 pa-1 px-3 mt-5 font-weight-bold rounded-pill" style="background-color: #F24C27;font-size: 1.7em;color: white">FORMULE {{ colors.name }}</h6>
                                                </v-row>
                                                <!--<v-row class="pa-0 ma-0" justify="center">
                                                    <v-avatar class="font-weight-bold" :color="colors.icon" style="font-size: 1.2em">{{ index + 1 }}</v-avatar>
                                                </v-row>-->
                                                <!--<v-row class="pa-0 ma-0" justify="center">
                                                    <p class="pa-0 ma-0 font-weight-bold" :style="{'color':colors.text}">{{colors.texte}}</p>
                                                </v-row>-->
                                                <!--<v-row class="pa-0 ma-0" justify="center">
                                                    <p class="pa-0 ma-0 font-weight-bold" :style="{'color':colors.text}">{{ 1 + index }} VÉHICULE{{index===0?"":"S"}}</p>
                                                </v-row>-->
                                                <v-row class="pa-0 ma-0" justify="center">
                                                    <p class="font-weight-bold" :style="{'color':colors.text}">Abondement maximum 150€</p>
                                                </v-row>
                                                <v-row class="pa-0 ma-0" justify="center">
                                                    <p class="font-weight-bold" :style="{'color':colors.text}" style="font-size: 1.7em">{{(index + 1) * 5}}€/mois</p>
                                                </v-row>
                                                <v-row class="pa-2 ma-0" justify="center">
                                                    <b-button  @click="register" style="color:white" class="btn btn-sushi btn-sm mr-2 pa-1 ma-0 px-2 rounded-pill"><span class="text-lg-caption" >Souscrire</span></b-button>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <div class="row flow-offset-1 clearleft-custom-2">
                            <div class="col-xs-12 col-sm-6">
                                <div class="media media-mod-1 inset-4">
                                    <div class="media-left">
                                        <v-img
                                                style=" transform: scaleX(-1);"
                                                max-height="100"
                                                max-width="100"
                                                src="images/budgetct/piggy_bank.png"
                                        >  </v-img>
                                    </div>
                                    <div class="media-body">
                                        <h4 style="color: white">Mon budget et ma sécurité maîtrisés</h4>
                                        <span class="pa-0 ma-0 text-info">Je n'hésite pas, je m'abonne !</span>
                                        <p class="pa-0 ma-0 mt-1 text-justify text-info">
                                            Pour quelques euros par mois vous anticipez les frais liés au contrôle technique périodique ou volontaire de votre véhicule. Vous gérez votre budget et roulez en toute sérénité. Cette formule permet de préparer le budget de votre contrôle technique en totalité ou partiellement. Vous décaissez votre budget sécurité quand vous le désirez pour payer la réservation de votre contrôle technique tout en bénéficiant des offres promotionnelles proposées par nos partenaires.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="media media-mod-1 inset-4">
                                    <div class="media-left">
                                        <v-img
                                                max-height="100"
                                                max-width="100"
                                                src="images/budgetct/plus_icons.png"
                                        ></v-img>
                                    </div>
                                    <div class="media-body">
                                        <h4 style="color: white">Quels sont mes avantages pour mes 150€ d'abondement ?</h4>
                                        <p  class="text-justify text-info">
                                            Cumulée petit à petit, sans grever votre budget. Cette somme vous permet d'assurer les frais du contrôle technique périodique ou volontaire de votre véhicule en toute quiétude, en amont.<br/>Nos partenaires sont informés de votre abondement régulièrement pour vous faire leurs meilleurs propositions tarifaires. Vous restez maître de l'acceptation des offres proposées par nos partenaires ainsi que de la date et l'heure de votre choix pour effectuer votre contrôle technique.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="media media-mod-1 inset-4">
                                    <div class="media-left">
                                        <v-img
                                                max-height="100"
                                                max-width="100"
                                                src="images/budgetct/safe.png"
                                        ></v-img>
                                    </div>
                                    <div class="media-body">
                                        <h4 style="color: white">Comment marche ma Bourse CT ?</h4>
                                        <p class="text-justify text-info">
                                            Vous mettez de côté, chaque mois le montant de la formule choisie.  Cette somme cumulée vous permet de régler le contrôle technique périodique ou volontaire de votre ou de vos véhicules. L'abondement maximum est de 150€ utilisable jusqu'à 12 mois à date de votre dernier abondement.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="media media-mod-1 inset-4">
                                    <div class="media-left">
                                        <v-img
                                                max-height="100"
                                                max-width="100"
                                                src="images/budgetct/coins.png"
                                        ></v-img>
                                    </div>
                                    <div class="media-body">
                                        <h4 style="color: white">Comment et quand utiliser ma Bourse CT ?</h4>
                                        <p class="text-justify text-info">
                                            <span class="font-weight-bold bold-custom">Vous avez atteint un montant égal ou supérieur</span> au coût
                                            d'un contrôle technique périodique, vous pouvez le régler avec la somme cumulée.<br/>
                                            Le montant restant est votre réserve pour vos prochains rendez-vous en centre.<br/>
                                            <span class="font-weight-bold bold-custom">Votre bourse doit avoir atteint le montant minimum de 30€ </span>pour pouvoir être utilisée et si nécessaire complété en ligne lors de votre réservation.<br/>
                                            Avec ma Bourse CT,  j'anticipe et je gère
                                            de mon budget contrôle technique.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-dialog
                            class="ma-0 pa-0"
                            v-model="emailConfirmationDialog"
                            width="unset"
                    >
                        <v-alert
                                class="ma-0 "
                                prominent

                                type="success"
                        >Votre compte a été activé</v-alert>
                    </v-dialog>
                </v-row>
                <v-row justify="center">
                    <v-dialog
                            class="ma-0 pa-0"
                            v-model="tokenStatus"
                            width="unset"
                    >
                        <v-alert
                                class="ma-0 "
                                prominent

                                type="error"
                        >Votre compte est déjà activé</v-alert>
                    </v-dialog>
                </v-row>
            </v-container>
        </section>
    </div>
</template>

<script>

    import { mdiPiggyBankOutline, mdiAccount, mdiAccountCheck, mdiCash, mdiCash100, mdiCreditCard, mdiHeadDotsHorizontal } from '@mdi/js'
    import loginComponent from './client/loginComponent'
    import FormRegisterComponent from './client/FormRegisterComponent'
    import dialogEmail from './client/DialogEmail'
    export default {
        name: "BudgetControleTechniqueComponent",
        components:{loginComponent,FormRegisterComponent,dialogEmail},
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    /* el.removeEventListener('mouseenter');
                     el.removeEventListener('mouseleave')*/
                }
            }
        },
        data() {
            return {
                piggyBank:mdiPiggyBankOutline,
                tokenStatus:false,
                emailConfirmationDialog:false,
                emailConfirmation:false,
                dialogLogin:false,
                dialogEmail:false,
                dialogRegister:false,
                more: false,
                steps: ["Je Crée mon compte", "J'active ma bourse", "J'abonde ma bourse", "J'atteinds mon plafond", "Je règle mon contrôle", "J'anticipe mes futurs contrôle"],
                icon:  [mdiAccount, mdiAccountCheck, mdiCash, mdiCash100, mdiCreditCard, mdiHeadDotsHorizontal],
                items: [
                    {
                        card: '#FFF',
                        icon: '#038B8BFF',
                        text: '#025578',
                        name:'Confort',
                        texte:'Je pense passer mon contrôle technique d\'ici 30 mois'
                    },
                    {
                        card: '#FFF' ,
                        icon: '#dad11a',
                        text: '#025578',
                        name:'Sérénité',
                        texte:'Je pense passer mon contrôle technique d\'ici 15 mois'
                    },
                    {
                        card: '#FFF',
                        icon: '#f14c27',
                        text: '#025578',
                        name:'Premium',
                        texte:'Je pense passer mon contrôle technique d\'ici 10 mois'
                    }
                ]
            }
        },
        methods:{
            closeDialogRegister(){
                setTimeout(()=>{
                    this.dialogRegister=false
                },2000)

            },
            myAccount(){
                this.$router.push({name:'perso-user'})
            },
            recoverPassword(){
                this.dialogLogin= false
                this.dialogEmail= true
            },
            closeDialogRecoverEmail(){
                this.dialogEmail = false
            },
            connexion(){
                this.dialogLogin = true
            },
            register(){
                this.$store.commit('incRegisterCounter')
                this.dialogRegister = true
            },
            deconnexion(){
                this.$store.commit('setIsNotAuth')
            },
            prompConfirmationEmail(){
                this.emailConfirmationDialog=true
                setTimeout(()=>{
                    this.emailConfirmationDialog=false
                },4000)

            },
            tokenNotAvailable(){
                this.tokenStatus=true
                setTimeout(()=>{
                    this.tokenStatus=false
                },3000)
            }
        },
        async mounted() {
            this.$vuetify.goTo(0)
            await this.$store.dispatch("getData",localStorage.getItem('token'))
            const {token} = this.$route.params
            if(token !== undefined){
                const {emailConfirmation,tokenAvailable} = await this.$store.dispatch("confirmationEmail",{token:token})
                if(tokenAvailable!==undefined)
                    if(!tokenAvailable)
                        this.tokenNotAvailable()
                if(emailConfirmation!== undefined)
                    if(emailConfirmation)
                        this.prompConfirmationEmail()
                this.$router.push({name:'BudgetCT'})
            }
        }
    }
</script>

<style scoped>
    .arrow-class{
        font-size: 70px;
        color: #DBD21A;
    }
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 17px!important;
    }
    .text-big-title{
        font-family: Lato, sans-serif!important;
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-title{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
    .block-card{
        background-color: #025578;
    }
    .big-text{
        color:white;
        font-size: 28px;
        font-weight: 900;
        line-height: 1.2em;
    }
    .color-bottom{
        color:#DBD21A!important;
    }
    .text-style-card{
        line-height: 1.1em!important;
        font-family: Lato, sans-serif;
        color: white;
        font-size: 17px!important;
    }
    .text-style-mini-title{
        line-height: 1.1em!important;
        font-family: Lato, sans-serif;
        color: white;
        font-size: 20px!important;
        font-weight: bold;
    }
    .text-arrowed{
        line-height: 1.1em!important;
        font-family: Lato, sans-serif;
        color: black;
        font-size: 17px!important;
        text-align: center;
        vertical-align: middle;
    }
    .bg
    {
        height: 380px;
        background-image: url("../../../public/images/budgetCT.jpeg");
    }
    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 85%!important;
    }
    .bold-custom{
        font-size: 15px;
    }
</style>
