<template>
  <v-app>
  <Home></Home>
  </v-app>
</template>

<script>
import Home from "./pages/HomePage";
export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
  mounted() {
    this.$loadScript("/js/core.min.js").then(()=>{
      this.$loadScript("/js/script.js")
    })

  }
};
</script>
<style>
</style>
