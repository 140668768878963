<template>
    <v-row justify="center">
        <v-col cols="10" class="py-10 my-10">
            <v-row>
                <v-dialog v-model="dialogPromo" persistent width="700">
                    <v-card class="pa-0 ma-0">
                        <form-promo @closeDialog="closeDialogPromo"></form-promo>
                    </v-card>
                </v-dialog>
            </v-row>

            <!-- <table>
                 <thead>
                 <tr>

                 </tr>
                 <tr>
                     <td>Date début</td>
                 </tr>
                 </thead>
                 <tbody>

                 </tbody>
             </table>
 -->
            <v-row>
                <span class="title-text" style="color:black">{{centre.ctr_nom}} - {{centre.ctr_agrement}}</span>
            </v-row>
            <v-row v-for="(prestation,index) in centre.tarifs" :key="index" class="mb-4">
                <v-col>
                    <div style="background-color: var(--btn-color)">
                        <v-row justify="center" class="mt-1 px-1 rounded" align="center">
                            <v-col><span class="title-text" >Prestation : {{prestation.typ_vst_libele_court}} - Tarif : {{prestation.pst_tarif}}€</span></v-col>
                            <v-col>
                                <v-row justify="end" align="center" class="px-3">
                                    <v-btn color="white" style="color: black" dark class="mb-2 rounded-pill" v-on:click="addItem(prestation)">
                                        Ajout promotion
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-3 mt-0 py-0 my-0 text-white">
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Date début</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Date fin</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Heure début</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Heure fin</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Type de remise</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Remise</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Tarif remisé</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">Jours</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center" class="px-2 py-2">
                                    <span class="text-style-table">Nombre total</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center" class="px-2 py-2">
                                    <span class="text-style-table">Nombre utilisé</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center" class="px-2 py-2">
                                    <span class="text-style-table" style="text-transform: capitalize"></span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- <v-dialog
                             v-model="dialog2"
                             max-width="500px"
                             hide-overlay
                             persistent
                             style="background-color: white"
                     >

                                 <v-col cols="2" v-for="(jour,index) in jourSemaine" :key="index">
                                     {{jour}}
                                 </v-col>

                     </v-dialog>-->
                    <div class="border-table">
                        <v-row class="px-2 py-0 my-0 " v-for="(promotion,indexPromo) in prestation.promotions" :key="indexPromo">
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">{{formatDate(promotion.dateDebut)}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">{{formatDate(promotion.dateFin)}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">{{promotion.heureDebut}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">{{promotion.heureFin}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">{{promotion.montant === 0 ? "Pourcentage" : "Montant"}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-2 py-2">
                                    <span class="text-style-table">{{promotion.montant === 0 ? promotion.pourcentage+"%" : promotion.montant+"€"}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="start" class="px-4 py-2">
                                    <span class="text-style-table">{{promotion.montant === 0 ? parseFloat(prestation.pst_tarif - ((promotion.pourcentage/100)*prestation.pst_tarif)).toFixed(2)+"€" : prestation.pst_tarif - promotion.montant+"€"}}</span>
                                </v-row>
                            </v-col>
                            <v-col style="overflow: hidden">
                                <v-row justify="start" class="px-2 py-2">
                                    <v-menu offset-y :open-on-hover="test">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on" class="text-style-table rounded-pill px-2" style="overflow: hidden;background-color: #DBD21A" @mouseleave="closeDaydialog" @mouseover="displayJoursSemaine(promotion.jours_applicable)">{{promotion.jours_applicable.length}} sur 7</span>
                                        </template>
                                        <v-list class="py-0">
                                            <v-list-item
                                                    v-for="(item, index) in displayJoursSemaine(promotion.jours_applicable)"
                                                    :key="index"
                                            >
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center" class="px-2 py-2">
                                    <span class="text-style-table">{{promotion.nb_total}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center" class="px-2 py-2">
                                    <span class="text-style-table">{{promotion.counter_used}}</span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center" class="px-2 py-2">
                                    <span class="text-style-table" style="text-transform: capitalize">
                                        <v-icon   @click="editItem(promotion,prestation)">{{mdiLeadPencil}}</v-icon>
                                        <v-icon  @click="deleteItem(promotion,prestation)">{{mdiDelete}}</v-icon>
                                    </span>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-2 py-2 my-0 "v-if="prestation.promotions.length===0" justify="center">
                            <span>Pas de promotion en cours</span>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import formPromotion from "./formPromotion";
    import { mdiLeadPencil,mdiDelete } from '@mdi/js';
    export default {
        name: "centre",
        components:{
            'form-promo':formPromotion,
        },
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    /* el.removeEventListener('mouseenter');
                     el.removeEventListener('mouseleave')*/
                }
            }
        },
        data: () => ({
            test:true,
            jourSemaine:[],
            dialog2:false,
            mdiDelete:mdiDelete,
            mdiLeadPencil:mdiLeadPencil,
            promotions:[],
            headers: [
                {
                    text: 'Nombre utilisation',
                    align: 'start',
                    sortable: false,
                    value: 'nb_total',
                },
                { text: 'Date début', value: 'dateDebut' },
                { text: 'Date fin', value: 'dateFin' },
                { text: 'Heure début', value: 'heureDebut' },
                { text: 'Heure fin', value: 'heureFin' },
                { text: 'Pourcentage (%)', value: 'pourcentage' },
                { text: 'Montant fixe', value: 'montant' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            centre:'',
            dialogPromo:false,
        }),
        async mounted() {
            this.$store.commit('setStatusClient',false)
            this.centre = this.$store.getters.centrePicked
            if(!this.centre){
                this.$router.push({name:'espace-centre'})
            }
        },
        methods:{
            closeDaydialog(){
                // this.dialog2=false
            },
            displayJoursSemaine(inputs){
                return inputs.slice().sort(this.sortDayInWeekAsc)
            },
            sortDayInWeekAsc(a,b){
                const days= ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]
                if(days.indexOf(a)<days.indexOf(b))return -1
                if(days.indexOf(a)>days.indexOf(b))return 1
                return 0
            },
            formatJours(value){
                const days= ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]

                const arrayToString= (inputs,index=0,acc='')=> inputs.length  >index ? arrayToString(inputs,index+1,`${acc.length ===0 ? inputs[index]:`${acc},${inputs[index]}`}`) : acc
                return arrayToString(value.slice().sort(this.sortDayInWeekAsc))

            },
            setDigitFormat(value){
                return value.toString().length ===1 ? '0'+value : value
            },
            formatDate(item){
                const date= new Date(item)
                return `${this.setDigitFormat(date.getDate())}/${this.setDigitFormat(date.getMonth()+1)}/${date.getFullYear()}`
            },
            formatHeureFin(item){
                const date = new Date(item)
                return date.toTimeString()
            },
            closeDialogPromo(){
                this.dialogPromo=false
            },
            editItem(item,prestation){
                this.$store.commit("setPrestationPicked",prestation)
                this.$store.commit('setPromotionPicked',item)
                this.$store.commit("incrementCounter")
                this.dialogPromo = true
            },
            async deleteItem(promotion,prestation){
                this.$store.commit("setPrestationPicked",prestation)
                const response = await this.$store.dispatch('deletePromotion',promotion)
                this.$store.commit('setPromotionPrestation',response)
            },
            addItem(item){
                console.log(item)
                this.$store.commit("setPrestationPicked",item)
                this.$store.commit("incrementCounter")
                this.dialogPromo = true
            }
        }
    }
</script>

<style scoped>
    .bg-header-color-pro{
        background-color: #89B951!important;
    }
    .title-text{
        color: white;
        font-weight: bold;
        font-size: 22px;
    }
    .border-table{
        border-bottom: 1px solid #89B951;
        border-right:1px solid #89B951;
        border-left:1px solid #89B951;
    }
    span{
        font-size: 18px;
    }

</style>
