<template>
    <v-container>
        <div class="pa-0 ma-0" v-if="Object.getOwnPropertyNames(this.field)[0]!='email'">
            <v-col cols="12"class="rounded-lg">
                <v-row>
                    <v-toolbar class="" color="var(--btn-color)" dark>
                        <v-row justify="center" align="center">
                            <span style="font-size: 1.4rem">{{fieldFormat[getPropertyField()]}}</span>
                        </v-row>
                    </v-toolbar>
                </v-row>

                    <v-form class="my-4 rounded-sm pa-3 pt-0 mt-0">
                        <v-row justify="center">
                            <v-col cols="9" class="mt-5 mb-0 pb-0">
                                <v-text-field
                                        filled
                                        clearable
                                        v-model="valueField ='' || field[getPropertyField()]"
                                        :label="`Changer votre ${fieldFormat[getPropertyField()].toLowerCase()}`"
                                        type="text"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mt-0 pt-0">

                                <b-button :disabled="Object.getOwnPropertyNames(this.field)[0]=='email'" @click="validate" style="color:white" class="btn btn-sushi btn-sm mb-0 pb-3">Valider</b-button>

                        </v-row>
                    </v-form>

            </v-col>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "fieldForm",
        props:['field'],
        methods:{
            getPropertyField(){
                return Object.getOwnPropertyNames(this.field)[0]
            },
            async validate(){
                if(Object.getOwnPropertyNames(this.field)[0]!='email'){
                    const response = await this.$store.dispatch("updateClient",{token:localStorage.getItem('token'),field:this.field})
                    this.$emit('refresh',response)
                }

            }
        },
        data(){
            return {
                fieldFormat: {civility: 'Civilité', lastName: 'Nom',firstName:'Prénom',email:'Email',phone:'Téléphone',ville:'Ville',code_postal:'Code Postal',nom_rue:'Libellé voie',num_rue:'Numéro voie',complement_adresse:'Complément Adresse'},
                valueField: '',
            }
        }

    }
</script>

<style scoped>

</style>
