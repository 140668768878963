<template>
    <div>
        <v-col v-if="spinner">
            <v-overlay :value="spinner"><v-progress-circular
                    :size="100"
                    :width="10"
                    color="#1F5578"
                    indeterminate
            ></v-progress-circular></v-overlay>
        </v-col>
        <v-dialog
                :value="dialogDailyScheduler"
                width="900px"
                v-if="!spinner"
                class="ma-0 pa-0"
                persistent

        >
            <v-card style="overflow: hidden" class="ma-0 pa-0">
                <v-card-title  justify="center" align="center" class="ma-0 pa-0">
                    <v-row justify="center" class="my-0 py-0">
                        <v-col class="my-0 pa-0">
                            <v-row justify="center" align="center" class="py-0 my-0 mx-0 px-0">
                                <v-col cols="12" class=" mx-0 px-0" >
                                    <v-row justify="center" align="center" class="px-0 pt-0">
                                        <v-col cols="10">
                                            <span class="text-style">
                                                Centre de contrôle technique :
                                            </span>
                                            <span class="text-title ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">
                                                {{$store.getters.centreSelected.ctr_nom}} {{$store.getters.centreSelected.adr_code_postal}} {{$store.getters.centreSelected.adr_ville}}
                                            </span>
                                        </v-col>
                                        <v-col cols="1"  style="position: absolute;right: -25px;top:10px">
                                            <v-row justify="end" align="center" style="position: absolute;background-color: white" >
                                                <!--<v-btn @click="closeDialogDailyScheduler">
                                                    <v-icon>{{mdiClose}}</v-icon>
                                                </v-btn>-->
                                                <v-btn
                                                        icon

                                                        @click="closeDialogDailyScheduler"

                                                >
                                                    <v-icon size="30" color="black">mdi-close</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" v-if="dispoDays">
                                        <span class="text-title mb-2">Choisissez votre date de contrôle technique</span>
                                    </v-row>
                                    <!--<v-row justify="center"><span class="pa-1 text-h8 rounded" style="text-align: center;text-transform: uppercase;font-weight: bold;background-color: var(&#45;&#45;btn-color);color: white">{{monthName}} {{year}}</span></v-row>-->
                                    <!--<span class="text-h8" style="text-align: center;text-transform: uppercase;font-weight: bold">{{$store.getters.centreSelected.ctr_nom}} {{$store.getters.centreSelected.adr_ville}} </span>-->
                                </v-col>

                                <!-- </v-row>
                                     <v-row justify="center" align="center" class="py-0 my-0 mx-0 px-0">-->
                                <!--<v-col cols="2" class="py-0 my-0 mx-0 px-0">
                                    <span class="text-h8" style="text-align: center;text-transform: uppercase;font-weight: bold"></span>
                                </v-col>-->
                            </v-row>

                        </v-col>

                    </v-row>
                </v-card-title>
                <v-card-text class="my-0 py-0 ma-0 mt-0 pt-0">
                    <v-col v-if="dispoDays">
                        <v-row class="mb-5" justify="center">
                            <v-slide-group center-active mandatory v-model="monthSelected">
                                <v-slide-item v-slot="{ active }" class="my-5" v-for="(month , index) in monthsAvailable" v-bind:key="index">
                                    <v-card :elevation="isGoodMonth(month) ? '12' : ''" class="mx-1 mx-sm-2" :width="setCardWidth()" v-bind:class="isGoodMonth(month) ? 'selected' : '' " v-on:click="pickMonth(month)">
                                        <v-card-title  style="background-color: #0379AA;color: white" class="justify-center pa-0">
                                            <span :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : 'text-md-body'" style="text-align: center; text-transform: capitalize" class="pa-0 ma-0">{{ month.moisStr }}</span>
                                        </v-card-title>
                                        <v-card-title>
                                            <v-row justify="center" class="">
                                                <span v-if="getBestPrice(getPromotionByMonth(month)).tarif" class="line-through-tarif mt-0" style="color:#F24C27;text-decoration:line-through;font-size: 0.9em">{{getPromotionByMonth(month).tarif ? getPromotionByMonth(month).tarif:$store.getters.centreSelected.bestTarif.pst_tarif}}&#8364;</span>
                                                <!--<span v-if="getBestPrice(getPromotionByMonth(month)).tarif" :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : 'text-md-body'" class="pa-0 ma-0">{{getPromotionByMonth(month).tarif ? getPromotionByMonth(month).tarif:$store.getters.centreSelected.bestTarif.pst_tarif}}&#8364;</span>-->
                                                <span :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : 'text-md-body'" class="pa-0 ma-0 ml-1"> {{getBestPrice(getPromotionByMonth(month)).tarif ? setDecimal(getBestPrice(getPromotionByMonth(month)).tarif):setDecimal($store.getters.centreSelected.bestTarif.pst_tarif)}}&#8364;</span>
                                            </v-row>
                                        </v-card-title>
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                        </v-row>
                        <v-row>
                            <v-col class="col-xs-1 ma-xs-1 pa-1" v-for="(day , index) in week[testsize()]" v-bind:key="index">
                                <!--<v-card>
                                    <v-card-title style="background-color: var(&#45;&#45;btn-color)">-->
                                <v-row justify="center" align="center" class="pb-1 mb-2">
                                    <span class="text-title" style="text-align: center;text-transform: capitalize">{{day}}</span>
                                </v-row>
                                <!--</v-card-title>
                            </v-card>-->
                            </v-col>
                        </v-row>
                        <v-row v-for="(week ,indexNumberOfWeeks) in this.daysByWeeks.length" v-bind:key="indexNumberOfWeeks">
                            <v-col :style="{visibility: getFormatDate(day) ? 'visible' : 'hidden'}" class="col-xs-2 ma-xs-1 pa-1 " v-for="(day , index) in getWeeksFromIndex(indexNumberOfWeeks)" v-bind:key="index">
                                <v-card v-on:click="setDateRDV(day)" :disabled="getFormatDate(day)=='Complet'" >
                                    <v-card-title  style="background-color: #0379AA;color: white" class="justify-center pa-0">
                                        <span style="text-align: center" :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : 'text-md-body'">{{getJour(day)}}</span>
                                    </v-card-title>
                                    <v-card-title>
                                        <v-row v-if="getFormatDate(day)!='Complet'" justify="center" class="">
                                            <span v-if="getBestPrice(applyPromo(day.date)).tarif" :class="$vuetify.breakpoint.smAndDown ? 'text-xs-price' : ''" class="line-through-tarif mt-0 text-md-body pa-0 ma-0" style="color:#F24C27;text-decoration:line-through">{{$store.getters.centreSelected.bestTarif.pst_tarif }}&#8364;</span>
                                            <span :class="$vuetify.breakpoint.smAndDown ? 'text-xs-price' : ''" class="ml-1 pa-0 ma-0">{{getBestPrice(applyPromo(day.date)).tarif ? setDecimal(getBestPrice(applyPromo(day.date)).tarif):setDecimal($store.getters.centreSelected.bestTarif.pst_tarif)}}&#8364;</span>
                                        </v-row>
                                        <v-row v-if="getFormatDate(day)=='Complet' && !isDatePassed(day.date)" justify="center" class="">
                                            <span class="line-through-tarif mt-0" :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : ''" :style="$vuetify.breakpoint.smAndDown ? '' : 'font-size: 0.9em'" style="color:#F24C27;"></span>
                                        </v-row>
                                        <v-row v-if="isDatePassed(day.date)" justify="center" class="">
                                            <span class="line-through-tarif mt-0" :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : ''" :style="$vuetify.breakpoint.smAndDown ? '' : 'font-size: 0.9em'" style="color:#F24C27;"></span>
                                        </v-row>
                                    </v-card-title>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="col-12" v-if="!dispoDays">
                        <v-alert
                                text
                                prominent
                                type="error"
                                icon="mdi-cloud-alert"
                                v-if="$store.getters.centreSelected"
                        >
                            Ce centre ne permet pas actuellement de prendre rendez-vous à partir du site d'ABS-GROUP. <br>Merci de contacter le centre par téléphone : {{getCentreTelephone($store.getters.centreSelected)}}
                        </v-alert>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import promotionHandler from '../../libraries/promotionHandler'
    import { mdiClose,mdiCloseThick } from '@mdi/js';
    export default {
        name: "DailyScheduler",
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    el.removeEventListener('mouseenter');
                    el.removeEventListener('mouseleave')
                }
            }
        },
        data() {
            return {
                spinner:false,
                dayNotDispo : false,
                dispoDays:'',
                week: [['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'],['lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.', 'dim.']],
                months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
                hours: ['8h - 9H', '9h - 10H', '10h - 11H', '11h - 12H'],
                monthsAvailable: '',
                monthSelected:'',
                currentMonth:'',
                hour: '',
                horaires: [],
                interval: 40,
                monthYear: '',
                daysOfMonth: [],
                numberOfWeeks: '',
                numberOfDaysInMonth: '',
                daysByWeeks: '',
                monthName: '',
                today:'',
                currentSearch: '',
                centres:"",
                centresSelected:'',
                prestation:{pst_tarif:60,reduction:20},
                dialogDailyScheduler:false,
                buttonStatus:false,
                year:'',
                daysInMonthWithDispo:[],
                mdiClose:mdiClose,
                mdiCloseThick:mdiCloseThick
            }
        },
        mounted() {
            const today = new Date()
            this.today= today

        },
        methods: {
            getCentreTelephone(centre){
            const {ctr_telephone} = centre
                return ctr_telephone.replaceAll('-',' ')
            },
            setDecimal(value){
                return parseFloat(value) % 1 === 0 ? parseFloat(value).toFixed(0) : parseFloat(value).toFixed(2)
            },
            getBestPrice(promotions){
                const {pst_tarif}=this.$store.getters.centreSelected.bestTarif
                const sortBestPromotion=(array,index=0,acc={},tarif=pst_tarif,result=pst_tarif)=>{
                    const {pourcentage,montant}= array[index]
                    if(parseInt(pourcentage)===0){
                        if(parseInt(result) > parseInt(tarif)-parseInt(montant)){
                            result =  parseInt(tarif)-parseInt(montant)
                            acc=array[index]
                        }
                    }else{
                        if(parseInt(result) > parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)){
                            result =   parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)
                            acc=array[index]
                        }
                    }
                    return array.length-1  > index? sortBestPromotion(array,index+1,acc,tarif,result) : {item:acc,tarif:result}
                }

                return promotions.length >0 ? sortBestPromotion(promotions): pst_tarif
            },
            applyPromo(date){
                const centre = this.$store.getters.centreSelected
                if(date){
                    const currentDate = new Date(date)
                    const promotions = centre.bestTarif.promotions.filter((promo)=>{
                        const dateDebut = new Date(promo.dateDebut)
                        const dateFin = new Date(promo.dateFin)
                        //console.log(`${date}  is available == ${promotionHandler.isDateAvailable(date,promo)}  weekIndex = ${promotionHandler.getIndexWeekByDate(date)}`)
                        return ((dateDebut<currentDate && dateFin> currentDate) || (this.compareDate(dateDebut,currentDate)) || (this.compareDate(dateFin,currentDate))) && promo.jours_usage[currentDate.getDay()] ==="true" && (promo.nb_total>0 || promo.nb_mensuel>0 || promo.nb_hebdo>0) && promotionHandler.isDateAvailable(date,promo)
                    })
                    return promotions
                }
                return []

            },
            getPromotionByMonth(month){

                const centre = this.$store.getters.centreSelected
                const promotions = centre.bestTarif.promotions.filter((promo)=>{
                    const today = new Date()
                    /* const dateDebutMois = new Date(today.getFullYear(),month.moisInt-1,1)
                     const dateFinMois = new Date(today.getFullYear(),month.moisInt,0)*/
                    const dateDebut = new Date(promo.dateDebut)
                    const dateFin = new Date(promo.dateFin)
                    return dateDebut.getMonth() <= month.moisInt-1 && month.moisInt-1 <=dateFin.getMonth()
                })
                return promotions

            },
            setCardWidth() {
                if (this.$vuetify.breakpoint.xsOnly) return 53
                if (this.$vuetify.breakpoint.smOnly) return 70
                if (this.$vuetify.breakpoint.mdOnly) return 100
                if (this.$vuetify.breakpoint.lgAndUp) return 120
            },
            testsize() {
                if (this.$vuetify.breakpoint.smAndDown)
                {
                    return 1
                }
                else
                {
                    return 0
                }
            },

            getJour(date){
                if(typeof date.date == "object")
                    return date.date.getDate()
            },
            isDatePassed(date){
                const today = new Date()
                if(typeof date == "object") {
                    return date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth() && date.getDate() == today.getDate() ? false : date < today ? true : false
                }
            },
            pickMonth(month) {
                // const lastDayOfMonth = new Date(month.annee,month.moisInt,0)
                // const firstDayOfMonth= new Date(month.annee,month.moisInt -1,1)
                // const objectRequest={
                //   idCentre:this.$store.getters.centreSelected.id_centre,
                //   dateDebut:this.formatDate(this.isFirstDayPassed(firstDayOfMonth) ? this.today: firstDayOfMonth),
                //   dateFin:this.formatDate(lastDayOfMonth)
                // }

                this.$store.commit('setCurrentSearchDate', `${month.annee}-${month.moisInt}`)
                this.initialize()
            },
            isGoodMonth(month) {
                return this.getMonth(this.$store.getters.currentSearch.date) + 1 === month.moisInt
            },
            bootstrap(){this.initialize()},
            getMonth : value => parseInt(value.match(/(?<=-)\d+/)[0]) - 1,
            getYear : value => value.match(/\d+(?=-)/)[0],
            countDaysOfMonth : daysInMonth => daysInMonth.reduce((acc, value) => acc + 1, 0),
            countWeeksInMonth : numberOfDays => Math.ceil(numberOfDays / 7),
            daysInMonth(month, year, index = 1, result = []){
                const date = new Date(year, month, index)
                const item= this.dispoDays===false ? date  : {
                    date:date,
                    dispo: this.dispoDays.filter((value)=>this.compareDate(this.inputToDate(value.DateJour[0]),date)).length === 0
                        ? false
                        : this.dispoDays.filter((value)=>this.compareDate(this.inputToDate(value.DateJour[0]),date))[0]
                }
                return month === date.getMonth() ? this.daysInMonth(month, year, index + 1, [...result, item]) : result
            },
            getDaysInWeeks(daysInMonth, index = 0, counter = 0, result = [], line = []){
                if (daysInMonth.length > index) {
                    line.push(daysInMonth[index])
                    result[counter] = line
                    if (daysInMonth[index].date.getDay() === 0) {
                        counter = counter + 1
                        line = []
                    }
                    return this.getDaysInWeeks(daysInMonth, index + 1, counter, result, [...line])
                } else {
                    return result
                }

            },
            getWeeksFromIndex(index) {
                return this.daysByWeeks[index]
            },
            getFormatDate(date) {
                this.dayNotDispo = false
                if(typeof date.date =='object' && date.dispo){
                    if(date.date.getFullYear() === this.today.getFullYear()){
                        if(date.date.getMonth() === this.today.getMonth()){
                            if(date.date.getDate()>=this.today.getDate())
                                return date.date.getDate()

                        }
                        if(date.date.getMonth()> this.today.getMonth())
                            return date.date.getDate()

                    }
                    if(date.date.getFullYear()> this.today.getFullYear())
                        return date.date.getDate()
                }else
                if(date.dispo === false){
                    return 'Complet'
                }

                return false

            },
            fillWeeks(array) {
                const fillVoid = (array, position, index = 0, result = []) =>
                    index < 7 - array.length ?
                        fillVoid(array, position, index + 1, [false, ...result]) :
                        position ?
                            [...result, ...array] :
                            [...array, ...result]

                return [
                    fillVoid(array[0], true),
                    ...array.slice(1, array.length - 1),
                    fillVoid(array[array.length - 1], false)
                ]
            },
            closeDialogDailyScheduler(){
                this.dialogDailyScheduler=false
            },
            async initialize(){
                const getForwardMonths = (array=[] ,index=0) => {
                    const todayRef = new Date()
                    const today = new Date(todayRef.getFullYear(), todayRef.getMonth(), 1)
                    return index < 6 ? getForwardMonths([...array, new Date(today.setMonth(today.getMonth() + index))], index + 1) : array
                }

                this.monthsAvailable = getForwardMonths().map((date) => ({moisStr : this.months[date.getMonth()], moisInt: date.getMonth() +1, annee : date.getFullYear()}))
                this.prestationSelected = this.$store.getters.prestationSelected
                this.currentSearch = this.$store.getters.currentSearch
                this.centresSelected = this.$store.getters.centresSelected
                this.monthYear = this.currentSearch.date
                const lastDayOfMonth = new Date(this.getYear(this.monthYear),this.getMonth(this.monthYear)+1,0)
                const firstDayOfMonth =new Date(this.getYear(this.monthYear),this.getMonth(this.monthYear),1)
                const objectRequest={
                    idCentre:this.$store.getters.centreSelected.id_centre_global,
                    dateDebut:this.formatDate(this.isFirstDayPassed(firstDayOfMonth) ? this.today: firstDayOfMonth),
                    dateFin:this.formatDate(lastDayOfMonth)
                }
                this.spinner=true
                this.dispoDays = await this.$store.dispatch('getDispo',objectRequest)
                if(!this.dispoDays){
                    if(this.$store.getters.centreSelected.ctr_url){
                        this.dialogDailyScheduler= false
                        window.open(this.$store.getters.centreSelected.ctr_url, '_blank');
                    }
                }

                this.daysOfMonth = this.daysInMonth(this.getMonth(this.monthYear), this.getYear(this.monthYear))
                this.spinner=false
                if(this.dispoDays){
                    this.numberOfDaysInMonth = this.countDaysOfMonth(this.daysOfMonth)
                    this.numberOfWeeks = this.countWeeksInMonth(this.numberOfDaysInMonth)
                    this.daysByWeeks = this.fillWeeks(this.getDaysInWeeks(this.daysOfMonth))
                    this.monthName = this.months[this.getMonth(this.monthYear)]
                    this.year = this.getYear(this.monthYear)
                    this.$store.commit('setDispoDays', this.daysOfMonth)
                }
            },
            setDateRDV(value){
                const datePicked = new Date(value.date)
                const promotions = this.applyPromo(datePicked)
                this.$store.commit("setDateSelected",value)
                this.$store.commit("setPromotionsSelected",promotions)
                this.dialogDailyScheduler = false
                this.buttonStatus = true
            },
            formatDate(date){
                const monthValue = date.getMonth()+1
                return `${date.getFullYear()}${this.transformDay(date.getMonth()+1)}${this.transformDay(date.getDate())}`
            },
            isFirstDayPassed(date){
                return this.today.getFullYear() === date.getFullYear() && this.today.getMonth() === date.getMonth() && date.getDate() < this.today.getDate()
            },
            transformDay(value){
                return value.toString().length === 1 ? '0'+value : value
            },
            inputToDate:input =>new Date(input),
            compareDate : (date1,date2)=> date1.getFullYear() === date2.getFullYear()
                && date1.getMonth() === date2.getMonth()
                && date1.getDate() === date2.getDate()


        },
        watch:{
            '$store.getters.timeCentreSelected':{
                deep:true,
                handler(value){
                    this.bootstrap()
                    this.dialogDailyScheduler= true
                    this.currentSearch = value
                }
            },
            '$store.getters.retourTimeSlot':{
                deep:true,
                handler(){
                    this.dialogDailyScheduler= true
                }
            }


        }
    }//background-color: #DBD21A;
</script>

<style scoped>
    .text-xs
    {
        font-size: 0.52em;
    }
    .text-xs-price
    {
        font-size: 0.52em;
    }

    .selected
    {
        bottom: 10px;
    }
    .text-style{
        font-family: Lato, sans-serif;
        color: black;
        font-size: 15px!important;
    }
    .text-title{
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
</style>
