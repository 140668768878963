import PoidsLourdsComponent from './PoidsLourdsComponent'
import VehiculeLegerComponent from './VehiculeLegerComponent'
import InformatiqueComponent from './InformatiqueComponent'
import AuditsComponent from './AuditsComponent'
import SocieteComponent from './SocieteComponent'
import HubComponent from './HubComponent'
import FormationsComponent from './FormationsComponent'
import ArtComponent from "../../components/ArtComponent";
import espaceCentre from "./espace-centre/espace-centre"
import centre from "./espace-centre/centre"
import ContactUsForm from "@/components/ContactUsComponent";
import informationsLegales from "@/components/informations-legales/InformationsLegalesComponent";

export const professionalRoutes = [
    {
        path: '/',
        name: 'Pro',
        component: HubComponent
    },
    {
        path: '/contact',
        name:'contact',
        component: ContactUsForm
    },
    {
        path: '/informations-legales',
        props: true,
        name: 'info-legal',
        component: informationsLegales
    },
    {
      path: "/espace-centre",
      name: "espace-centre",
      component: espaceCentre
    },
    {
        path: "/espace-centre/centre",
        name: "centre",
        component: centre
    },
    {
        path: "/hub-articles",
        name: "hub",
        component: HubComponent,
    },
    {
        path: "/poids-lourds/:nameUrl?",
        name: "poidsLourds",
        props: true,
        component: PoidsLourdsComponent,
    },
    {
        path: "/vehicules-legers/:nameUrl?",
        name: "vehiculesLegers",
        props: true,
        component: VehiculeLegerComponent
    },
    {
        path: "/informatique/:nameUrl?",
        name: "informatique",
        props: true,
        component: InformatiqueComponent
    },
    {
        path: "/audits/:nameUrl?",
        name: "audits",
        props: true,
        component: AuditsComponent
    },
    {
        path: "/qui-sommes-nous/:nameUrl?",
        name: "societe",
        props: true,
        component: SocieteComponent
    },
    {
        path: "/formations/:nameUrl?",
        name: "formations",
        props: true,
        component: FormationsComponent
    }
]
