<template>
    <div>
        <section class="section-full text-left bg">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Audit</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                                <router-link v-bind:to="{name:'hub'}">
                                    <button>Accueil</button>
                                </router-link>
                            </li>
                            <li v-show="idSelected == null" class="active">Services</li>
                            <li v-show="idSelected !== null">
                                <button v-on:click="redirectRoot">Services</button>
                            </li>
                            <li v-if="idSelected !== null" class="active">{{ articleSelected.titre }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!--Section popular categories 1-->
        <v-container class="mt-5 mb-16">
            <v-row class="text-center" v-if="idSelected== null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" v-for="article in articles" :key="article.id">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <v-row v-if="idSelected!== null">
                <ArtComponent :theme="articleSelected"></ArtComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ArtComponent from "../../components/ArtComponent.vue";

export default {
    name: "Audits",
    components:{
        ArtComponent
    },
    props: ['art', 'id','nameUrl'],
    mounted(){
        this.$vuetify.goTo(0)
        this.root = document.documentElement
        this.$store.commit('setStatusClient',false)
        this.root.style.setProperty('--btn-color', '#89B951')
        this.root.style.setProperty('--options-orange', '#89B951')
        if(this.nameUrl){
            this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==this.nameUrl)[0]
            this.idSelected = this.articleSelected.id
        }

    },
    methods:{
        redirectRoot(){
            this.resetArticle()
            this.$router.push(`/audits`)
        },
        setArticles(currentNameUrl){
            this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==currentNameUrl)[0]
            this.idSelected = this.articleSelected.id
        },
        setArt(val){
            this.articleSelected = val
            this.idSelected = val.id
            this.$router.push(`/audits/${val.nameUrl}`)
        },
        resetArticle(){
            this.idSelected= null
        }
    },
    watch:{
        'id':{
            handler(){
                if(this.id===null){
                    this.resetArticle()
                }
            }
        },
        'nameUrl':{
            handler(){
                if(this.nameUrl){
                    this.setArticles(this.nameUrl)
                }
            }
        }
    },
    data() {
        return {
            root:null,
            idSelected:null,
            articleSelected :'',
            articles: [
                {
                    id: 1,
                    img: '../images/img/A-1.jpg',
                    nameUrl:'audits-initiaux',
                    texte:
                        "AutoBilan-Systems est agréé par le Ministère chargé des transports pour réaliser les audits initiaux des centres de contrôle technique indépendants VL et PL (cf. CDC 21). Lors de ces audits nous vérifions, notamment, le respect des dispositions règlementaires relatives au bâtiment, aux matériels, à l'informatique et au personnel. Le rapport d'audit initial favorable est un élément constitutif du dossier de demande d'agrément d'un centre de contrôle technique.",
                    titre: "Audits initiaux centres VL & PL",
                    textHighLight:"",
                },
                {
                    id: 2,
                    nameUrl:'audits-reglementaires',
                    img: '../images/img/A-2.jpg',
                    texte:"AutoBilan-Systems est agréé par le Ministère chargé des transports pour réaliser les audits règlementaires annuels des centres de contrôle technique indépendants VL et PL (cf. CDC 21). Lors de ces audits nous vérifions, notamment :",
                    textList:[
                        "Le respect des exigences relatives à la norme NF EN ISO/CEI 17020 (pour le PL).",
                        "Le respect des dispositions règlementaires, des instructions techniques, des cahiers des charges, des recommandations techniques élaborées par l'OTC.",
                        "La présence, la cohérence, le contenu et l'application des procédures internes de l'installation mentionnées à l'annexe VII, chapitre III de l'arrêté ministériel du 18/06/1991 modifié (pour le VL).",
                        "La présence, la cohérence, le contenu et l'application des procédures internes de l'installation mentionnées à l'annexe V de l'arrêté ministériel du 27/07/2004 (pour le PL).",
                        "Le contenu, la cohérence et l'application des procédures non exigées par la réglementation, que l'installation a mis en place."
                    ],
                    titre: "Audits règlementaires centres VL & PL",
                    textHighLight:"",
                },
                {
                    id: 3,
                    nameUrl:'audits-controleurs',
                    img: '../images/img/A-3.jpg',
                    texte:
                        "AutoBilan-Systems est agréé par le Ministère chargé des transports pour réaliser les audits règlementaires des contrôleurs VL et PL indépendants (cf. CDC 21). L'audit d'un contrôleur est constitué du suivi d'une visite technique périodique et de l'étude des procès-verbaux réalisés par le contrôleur et archivés conformément aux procédures de l'installation. L'auditeur s'assure également que le contrôleur maîtrise l'ensemble des textes applicables. Un contrôleur technique de véhicules légers doit pouvoir justifier d'un audit au moins une fois toutes les deux années civiles pour maintenir sa qualification.<br> Un contrôleur technique de véhicules lourds doit pouvoir justifier d'un audit au moins une fois par année civile pour maintenir chacune de ses qualifications.",
                    titre: "Audits règlementaires contrôleurs VL & PL",
                    textHighLight:"",
                },
                {
                    id: 4,
                    nameUrl:'audits-internes',
                    img: '../images/img/A-4.jpg',
                    texte:
                        "AutoBilan-Systems peut également réaliser l'audit interne d'installations VL et PL. Le contenu des audits internes est fonction du besoin de l'installation, et couvre à minima le périmètre de l'audit règlementaire d'une installation.",
                    titre: "Audits internes",
                    textHighLight:"",
                },
            ],
        };
    },
};
</script>

<style scoped>
.bg
{
    background-image: url("../../../public/images/bandeaux/bandeau-audit.jpeg");
}
#articles
{
    text-align: center;
}
</style>
