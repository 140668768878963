import Vue from "vue";
import App from './App.vue';

import { particulierRoutes } from './modules/Particulier/routes'
import { professionalRoutes } from './modules/Professional/routes'
import { RootStore } from "./store";
import VDigitalTimePicker from 'v-digital-time-picker'
import Vuex from 'vuex';
import VueRouter from "vue-router"
import vuetify from "./plugins/vuetify";
import LoadScript from "vue-plugin-load-script";
import colorsHandler from "./libraries/colorsHandler"
// Boostrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import ToggleButton from 'vue-js-toggle-button'
//GoogleMaps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps,{
  load: {
    key: 'AIzaSyBs9p5zKlf3sCH5w62p-VAG9dzwzmlLEyw',
    region: 'FR',
    language: 'fr',
    //libraries: 'places',  This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})
Vue.use(mdiVue, {
  icons: mdijs
})
Vue.use(ToggleButton)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LoadScript)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VDigitalTimePicker)
Vue.use(colorsHandler)


const routes = `${window.location.protocol}//${window.location.host}` ==process.env.VUE_APP_URL_PARTICULIER ? particulierRoutes :professionalRoutes
const store = new Vuex.Store(RootStore)
const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
