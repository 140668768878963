import budgetct from "./budgetCtComponent";
import alertect from "./AlerteCtComponent";
import FormReservationComponent from "./FormReservationComponent"
import budgetCtComponent from "./budgetCtComponent";
import formRegister from "./client/FormRegisterComponent"
import authComponent from "./client/loginComponent";
import almaComponent from "./payment/AlmaFormComponent"
import LastBudgetCt from "./BudgetControleTechniqueComponent"
import ReturnPaymentComponent from "./payment/ReturnPaymentComponent";
import formPayment from "./payment/FormPayment"
import recoverPassword from "./client/resetPassword"
import bctSpace from "./client/bctSpace";
import recruitComponent from "./FormRecruit"
import ParticulierPage from "@/modules/Particulier/ParticulierPage";
import ContactUsForm from "@/components/ContactUsComponent";
import informationsLegales from "@/components/informations-legales/InformationsLegalesComponent";
export const particulierRoutes = [
    {
        path: '/',
        name: 'particulier',
        component: ParticulierPage
    },
    {
        path: '/contact',
        name:'contact',
        component: ContactUsForm
    },
    {
        path: '/informations-legales',
        props: true,
        name: 'info-legal',
        component: informationsLegales
    },
    {
      path:'/perso-user',
      name:'perso-user',
      component:bctSpace
    },
    {
      path:'/recover-password/:token',
        name:'recover-password',
        component:recoverPassword
    },
    {
      path:'/form-reservation',
      name: 'form',
      component:formPayment
    },
    {
        path:'/return-payment/:data?',
        name: 'return-payment',
        component: ReturnPaymentComponent
    },
    {
      path:'/alma',
      name: 'alma',
      component: almaComponent
    },
    {
        path: '/budgetct/:token?',
        name: 'BudgetCT',
        component: LastBudgetCt,
        scrollBehavior (to, from, savedPosition) {
            return { x: 0, y: -5000 }
        }
    },
    {
        path: '/alertect',
        name: 'alertect',
        component: alertect
    },
    {
        path: '/cartegrise',
        name:'cartegrise',
        component:FormReservationComponent
    },
    {
        path:'/register',
        name:'register',
        component:formRegister
    },
    {
        path:'/auth',
        name:'auth',
        component:authComponent
    },
    {
        path:'/recruit',
        name:'recruit',
        component:recruitComponent
    }
]
