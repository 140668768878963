<template>
    <div class="pa-0 ma-0">
        <v-col cols="12"class="rounded-lg">
            <v-row>
                <v-toolbar class="" color="var(--btn-color)" dark>
                        <v-row justify="center" align="center">
                                <span style="font-size: 1.2rem">Se connecter à ma bourse CT</span>
                        </v-row>
                </v-toolbar>
            </v-row>
            <v-row justify="center" class="ml-4">
                <v-col cols="9">
                    <v-row justify="center" class="mt-3">
                        <v-alert
                                dense
                                text
                                type="error"
                                v-if="response.email==false"
                        >
                            Cet email n'est pas valide
                        </v-alert>
                        <v-alert
                                dense
                                outlined
                                type="error"
                                v-if="response.password==false"
                        >
                            Mot de passe incorrecte
                        </v-alert>
                        <v-alert
                                dense
                                outlined
                                type="error"
                                v-if="response.activate==false"
                        >
                            Votre compte n'est pas activé
                        </v-alert>
                        <v-alert
                                dense
                                outlined
                                type="success"
                                v-if="$store.getters.authenticate"
                                class="my-3"
                        >
                            Vous êtes authentifié
                        </v-alert>
                    </v-row>
                </v-col>
            </v-row>
                    <v-form class="my-4 rounded-sm pa-3 pt-0 mt-0" v-if="$store.getters.authenticate ===false">

                        <v-row justify="center">
                            <v-col cols="9" class="mt-5 mb-0 pb-0">
                                <v-text-field
                                        :prepend-icon="icons.mdiAccountBox"
                                        v-model="email"
                                        filled
                                        clear-icon="mdi-close-circle"
                                        clearable
                                        label="Saisir votre email"
                                        type="text"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mb-0 pb-0">
                            <v-col cols="9" class="mb-0 pb-0">
                                <v-text-field
                                        class="my-0 py-0"
                                        :prepend-icon="icons.mdiLock"
                                        v-model="password"
                                        type="password"
                                        filled
                                        clear-icon="mdi-close-circle"
                                        clearable
                                        label="Saisir votre mot de passe"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mb-2 pb-2 mt-0 pt-0 ">
                            <span><a @click="recoverPassword">Mot de passe oublié ? Cliquez ici</a></span>
                        </v-row>
                        <v-row justify="center" class="mt-0 pt-0">
                            <!--<v-col cols="4" class="mt-0 pt-0">-->
                                <b-button  @click="connexion" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Connexion</b-button>
                                <b-button v-if="paramRegister"  @click="connexion" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Créer mon compte</b-button>
                                <b-button v-if="paramReturn" v-on:click="retour"  style="color:white;background-color: red" class="btn btn-sushi btn-sm mr-2 rounded-pill">Retour</b-button>
                            <!--</v-col>-->
                        </v-row>
                    </v-form>
        </v-col>
    </div>
</template>

<script>
    import { mdiLock,mdiAccountBox } from '@mdi/js';
    export default {
        name: "authComponent",
        props:['paramRegister','paramReturn'],
        async mounted(){
           await this.$store.dispatch("getData",localStorage.getItem('token'))

        },
        data(){
            return{
                response:'',
                show4:false,
                password: '',
                show: false,
                email:'',
                marker: true,
                iconIndex: 0,
                icons:{mdiAccountBox,mdiLock}
            }
        },
        methods:{
            retour(){
              this.$emit('retourDialog')
            },
            recoverPassword(){
              this.$emit("recoverPassword",true)
            },
            async connexion(){
                this.response=''
                const object = {
                    email:this.email,
                    password: this.password
                }
                this.response  = await this.$store.dispatch("authFunc",object)


            },

        }
    }
</script>

<style scoped>
    .main-col{
        box-shadow: 0px 3px 6px 3px var(--btn-color);
    }
</style>
