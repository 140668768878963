<template>
    <v-card>
        <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>


            <v-toolbar color="var(--btn-color)" dark>
                <v-row justify="center" align="center">
                    <v-col>
                        <span style="font-size: 1.2rem">Promotion : {{$store.getters.prestationPicked.typ_vst_libele_court}}  Tarif initial {{$store.getters.prestationPicked.pst_tarif}}€</span>
                    </v-col>
                </v-row>
            </v-toolbar>


            <v-card-text class="mt-0 pt-0" elevation="4">

                <v-row justify="center">
                    <v-col class="col-10">
                        <v-row justify="center">
                            <v-col>
                                <v-radio-group row class="justify-center" v-model="choixType" v-on:change="resetValue">
                                    <v-row justify="center" align="center">

                                        <v-col cols="8" sm="8" md="5" >
                                            <v-radio label="Pourcentage à déduire" :value="true"></v-radio>
                                        </v-col>

                                        <v-col cols="8" sm="8" md="5" >
                                            <v-radio label="Montant à déduire" :value="false"></v-radio>
                                        </v-col>

                                    </v-row>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row justify="start" align="start" class="mt-4 pt-2" v-if="choixType">
                            <v-col class="mt-0 pt-0" cols="6">
                                <v-text-field v-model="pourcentage" label="Pourcentage à déduire" :rules="pourcentageRules" required></v-text-field>
                            </v-col>

                            <v-col cols="6" class="mt-0">
                                <v-row align="center" justify="center" >
                                    <span style="font-size: 18px">Nouveau montant</span>
                                </v-row>
                                <v-row align="center" justify="center" class="mt-4 pt-2" >
                                    <span style="font-size: 16px">{{$store.getters.prestationPicked.pst_tarif - ((pourcentage/100)* $store.getters.prestationPicked.pst_tarif)  > 0 ? parseFloat($store.getters.prestationPicked.pst_tarif - ((pourcentage/100)* $store.getters.prestationPicked.pst_tarif)).toFixed(2) : $store.getters.prestationPicked.pst_tarif }}€</span>
                                </v-row>
                            </v-col>
<!--                            <v-col class="mb-2">
                                <v-progress-linear
                                    v-model="pourcentage"
                                    color="blue-grey"
                                    height="25"
                                >
                                    <template v-slot:default="{ value }">
                                        <strong>{{ Math.ceil(value) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>-->
                        </v-row>
                        <v-row justify="start" align="start" class="mt-4 pt-2"  v-if="!choixType">
                            <v-col class="mt-0 pt-0" cols="6">
                                <v-text-field v-model="montantFixe" label="Montant à déduire" :rules="montantFixeRules" required></v-text-field>
                            </v-col>
                            <v-col cols="6" class="mt-0">
                                <v-row align="center" justify="center" >
                                    <span style="font-size: 18px">Nouveau montant</span>
                                </v-row>
                                <v-row align="center" justify="center" class="mt-4 pt-2" >
                                    <span style="font-size: 16px">{{$store.getters.prestationPicked.pst_tarif - montantFixe  > 0 ? $store.getters.prestationPicked.pst_tarif - montantFixe : $store.getters.prestationPicked.pst_tarif}}€</span>
                                </v-row>
                            </v-col>
                        </v-row>


                        <v-row justify="start" align="start" class="mt-4 pt-2">
                            <v-col class="mt-0 pt-0" cols="6">
                                <v-text-field v-model="nombreUtilisationMax" label="Nombre d'utilisation maximum" :rules="nombreUtilisationRules" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="start" align="start" class="mt-4 pt-2">
                            <v-col class="mt-0 pt-0" cols="6">
                                <v-text-field v-model="nombreUtilisationMensuel" label="Nombre d'utilisation mensuelle" :rules="nombreUtilisationRules" required></v-text-field>
                            </v-col>
                            <v-col class="mt-0 pt-0" cols="6">
                                <v-text-field v-model="nombreUtilisationWeek" label="Nombre utilisation hebdomadaire" :rules="nombreUtilisationRules" required></v-text-field>
                            </v-col>
                            <!--<v-col class="mt-0 pt-0" cols="3">
                                <v-text-field v-model="nombreUtilisationWeek2" label="Semaine 2" :rules="nombreUtilisationRules" required></v-text-field>
                            </v-col>
                            <v-col class="mt-0 pt-0" cols="3">
                                <v-text-field v-model="nombreUtilisationWeek3" label="Semaine 3" :rules="nombreUtilisationRules" required></v-text-field>
                            </v-col>
                            <v-col class="mt-0 pt-0" cols="3">
                                <v-text-field v-model="nombreUtilisationWeek4" label="Semaine 4" :rules="nombreUtilisationRules" required></v-text-field>
                            </v-col>-->
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <v-select
                                    v-model="jourApplicable"
                                    :items="joursSemaine"
                                    chips
                                    label="Jours applicables"
                                    multiple
                                    solo
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="dates"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :rules="datesRules"
                                    required
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-combobox
                                            v-model="computedDateFormatted"
                                            multiple
                                            chips
                                            small-chips
                                            label="intervalles de date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="datesRules"
                                            required
                                        >

                                        </v-combobox>
                                    </template>
                                    <v-date-picker
                                        v-model="dates"
                                        hint="DD/MM/YYYY format"
                                        multiple
                                        no-title
                                        scrollable
                                        range
                                        locale="fr"
                                        :first-day-of-week="1"
                                        :allowed-dates="allowedDate"
                                        :rules="datesRules"
                                        required
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(dates)"
                                            required
                                            :rules="datesRules"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="3" class="">

                                <v-digital-time-picker
                                    v-model="heureDebut"
                                    rounded
                                    filled
                                    label="Heure début"
                                    :rules="basicRules"
                                    format="HH:mm"
                                />

                            </v-col>
                            <v-col cols="3">
                                <v-digital-time-picker
                                    v-model="heureFin"
                                    rounded
                                    filled
                                    label="Heure fin"
                                    :rules="basicRules"
                                    format="HH:mm"
                                />

                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <div class="text-center">
                    <b-button style="color:white;background-color: red"  @click="closeDialog" class="btn btn-sushi btn-sm rounded-pill">Fermer</b-button>
                </div>
                <div class="text-center">
                    <b-button style="color:white" @click="validate" :disabled="!valid" class="btn btn-sushi btn-sm rounded-pill">Enregistrer</b-button>
                </div>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import { VDigitalTimePicker } from 'v-digital-time-picker'
export default {
    name: "formPromotion",
    components:{
        'v-time-picker':VDigitalTimePicker
    },
    data: () => ({
        usage_applicability:'',
        nombreUtilisationMensuel:'',
        nombreUtilisationMax:'',
        nombreUtilisationWeek:'',
        jourApplicable:'',
        joursSemaine:['Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi','Dimanche'],
        heureDebut: '00:00',
        heureFin:'23:59',
        menuHour: false,
        dates:[],
        menu: false,
        nombreUtilisation:'',
        choixType:true,
        valid: false,
        pourcentage: 0,
        knowledge: 33,
        power: 78,
        montantFixe:0,
        pourcentageRules: [
           /* v => !!v || 'Champs vide',*/
            v=>v.toString().length> 0  && v.toString().match(/(^[0-9]{1,2}[\.\,]{1}[0-9]{1,2})|(^[0-9]{1,2})$/)!=null && v.toString().match(/(^[0-9]{0,3}[\.\,]{1}[0-9]{1,2})|(^[0-9]{0,3})$/)[0] === v.toString().match(/(^[0-9]{0,3}[\.\,]{1}[0-9]{1,2})|(^[0-9]{0,3})$/)['input']|| 'non valide'|| 'non valide',
        ],
        montantFixeRules:[
            v => !!v || 'Champs vide',
            v => v.toString().length> 0  && v.toString().match(/(^[0-9]{1,3}[\.\,]{1}[0-9]{1,3})|(^[0-9]{1,3})$/)!=null && v.toString().match(/(^[0-9]{0,3}[\.\,]{1}[0-9]{1,2})|(^[0-9]{0,3})$/)[0] === v.toString().match(/(^[0-9]{0,3}[\.\,]{1}[0-9]{1,2})|(^[0-9]{0,3})$/)['input']|| 'non valide',
        ],
        nombreUtilisationRules:[
            v => !!v || 'Champs vide',
            v => /^[0-9]{0,5}$/.test(v)|| 'non valide',
        ],
        datesRules:[
            v => v.length >0 || 'champs vide'
        ],
        basicRules: [v => !!v || 'Champs vide'],

    }),
    computed:{
        computedDateFormatted(){
            return this.setFormatDate(this.dates)
        },
        computedPourcentage(){
            return this.setPourcentage(this.pourcentage)
        }
    },
    mounted(){

    },
    methods:{
        setPourcentage(value){
            this.pourcentage=parseInt(value)
            return parseInt(value)
        },
        closePicker: function(v){
            v = v < 10 ? '0'+v : v;
            this.time = v+":00";
            this.menu = false
        },
        async validate(){

            const jourApplicable = {
                "0":this.jourApplicable.includes('Dimanche') === true ? "true":"false",
                "1":this.jourApplicable.includes('Lundi') === true? "true":"false",
                "2":this.jourApplicable.includes('Mardi') === true? "true":"false",
                "3":this.jourApplicable.includes('Mercredi') === true? "true":"false",
                "4":this.jourApplicable.includes('Jeudi') === true? "true":"false",
                "5":this.jourApplicable.includes('Vendredi') === true? "true":"false",
                "6":this.jourApplicable.includes('Samedi') === true? "true":"false",
            }

            this.$refs.form.validate()
            if(this.$refs.form.validate()){
                const {id_prestation} = this.$store.getters.prestationPicked
                const {id_centre} = this.$store.getters.centrePicked
                const item = (choixType) => {
                    return choixType ?
                        {
                            pourcentage: this.pourcentage,
                            montant:0,
                            dateDebut: new Date(this.dates[0]),
                            dateFin: new Date(this.dates[1]),
                            heureDebut: this.heureDebut,
                            heureFin: this.heureFin,
                            id_centre: id_centre,
                            id_prestation: id_prestation,
                            jours_usage:jourApplicable,
                            nb_hebdo:this.nombreUtilisationWeek,
                            nb_mensuel:this.nombreUtilisationMensuel,
                            nb_total:this.nombreUtilisationMax,
                            usage_applicability:this.usage_applicability

                        } :
                        {
                            montant: this.montantFixe,
                            pourcentage:0,
                            dateDebut: new Date(this.dates[0]),
                            dateFin: new Date(this.dates[1]),
                            heureDebut: this.heureDebut,
                            heureFin: this.heureFin,
                            id_centre: id_centre,
                            id_prestation: id_prestation,
                            jours_usage:jourApplicable,
                            nb_hebdo:this.nombreUtilisationWeek,
                            nb_mensuel:this.nombreUtilisationMensuel,
                            nb_total:this.nombreUtilisationMax,
                            usage_applicability:this.usage_applicability
                        }
                }
                const itemToSend = this.$store.getters.promotionPicked===null?
                    {
                        ...item(this.choixType)
                    }:
                    {
                        ...item(this.choixType),
                        id_promotion_abs:this.$store.getters.promotionPicked.id_promotion_abs
                    }

                const result = await this.$store.dispatch('setPromotion', {item:itemToSend,isNew:this.$store.getters.promotionPicked===null})
                this.$store.commit("setPromotionPrestation", await result)
                this.$emit('closeDialog', true)
                this.$store.commit('setPromotionPicked',null)


            }
        },
        setFormatDate (value){
            if(!value) return null
            const arrayDates = value.map((value)=>{
                const date = new Date(value)
                return `${this.setDigit(date.getDate())}/${this.setDigit(date.getMonth()+1)}/${date.getFullYear()}`
            })
            return arrayDates
        },
        setDigit(value){
            return value.toString().length===1 ? '0'+value:value
        },
        resetProps(){
            this.heureDebut='00:00'
            this.heureFin='23:59'
            this.dates.splice(0,this.dates.length)
            this.nombreUtilisation=''
            this.pourcentage=0
            this.montantFixe=''
            this.choixType=true
        },
        closeDialog(){
            this.resetProps()
            this.$emit('closeDialog',true)
            this.$store.commit('setPromotionPicked',null)
        },
        resetValue(){
            this.skill=0
            this.montantFixe=''
        },
        allowedDate(val){

            const today = new Date()
            const currentDate = new Date(val)
            return currentDate >= today
        }
    },
    watch:{
        'montantFixe':{
            handler(value){
                if(/^[0-9]+,$/.test(value)) {
                    this.montantFixe = value.substr(0,value.length-1).concat('.')
                }

            }
        },
        'pourcentage':{
            handler(value){
                if(!isNaN(this.pourcentage)) {
                    this.pourcentage = parseFloat(this.pourcentage)
                    if(parseInt(this.pourcentage)>100){
                        this.pourcentage=100
                    }

                }else{
                    this.pourcentage=0
                }

            }
        },
        '$store.getters.prestationPicked':{
            handler(value){
                //  this.resetProps()
            }
        },
        '$store.getters.counterEspace':{
            immediate: true,
            handler(){
                if(this.$store.getters.promotionPicked !== null){
                    const {pourcentage,montant,dateDebut,dateFin,heureDebut,heureFin,jours_applicable,nb_hebdo,nb_mensuel,nb_total,usage_applicability}=this.$store.getters.promotionPicked
                    this.heureDebut=heureDebut
                    this.heureFin=heureFin
                    this.dates= [dateDebut,dateFin]
                    this.pourcentage=pourcentage
                    this.montantFixe=montant
                    this.nombreUtilisationMensuel=nb_mensuel
                    this.nombreUtilisationMax=nb_total
                    this.nombreUtilisationWeek=nb_hebdo
                    this.jourApplicable=jours_applicable
                    this.usage_applicability =usage_applicability

                    if(pourcentage ===0)
                        this.choixType=false
                    else
                        this.choixType=true
                }else
                    this.resetProps()
            }
        }
    }
}
</script>

<style scoped>
.text-style{
    font-family: Lato, sans-serif;
    color: #8f8f8f;
    font-size: 15px!important;
}
</style>
