<template>
    <div>
        <section class="section-full text-left bg" style="height: 250px">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Nos clients recrutent<br> des contrôleurs </h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-md text-left" v-if="!sended">
            <v-container class="container mb-0 pb-0">
                <h3 style="font-size: 1.6em">Pour nous permettre de mieux orienter votre candidature nous vous remercions de bien vouloir compléter le formulaire ci-dessous.</h3>
                <hr>
                <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>

                    <v-row justify="center" class="my-0 py-0">
                        <v-col cols="12" sm="12"  md="12" lg="12" xl="8" class="mb-0 pb-0">
                            <v-card elevation="0">
                                <v-card-title>
                                    <v-row class="pa-0 ma-0 mt-3">
                                        <v-col class="col-12 pa-0 ma-0">
                                            <span class="ma-0 pa-0 rounded text-title-2">Vos informations personnelles</span>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <!--<v-row justify="center">
                                    <v-radio-group row class="justify-center" v-model="civility" :rules="basicRules" v-on:change="resetNames">
                                        <v-row class="justify-start" align="center">
                                            <v-col lg="5" class="ml-4">
                                                <v-radio label="Madame" value="Madame"></v-radio>
                                            </v-col>

                                            <v-col lg="5" class="ml-4">
                                                <v-radio label="Monsieur" value="Monsieur"></v-radio>
                                            </v-col>
                                        </v-row>
                                    </v-radio-group>
                                </v-row>-->
                                <v-card-text>
                                    <v-row justify="center">
                                        <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                            <v-text-field v-model="lastName" :rules="nameRules" label="Nom" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                            <v-text-field v-model="firstName" :rules="prenomRules" label="Prénom" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                            <v-text-field v-model="email" :rules="emailRules" label="Email" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                            <v-text-field v-model="phone" :rules="phoneRules" label="Téléphone mobile" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12"  md="12" lg="12" xl="8" class="mb-0 pb-0">
                            <v-card elevation="0">
                                <v-card-title >
                                    <v-row class="pa-0 ma-0 mt-3">
                                        <v-col class="col-12 pa-0 ma-0">
                                            <span class="ma-0 pa-0 rounded text-title-2">Votre adresse</span>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-row justify="center" class="py-0 my-0">
                                    <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                        <v-text-field v-model="numVoie" label="Numéro voie"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                        <v-text-field v-model="libelleVoie" :rules="libelleVoieRules" label="Libellé voie" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="12" class="px-6 py-0 my-0">
                                        <v-text-field
                                                label="Complément d'adresse"
                                                v-model="complementAdresse"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                        <v-text-field v-model="codepostal" :rules="codePostalRules" label="Code postal" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="6" class="px-6 py-0 my-0">
                                        <v-text-field v-model="ville" :rules="villeRules" label="Ville" required></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" sm="12"  md="12" lg="12" xl="8">
                            <v-card elevation="0">
                                <v-card-title>
                                    <v-row class="pa-0 ma-0">
                                        <v-col class="col-12 pa-0 ma-0">
                                            <span class="ma-0 pa-0 rounded text-title-2">Questionnaire</span>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-row justify="center" class="py-0 my-0">
                                    <v-col cols="12" sm="12" md="12" lg="6" xl="5" class="px-6 py-0 my-0">
                                        <v-select :items="answers" label="Êtes-vous déjà contrôleur technique ?" v-model="beenCT" :rules="basicRules" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="6" xl="5" class="px-6 py-0 my-0">
                                        <v-select :items="answers" label="Possédez-vous un diplôme dans la réparation ?" v-model="diplome" :rules="basicRules" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="6" xl="5" class="px-6 py-0 my-0">
                                        <v-select :items="answers" label="Avez-vous une expérience professionnelle dans la réparation ?" v-model="experienceCT" :rules="basicRules" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="6" xl="5" class="px-6 py-0 my-0">
                                        <v-select :items="answers" label="Possédez-vous la formation initiale de contrôleur ?" v-model="formationCT" :rules="basicRules" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="10" class="px-6 py-0 my-0">
                                        <v-text-field v-model="departementJob" :rules="basicRules" label="Dans quel département recherchez-vous un poste ?" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="start" class="pt-5 my-0">
                                    <v-col cols="12" class="px-6 py-0 my-0">
                                        <v-textarea outlined counter label="Vos questions ou commentaires" :rules="basicRules" v-model="comments"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-row justify="center">
                                <v-spacer></v-spacer>
                                <b-button style="color:white" @click="validate" :disabled="!valid" class="btn btn-sushi btn-sm rounded-pill">Envoyer</b-button>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-form>
                <!--<v-row class="justify-center">
                                <v-col class="col-8">
                                    <v-row justify="center">
                                        &lt;!&ndash;                                  Selection genre/société&ndash;&gt;
                                        <v-radio-group row class="justify-center" v-model="civility" :rules="basicRules" v-on:change="resetNames">
                                            <v-row class="justify-start" align="center">

                                                <v-col lg="3" sm="8" md="4" class="ml-4">
                                                    <v-radio label="Madame" value="Madame"></v-radio>
                                                </v-col>

                                                <v-col lg="3" sm="8" md="4" class="ml-4">
                                                    <v-radio label="Monsieur" value="Monsieur"></v-radio>
                                                </v-col>

                                                <v-col lg="4" sm="8" md="6" class="mx-0 px-0 ml-7" >
                                                    <v-radio class="" label="Autre : société, association..." value="Autre"></v-radio>
                                                </v-col>

                                            </v-row>
                                        </v-radio-group>
                                    </v-row>
                                </v-col>
                            </v-row>-->
            </v-container>
        </section>
        <section v-if="sended" class="my-5 py-5">
            <v-row justify="center" class="my-5 py-5">
                <h3 style="font-size: 1.6em">Votre candidature a bien été prise en compte.</h3>
            </v-row>
        </section>
    </div>
</template>

<script>
    export default {
        name: "FormRecrut",
        data(){
            return {
                complementAdresse:"",
                comments:'',
                formationCT:'',
                experienceCT:'',
                diplome:'',
                beenCT:'',
                departementJob:'',
                previousExperience:'',
                previousWork:'',
                ville:'',
                codepostal:'',
                libelleVoie:'',
                numVoie:'',
                phone:'',
                valid:null,
                civility:'',
                lastName:'',
                firstName:'',
                email:'',
                sended:false,
                villeRules:[
                    v => !!v || 'Saisir votre ville',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || / [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || 'Ville non valide'

                ],
                numVoieRules:[
                    v => !!v || 'Saisie le numéro de rue,avenue...',
                    v => /^[0-9]{1,4}[ ||A-Za-z ]*$/.test(v) || /^[0-9]{1,4} [A-Za-z]+$/.test(v) || 'Numéro voie non valide'
                ],
                answers:[
                    "Oui",
                    "Non"
                ],
                nameRules: [
                    v => !!v || 'Saisir votre nom',
                    v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

                ],
                basicRules: [v => !!v || 'Champs vide'],
                prenomRules:[
                    v => !!v || 'Saisir votre prénom',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || 'Prénom non valide'

                ],
                emailRules: [
                    v => !!v || 'Un E-mail est nécessaire',
                    v => /.+@.+\..+/.test(v) || 'E-mail non valide',
                ],
                phoneRules: [
                    v => !!v || 'Un téléphone est nécessaire',
                    v => /^0[67]\d{8}$/.test(v) && v.length <=10|| 'Mobile non valide',
                ],
                libelleVoieRules:[
                    v => !!v || 'Saisir votre avenue,rue,boulevard...',
                    v => /^^[(A-ZÉÈÊa-zéèê) || \s]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+-[A-ZÉÈÊÔ\']{1}[a-zéèêô]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+-[A-ZÉÈÊÔ\']{1}[a-zéèêô]+-[A-ZÉÈÊÔ]{1}[a-zéèê]+$/.test(v)
                        || / [A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+$/.test(v)
                        || /-[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+ [A-ZÉÈÊÔ\']{1}[a-zéèêô\']+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+$/.test(v)
                        || 'Libellé voie non valide'
                ],
                codePostalRules:[
                    v => !!v || 'Saisir votre Code Postal',
                    v => /^[0-9]{5}$/.test(v) && v.length <=5 || 'Code Postal non valide'

                ],
            }
        },
        mounted(){
            this.$vuetify.goTo(0)
        },
        methods:{
            validate(){
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                   this.sended = true
                }
            }
        },
        watch:{
            'firstName':{
                handler(value){
                    if(/^[a-zéèê]$/.test(value)){
                        this.firstName=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ][a-zéèê]+-[A-ZÉÈÊa-zéèê]$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]+$)|((?<=\1-[A-Z]{1})[A-Za-z]+$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }
                }
            },
            'lastName':{
                handler(value){
                    this.lastName = value.toString().toUpperCase()
                }
            },
            'libelleVoie':{
                handler(value){
                    if(/^[A-ZÉÈÊa-zéèê]{1}$/.test(value)) {
                        const char = value.substr(value.length - 1).toUpperCase()
                        this.libelleVoie = value.substr(0, value.length - 1).concat(char)
                    }
                    if(/^[(A-Za-zÉÈÊéèê)||\s]+[A-Z]$/.test(value)){
                        const char = value.substr(value.length - 1).toLowerCase()
                        this.libelleVoie = value.substr(0, value.length - 1).concat(char)
                    }
                }
            },
            'ville':{
                handler(value){
                    if(/^[a-zéèê]{1}$/.test(value)){
                        this.ville=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)
                        || / [A-ZÉÈÊa-zéèê]{1}$/.test(value)
                        || /-[A-ZÉÈÊa-zéèê]{1}$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.ville = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.ville = value.substr(0,value.length-1).concat(char)
                    }

                }
            },
        }
    }
</script>

<style scoped>
    .bg {
        background-image: url("/images/bandeaux/form_recruit.jpg");
    }
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 15px!important;
    }
    .text-title-1{
        font-family: Lato, sans-serif;
        font-size: 22px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-title-2{
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
        color: #8f8f8f;
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
    .v-dialog{
        margin-top: 120px;
    }
</style>
