<template>
  <div id="app">
  <section class="section-full text-left bg">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <h1></h1>
          <p></p>
          <ol class="breadcrumb">
            <li class="active"></li>
          </ol>
        </div>
      </div>
    </div>
  </section>

    <v-container>
        <v-col class="col-12 px-0 py-4">
          <v-row justify="center">
            <v-col sm="8" md="8" lg="8" xl="8" cols="12">
              <v-row>
                <p class="h2">
                  Mon Budget Contrôle Technique
                </p>
              </v-row>
              <hr/>
                <v-row class="mb-0 pb-0 mt-3">
                        <p class="text-title mb-0 pb-0 mt-3">
                            VOTRE ALLIER sécurité... Votre contrôleur Technique
                        </p>

                </v-row>
                <v-row class="mt-0 pt-0">
                        <p class="text-style text-justify">
                            Saviez-vous que le Contrôleur Technique est le seul professionnel de l'Automobile qui a une <span style="text-decoration: underline">obligation légale</span> de maintien de ses qualifications chaque année ?<br>
                            En lui confiant la réalisation de vos contrôles, vous faites appel à un spécialiste à la pointe et indépendant.
                            Le contrôle technique règlementaire vous connaissez mais connaissez-vous le controle volontaire ?<br>
                            Un contrôle volontaire permet de vérifier les points de contrôle, intégralement ou partiellement, à vous de choisir (pneumatiques, freinage uniquement, freinage et signalisation, pollution...). Il est dissocié du cycle des contrôles obgligatoires et ne donne pas lieu à une contre-visite.
                            Il ne peut pas être transformé en contrôle technique obligatoire et ne modifie, en aucun cas, la date de réalisation du contrôle technique suivant.
                        </p>
                </v-row>
                <v-row>
                    <p class="text-title">Un contrôle volontaire, c'est :</p>
                </v-row>
                <v-row>
                    <ul class="text-style-list text-justify">
                        <li><v-icon size="22">{{ icons.mdiAccountHardHat }}</v-icon>&nbsp;&nbsp;&nbsp;le diagnostic d'un spécialiste sur l'état de votre véhicule</li>
                        <li><v-icon size="22">{{ icons.mdiShieldCar }}</v-icon>&nbsp;&nbsp;&nbsp;l'assurance de rouler en toute sécurité, l'esprit tranquille</li>
                        <li><v-icon size="22">{{ icons.mdiBell }}</v-icon>&nbsp;&nbsp;&nbsp;une alerte des éventuels travaux ou entretiens à effectuer</li>
                        <li><v-icon size="22">{{ icons.mdiEye }}</v-icon>&nbsp;&nbsp;&nbsp;un pré-contrôle avant le contrôle règlementaire pour éviter la contre-visite</li>
                    </ul>
                </v-row>
                <v-row justify="center">
                         <p style="background-color: var(--btn-color);color: white;text-align: center" class="text-title pa-2 rounded text-justify">
                            ABS-GROUP vous propose <span style="color: #E8F643;">ma bourse auto</span>, la première cagnotte pour un budget contrôle de véhicules anticipé et maîtrisé.
                        </p>
                </v-row>
            </v-col>
            <!--<v-divider vertical class="px-3"></v-divider>-->
          </v-row>
            <v-row class="pa-4" justify="center">
                <v-col class="col-8">
                    <v-stepper class="mb-5" alt-labels>
                      <v-stepper-header>
                        <v-stepper-step :complete="stepper" step="1" color="red">
                            <span class="text-stepper ml-5">
                          Je crée mon compte
                                </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepper" step="2" color="red">
                             <span class="text-stepper ml-5">
                          J'active ma bourse
                             </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepper" step="3" color="red">
                             <span class="text-stepper ml-5">
                          J'abonde ma bourse
                             </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepper" step="4" color="red">
                           <span class="text-stepper ml-5">
                            J'atteinds mon plafond
                           </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepper" step="5" color="red">
                             <span class="text-stepper ml-5">
                          Je règle mon contrôle
                             </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepper" step="6" color="red">
                             <span class="text-stepper ml-5">
                          J'abonde mon compte
                             </span>
                        </v-stepper-step>
                      </v-stepper-header>
                    </v-stepper>

                    <v-row justify="center">
                        <p class="text-title" style="font-size: 1.7em; color: black;font-weight: bold">
                            Comment marche « Ma bourse auto » ?
                        </p>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="text-style text-justify">
                                Avec « <span style="font-weight: bold">Ma bourse auto </span>» vous mettez de côté, chaque mois, 5 &#8364;.
                                Cette somme cumulée vous permet de <span style="font-weight: bold">régler</span> votre <span class="ma-0 pa-0" style="font-weight: bold">contrôle règlementaire</span> mais aussi d'<span style="font-weight: bold">effectuer des contrôles volontaires</span> pour <span style="font-weight: bold">plus de sécurité</span>,
                                de <span style="font-weight: bold">respect de l'environnement</span>, un <span style="font-weight: bold">budget maîtrisé</span>, des travaux anticipés, et un <span style="font-weight: bold">rendez-vous avec les professionnels plus serein</span> en évitant les mauvaises surprises
                                lors du contrôle règlementaire.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="12" md="4" lg="4" xl="4" cols="12">

                                <v-card class="mb-4 mx-auto" max-width="344" outlined>
                                    <v-list-item>
                                        <v-list-item-content>
                                           <!-- <v-col class="col-1">-->
                                            <p class="text-title-last text-justify">
                                                Mon budget et ma sécurité maîtrisés, je n'hésite pas, j'adhère !
                                            </p>
                                            <p class="text-style text-justify">Pour seulement 5&euro; par mois, faites contrôler votre véhicule et rouler en toute sécurité.</p>
                                           <!-- </v-col>-->
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                        </v-col>
                        <v-col sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-card class="mb-4 mx-auto" max-width="344" outlined>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <p class="text-title text-justify">
                                                Comment et quand utiliser ma Bourse Auto ABS-Group ?
                                            </p>
                                            <p class="text-style text-justify">
                                                Vous avez atteint un montant égal ou supérieur au coût d'un contrôle volontaire ou règlementaire, vous pouvez le régler avec la somme cumulée dans votre Bourse securité auto. Le reste de la cagnotte est votre réserve pour vos prochains contrôles.
                                            </p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                        </v-col>
                        <v-col sm="12" md="4" lg="4" xl="4" cols="12">
                                <v-card class="mx-auto" max-width="344" outlined>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <p class="text-title">
                                                Quels sont mes avantages ?
                                            </p>
                                            <p class="text-style text-justify">
                                                Cumulée petit à petit, sans grever mon budget. Cette somme me permet d'assurer les frais du contrôles obligatoire ou volontaire de mon véhicule en toute quiétude.
                                            </p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
  </div>
</template>

<script>
    import { mdiLightbulb,mdiAccountHardHat,mdiShieldCar,mdiBellAlertOutline,mdiBoomGateAlert,mdiBell,mdiEye } from '@mdi/js';
    export default {
        name: "budgetCtComponent",
        components:{
        },
        data(){
            return{
                stepper:true,
                icons:{
                    mdiLightbulb,
                    mdiShieldCar,
                    mdiAccountHardHat,
                    mdiBellAlertOutline,
                    mdiBoomGateAlert,
                    mdiBell,
                    mdiEye
                }
            }
        },
        mounted() {
            window.scrollTo(0, 0)
            this.$store.dispatch('payment')
        }
    }
</script>

<style scoped>
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 15px!important;
    }
    .text-style-list{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 15px!important;
        line-height:2em!important;
    }
    .text-title{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.3px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-title-last{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.3px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }

    .step{
        background-color: var(--btn-color);
        color: white;
        font-family: Lato, sans-serif!important;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0.78px;
    }
    .bg {
      background-image: url("../../../public/images/budgetCT.jpeg");
    }
    .text-stepper{
        font-family: Lato, sans-serif;
        color: black;
        font-size: 16px!important;
        font-weight: bold;

    }
</style>
