<template>
    <v-container class="col-12">
        <section class="section-sm">
            <v-row  justify="center">
                <v-col cols="6" v-if="status===6" class="my-9 py-9" >
                    <v-alert
                        text
                        color="error"
                    >
                        <h3 class="text-h5">
                            Paiement refusé par votre banque
                        </h3>
                        <div>Votre paiement a été refusé. Nous vous invitons à ressayer avec un autre moyen de paiement.</div>

                        <v-divider
                            class="my-4 info"
                            style="opacity: 0.22"
                        ></v-divider>

                        <v-row
                            align="center"
                            no-gutters
                        >
                            <v-col class="grow">
                                A très bientôt
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-alert>
                </v-col>

                <v-col cols="6" v-if="status===7" >
                    <v-alert
                        text
                        color="error"
                    >
                        <h3 class="text-h5">
                            Paiement annulé
                        </h3>
                        <div>Votre paiement a été annulé. Nous vous invitons à consulter un rendez-vous qui vous conviendra mieux.</div>

                        <v-divider
                            class="my-4 info"
                            style="opacity: 0.22"
                        ></v-divider>

                        <v-row
                            align="center"
                            no-gutters
                        >
                            <v-col class="grow">
                                A très bientôt
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row class="" justify="center" v-if="infoRdv !=null && status===0" >
                <v-col cols="3" class="elevation-10 my-5 mr-2" style="background-color:var(--btn-color)">

                    <div class="pa-3">
                        <v-row justify="center">

                        <span class="text-main-title">
                            Paiement réussi
                        </span>
                        </v-row>
                        <v-row class="py-3">
                            <v-col>
                                <span class="text-style text-justify">Un email avec le détail de votre rendez-vous et de votre paiement vous a été envoyé à l'adresse suivante : {{infoRdv.emailClient}}.</span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row justify="start" class="pl-2 pt-2">
                        <span class="text-main-title">Récapitulatif rendez-vous</span>
                    </v-row>


                    <!--Info usager        -->
                    <v-row align="center" justify="start" class="pl-2 pt-2">
                        <v-col cols="4">
                            <v-icon style="color: white" size="60">
                                {{icons.user}}
                            </v-icon>
                        </v-col>
                        <v-col cols="8">
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{infoRdv.nomClient}}</span>
                            </v-row>
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{infoRdv.prenomClient}}</span>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!-- VEHICULE            -->
                    <v-row align="center" justify="start" class="pl-2 pt-2">
                        <v-col cols="4">
                            <v-icon size="60" style="color: white">
                                {{icons.car}}
                            </v-icon>
                        </v-col>
                        <v-col cols="8">
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-transform: uppercase;font-weight: bold">{{infoRdv.marqueVehicule}} </span>
                            </v-row>
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-transform: uppercase;font-weight: bold">{{infoRdv.modeleVehicule}}</span>
                            </v-row>
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-transform: uppercase;font-weight: bold">{{infoRdv.energieVehicule}}</span>
                            </v-row>
                        </v-col>
                    </v-row>


                    <!-- Lieu centre                       place-->

                    <v-row align="center" justify="start" class="pl-2 pt-2">
                        <v-col cols="4">
                            <v-icon style="color: white" size="60">
                                {{icons.city}}
                            </v-icon>
                        </v-col>
                        <v-col cols="8">
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{infoRdv.ctr_nom}}</span>
                            </v-row>
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{infoRdv.adr_rue}}</span>
                            </v-row>
                            <v-row justify="start" class="pt-2" v-if="infoRdv.adr_complement">
                                <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{infoRdv.adr_complement}}</span>
                            </v-row>
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{infoRdv.adr_code_postal}} {{infoRdv.adr_ville}}</span>
                            </v-row>
                        </v-col>
                    </v-row>


                    <!--                        date-->

                    <v-row justify="start" align="center" class="pl-2 pt-2">
                        <v-col cols="4" class="pr-0 mr-0">
                            <v-icon style="color: white" size="60">
                                {{ icons.calendar }}
                            </v-icon>
                        </v-col>
                        <v-col cols="8">
                            <v-row justify="start" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;font-weight: bold">{{ infoRdv.date_reservation }} à {{infoRdv.heure_rdv}}</span>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row justify="center" align="center" class="pl-2 pt-2">
                        <v-col cols="12" class="pr-0 mr-0">
                            <v-row justify="center" class="pt-2">
                                <span class="text-style ml-1" style="text-align: center;font-weight: bold">Code rendez-vous : {{infoRdv.code_reservation}}</span>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </section>
        <v-row class="ma-10 pa-10" v-if="spinner">
            <v-col class="ma-10 pa-10">
                <v-overlay :value="spinner">
                    <v-progress-circular :size="100" :width="10" color="#1F5578" indeterminate></v-progress-circular>
                </v-overlay>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mdiAccountBox, mdiCalendar, mdiCarSide, mdiCity,mdiCurrencyEur} from '@mdi/js';
export default {
    name: "ReturnPaymentComponent",
    data(){
        return{
            status:'',
            spinner:false,
            infoRdv:null,
            dayOfWeeks:['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            icons:{
                user:mdiAccountBox,
                car:mdiCarSide,
                city:mdiCity,
                calendar:mdiCalendar,
                euro:mdiCurrencyEur
            }
        }
    },
    methods:{

    },
    async mounted() {
        const {response_wkToken,pid} = this.$route.query
        if(response_wkToken!== undefined){
            this.spinner=true
            const response = await this.$store.dispatch("getPaymentDetailLW",{transactionId:response_wkToken})
            const {status,infoRdv}= response
            this.infoRdv = infoRdv
            this.status = status
            this.spinner=false
        }
        if(pid!== undefined){
            this.spinner=true
            const response = await this.$store.dispatch("getAlmaPaymentDetail",{paymentId:pid})
            const {status,infoRdv}= response
            this.infoRdv = infoRdv
            this.status = status
            this.spinner=false
        }
    }
}
</script>

<style scoped>
.text-style{
    font-family: Lato, sans-serif;
    color: white;
    font-size: 14px!important;
    font-weight: bold;
}
.text-main-title{
    font-family: Lato, sans-serif;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.78px;
    text-decoration-color: rgb(70,71,74);
    color: white;
    text-decoration:underline;
    text-underline-offset: 0.2em;
}
</style>
