<template>
    <v-row align="center" justify="start" class="pt-2">
        <v-col cols="12" class="pl-0">
            <v-row align="center" class="ma-0 pa-0">
                <v-col cols="8">
                    <v-row align="center" justify="start">
                        <span class="text-style-1" >Tarif initial :</span>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row class="pa-0 ma-0" justify="end">
                        <span class="text-style-2 rounded-pill pa-2">{{$store.getters.cvc ? calculePriceInitialCVC():setDecimal($store.getters.finalPrice.prixInitial)}}€</span>
                    </v-row>
                </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-0" v-if="$store.getters.finalPrice.promo !==false && !$store.getters.cvc">
                <v-col cols="8">
                    <v-row align="center" justify="start">
                        <span class="text-style-1" >Réduction :</span>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row class="pa-0 ma-0" justify="end" align="center">
                        <span class="text-style-2 rounded-pill pa-2">-{{ $store.getters.finalPrice.promo.item.pourcentage === 0 ? setDecimal($store.getters.finalPrice.promo.item.montant)+"€" : setDecimal($store.getters.finalPrice.promo.item.pourcentage)+"%" }}</span>
                        <!--<span class="text-style-2 rounded-pill pa-2">-{{parseInt(getBestTarif(centre).item.pourcentage) === 0 ? getBestTarif(centre).item.montant+ '\u20AC' :parseInt(getBestTarif(centre).item.pourcentage)+'%'}}</span>-->
                    </v-row>
                    <!--<v-row class="pa-0 ma-0" justify="end" align="center" v-else>
                        <span class="text-style-2 rounded-pill pa-2">-0%</span>
                        &lt;!&ndash;<span class="text-style-2 rounded-pill pa-2">-{{parseInt(getBestTarif(centre).item.pourcentage) === 0 ? getBestTarif(centre).item.montant+ '\u20AC' :parseInt(getBestTarif(centre).item.pourcentage)+'%'}}</span>&ndash;&gt;
                    </v-row>-->
                </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-0">
                <v-col cols="8">
                    <v-row align="end" justify="start">
                        <span class="text-style-1" >Frais de services :</span>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row class="pa-0 ma-0" justify="end">
                        <span class="text-style-2 rounded-pill pa-2">1.99€</span>
                    </v-row>
                </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-0">
                <v-col cols="8">
                    <v-row align="center" justify="start">
                        <span style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;color: white;" class="text-style-1" >Total TTC :</span>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row class="pa-0 ma-0" justify="end">
                        <span style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;" class="text-style-2 rounded-pill pa-2">{{$store.getters.cvc ? calculePriceWithoutPromo():calculatePrice()}}€</span>
                    </v-row>
                </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-0" :class="user.amount_bct/100 <30 ? 'not-bct-allow rounded' :''"  v-if="bct">
                <v-col cols="8">
                    <v-row align="end" justify="start" :class="user.amount_bct/100 <30 ? 'pa-1':''">
                        <span v-if="user.amount_bct/100 <30" class="text-style-1" >Montant disponible bourse CT : <br><span style="font-size: 13px">(Déblocable à partir de 30€)</span> </span>
                        <span v-else  class="text-style-1" >Montant disponible bourse CT :</span>

                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row class="pa-0 ma-0" justify="end">
                        <span class="text-style-2 rounded-pill pa-2">{{setDecimal(user.amount_bct/100)}}€</span>
                    </v-row>
                </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-0" v-if="bct">
                <v-col cols="8">
                    <v-row align="end" justify="start">
                        <span style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;color: white;" class="text-style-1" >Total restant à payer par carte bancaire :</span>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row class="pa-0 ma-0" justify="end">
                        <span v-if="user.amount_bct/100 < 30" style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;" class="text-style-2 rounded-pill pa-2">{{$store.getters.cvc ? calculePriceWithoutPromo() :setDecimal(calculatePrice())}}€</span>
                        <span v-else style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;" class="text-style-2 rounded-pill pa-2">{{$store.getters.cvc ? calculePriceWithoutPromo() : calculePriceWithPromo()}}€</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "detailPaymentComponent",
        props:['bct','cvc'],
        data(){
          return {
              user:''
          }
        },
        async mounted(){
          if(this.bct)
              this.user = await this.$store.dispatch("getData",localStorage.getItem('token'))
        },
        methods:{
            calculePriceInitialCVC(){
               return parseFloat(parseFloat(this.$store.getters.finalPrice.prixInitial) - (parseFloat(this.$store.getters.finalPrice.prixInitial)*0.3)).toFixed(2)
            },
            calculePriceWithoutPromo(){
              return parseFloat((parseFloat(this.$store.getters.centreSelected.bestTarif.pst_tarif)-(parseFloat(this.$store.getters.centreSelected.bestTarif.pst_tarif)*0.3))+1.99).toFixed(2)
            },
            calculePriceWithPromo(){
                return this.setDecimal(this.calculatePrice()-(this.user.amount_bct/100)) < 0 ? parseFloat(0).toFixed(2):  this.setDecimal(this.calculatePrice()-(this.user.amount_bct/100))
            },
            setDecimal(value){
                return parseFloat(value) % 1 === 0 ? parseFloat(value).toFixed(0) : parseFloat(value).toFixed(2)
            },
            getBestTarif(){
                const centre = this.$store.getters.centreSelected
                const {pst_tarif}=centre.bestTarif
                const sortBestPromotion=(array,index=0,acc={},tarif=pst_tarif,result=pst_tarif)=>{
                    const {pourcentage,montant}= array[index]
                    if(parseInt(pourcentage)===0){
                        if(parseInt(result) > parseInt(tarif)-parseInt(montant)){
                            result =  parseInt(tarif)-parseInt(montant)
                            acc=array[index]
                        }
                    }else{
                        if(parseInt(result) > parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)){
                            result =   parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)
                            acc=array[index]
                        }
                    }
                    return array.length-1  > index? sortBestPromotion(array,index+1,acc,tarif,result) : {item:acc,tarif:result}
                }
                return centre.bestTarif.promotions.length !==0 ? sortBestPromotion(centre.bestTarif.promotions): pst_tarif
            },
            calculatePrice(){
                if(this.$store.getters.promotionsSelected.length>0 && this.$store.getters.finalPrice.promo ){
                    const {prixInitial,promo} =this.$store.getters.finalPrice
                    return promo.item.pourcentage === 0 ? this.$store.getters.cvc ? parseFloat(parseFloat((prixInitial - promo.item.montant)+1.99).toFixed(2)-((parseFloat((prixInitial - promo.item.montant)+1.99).toFixed(2))*0.3)).toFixed(2) :parseFloat((prixInitial - promo.item.montant)+1.99).toFixed(2) :  this.$store.getters.cvc==true ? parseFloat(parseFloat((prixInitial - (prixInitial*promo.item.pourcentage/100))+1.99).toFixed(2) -parseFloat((prixInitial - (((prixInitial*promo.item.pourcentage/100))+1.99).toFixed(2))*0.30)).toFixed(2) :parseFloat((prixInitial - (prixInitial*promo.item.pourcentage/100))+1.99).toFixed(2)
                }else{
                    return  parseFloat(this.$store.getters.centreSelected.bestTarif.pst_tarif +1.99).toFixed(2)
                }
            },
        }
    }
</script>

<style scoped>
    .text-style-1{
        font-family: Lato, sans-serif;
        color: white;
        font-weight: bold;
        font-size: 16px!important;
        vertical-align: center;
    }
    .text-style-2{
        font-family: Lato, sans-serif;
        color: black;
        font-weight: bold;
        font-size: 16px!important;
        background-color: white;
        vertical-align: center;
    }
    .not-bct-allow{
        background-color: red;
    }
</style>
