<template>
    <!-- Page Header-->
    <v-footer class="page-foot bg-gray py-4" style="background-color: #EEF0F2">
        <v-dialog
                v-model="dialogLoginCentre"
                width="500"
        >
            <v-card class="pa-0 ma-0">
                <loginCentre @closeDialog="closeDialog"></loginCentre>
            </v-card>
        </v-dialog>
        <v-row justify="center" class="pa-0 my-4">
            <v-col class="footer-content" cols="10" lg="11" xl="10">
                <v-row justify="space-around">


                    <!--                 addresse/logo-->
                    <v-col cols="12" sm="6" md="4" lg="2" xl="2">
                        <v-row class="rd-navbar-brand">
                            <v-col cols="12">
                                <router-link :to="'/'">
                                    <img v-show="$store.getters.statusClient==true" src="/images/ABS_Group_72ppi.png">
                                    <img v-show="$store.getters.statusClient==false" src="/images/AUTOBILAN_logo.png">
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row class="mt-1" justify="start">
                            <v-col cols="12">
                                <p class="ma-0 pa-0">
                                    Arteparc – Bât F
                                    Route de la Côte d’Azur
                                    Lieu dit Le Canet de Meyreuil
                                </p>
                                <p class="text-justify ma-0 pa-0">
                                    13590 MEYREUIL
                                </p>
                                <p class="text-justify ma-0 pa-0">
                                    <a href="callto:#" v-add-class-hover="'hoverClass'" class="text-primary">05 63 73 16 97</a>
                                </p>
                                <p class="text-justify ma-0 pa-0">
                                    <a href="mailto:#" v-add-class-hover="'hoverClass'" class="text-primary">{{$store.getters.statusClient ?'contact@abs-group.fr': 'contact@autobilan-systems.com'}}</a>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="my-0">
                            <v-col class="col-12">
                                <button v-on:click="redirectContact" style="color:white;background-color: var(--btn-color)!important;" class="btn btn-sm rounded-pill" >
                                    Nous Contacter
                                </button>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!--                  content 1 2-->
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                        <!--<v-row align="start">
                            <v-col cols="12">
                                <h6 class="text-ubold my-0 py-0">Le contrôle technique</h6>
                                <ul class="list-marked well6 text-left mt-1 py-0">
                                    <li>
                                        <a v-add-class-hover="'hoverClass'" href="#">Informations</a>
                                    </li>
                                    <li>
                                        <a v-add-class-hover="'hoverClass'" href="#">Actualités</a>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>-->

                            <h6 class="text-ubold my-0 py-0">Les centres de contrôle</h6>
                            <ul class="list-marked well6 text-left mt-2 py-0">
                                <!--<li>
                                 <router-link to="/societe/1">
                                    <a v-add-class-hover="'hoverClass'" href="#">Informations</a>
                                 </router-link>
                                </li>-->
                                <li>
                                    <router-link v-add-class-hover="'hoverClass'" to="/recruit">Postuler pour un poste de contrôleur technique</router-link>
                                </li>
                            </ul>

                    </v-col>

                    <!--                 Content 3 particulier-->
                    <v-col cols="12" sm="6" md="3" lg="2"  v-if="$store.getters.statusClient">
                        <h6 class="text-ubold my-0 py-0">Particulier</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                            <li>
                                <router-link to="/">
                                    <a v-add-class-hover="'hoverClass'" href="">Trouver un centre de contrôle technique</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/budgetct">
                                    <a v-add-class-hover="'hoverClass'" href="#">Mon budget CT</a>
                                </router-link>
                            </li>
                            <!--<li>
                                <router-link v-bind:to="{name:'recruit'}">
                                    <a v-add-class-hover="'hoverClass'" href="">Les centres recrutent</a>
                                </router-link>
                            </li>-->
                            <li>
                                <router-link v-add-class-hover="'hover-menu'" to="/alertect" >
                                    <a v-add-class-hover="'hoverClass'" href="#">Mon alerte CT</a>
                                </router-link>
                            </li>
                        </ul>
                    </v-col>

                    <!--                 Content 3 Professional-->
                    <v-col cols="12" sm="6" md="3" lg="2" v-if="!$store.getters.statusClient">
                        <h6 class="text-ubold my-0 py-0">Professionnel</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                            <li>
                                <router-link v-bind:to="{name:'vehiculesLegers', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Véhicules légers</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'poidsLourds', params: { id:'articles', art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Poids lourds</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'audits', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Audit</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'formations', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Formation</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'informatique', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Informatique</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'societe', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Qui sommes-nous ?</a>
                                </router-link>

                            </li>
                        </ul>
                    </v-col>


                    <!--                 content 4-->
                    <v-col cols="12" sm="6" md="4" lg="2"  order="1" order-md="2" order-lg="1">
                        <v-row>
                            <v-col>
                                <h6 class="text-ubold my-0 py-0">Informations légales</h6>
                                <ul class="list-marked well6 text-left mt-2 py-0">
                                    <li>
                                        <router-link v-bind:to="{name:'info-legal', query: { subject:'CGU' } }">
                                            <a v-add-class-hover="'hoverClass'" href="">Conditions Générales d'Utilisation</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link v-bind:to="{name:'info-legal', query: { subject:'Politiques-des-cookies' } }">
                                            <a v-add-class-hover="'hoverClass'" href="">Utilisations des cookies</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link v-bind:to="{name:'info-legal', query: { subject:'Mentions-Legales' } }">
                                            <a v-add-class-hover="'hoverClass'" href="">Mentions légales</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link v-bind:to="{name:'info-legal', query: { subject:'RGPD' } }">
                                            <a v-add-class-hover="'hoverClass'" href="">RGPD</a>
                                        </router-link>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2" order-md="5" order-lg="5" v-if="!$store.getters.statusClient">
                        <h6 class="text-ubold my-0 py-0">Administration</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                            <li>
                                <a  v-on:click="espaceCentre" v-add-class-hover="'hoverClass'">Gestion des promotions</a>
                            </li>
                        </ul>
                    </v-col>

                    <v-col cols="3" order="3" v-if="$vuetify.breakpoint.mdOnly"></v-col>

                    <!--                 Lemonway-->
                    <v-col v-bind:class="{ 'hide-block':!$store.getters.statusClient}" cols="12" sm="6" md="4" lg="2" xl="3" class="mt-2 ml-5 ml-md-7" order="2" order-md="1" order-lg="2 ">
                        <v-row>
                            <a target="_blank" href="https://www.lemonway.com/" title="Lemon Way - &Eacute;tablissement de paiement agr&eacute;&eacute;">
                                <img style="vertical-align:middle;" src="/images/Lemon-Way-Logo-Inline-200px.png" />
                            </a>
                        </v-row>
                        <v-row class="mt-5">
                            <p class="text-lemonway text-justify mb-0">« Agent de Lemonway (établissement de paiement dont le siège social est situé au 8, rue du Sentier 75002 Paris, agrée par l'ACPR sous le numéro 16568) inscript au Registres des agents financiers (Regafi) ».<br></p>
                        </v-row>
                        <v-row>
                            <a v-add-class-hover="'hoverClass'" href="https://www.lemonway.com/conditions-generales-dutilisation/" target="_blank">CGU Lemonway</a>
                        </v-row>
                        <v-row>
                            <a v-add-class-hover="'hoverClass'" href="https://www.lemonway.com" target="_blank">Site officiel Lemonway</a>
                        </v-row>
                        <v-row><a v-add-class-hover="'hoverClass'" href="https://www.regafi.fr" target="_blank">Registres des agents financiers</a></v-row>
                        <v-row><a v-add-class-hover="'hoverClass'" href="https://www.lemonway.com/reclamation" target="_blank">Réclamation Lemonway</a></v-row>
                        <v-row class="pt-3">
                            <h6 class="text-ubold my-0 py-0">Paiement jusqu'à 4 fois sans frais</h6>
                        </v-row>
                        <v-row class="pt-1">
                            <img style="width: 54%" src="/images/alma_last.png">
                        </v-row>
                        <v-row><a v-add-class-hover="'hoverClass'" href="support@getalma.eu" target="_blank">support@getalma.eu</a></v-row>
                        <v-row><a v-add-class-hover="'hoverClass'" href="https://www.afepame.fr/" target="_blank">www.afepame.fr</a></v-row>
                    </v-col>


                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col class="col-10">
                <v-row>
                    <v-col class="col-8 ml-8">
                        <v-row justify="start">
                            <p class="pull-sm-left">©
                                <span id="copyright-year"></span> All Rights Reserved
                            </p>
                        </v-row>

                    </v-col>
                    <!--<v-col class="col-3">
                        <v-row justify="end" class="ml-4">
                            <ul class="list-inline pull-sm-right">
                                <li>
                                    <a href="#" class="fa-facebook"></a>
                                </li>
                                <li>
                                    <a href="#" class="fa-twitter"></a>
                                </li>
                                <li>
                                    <a href="#" class="fa-rss"></a>
                                </li>
                            </ul>

                        </v-row>
                    </v-col>-->
                </v-row>
            </v-col>
        </v-row>
        <!--<v-col class="col-xs-12 col-sm-6 col-lg-2 my-0 py-0">
                       <h6 class="text-ubold">Nous Contacter</h6>
                       <p class="text-gray">N'hésitez pas à nous contacter.</p>

                       &lt;!&ndash; RD Mailform&ndash;&gt;
                       <form data-form-output="form-output-global" data-form-type="subscribe" method="post" action="bat/rd-mailform.php" class="rd-mailform text-left subscribe">
                           <div class="form-group">
                               <label for="email-sub" class="form-label"></label>
                               <input id="email-sub" type="email" name="email" data-constraints="@Required @Email" placeholder="Enter e-mail" class="form-control">
                           </div>
                           <button class="btn btn-sushi btn-sm">Envoyer</button>
                       </form>
                   </v-col>-->



        <!--<v-container class="copyright ma-0 pa-0">
            <div class="container" style="background-color: var(&#45;&#45;bg-color-footer-down)">
                <p class="pull-sm-left">©
                    <span id="copyright-year"></span> All Rights Reserved |
                    <router-link v-bind:to="{name: 'CGU', params: {selected: 'CGU'}}">Conditions Générales d'Utilisations </router-link> |
                    <router-link v-bind:to="{name: 'CGU', params: {selected: 'Cookies'}}">Utilisations des Cookies </router-link> |
                    <router-link v-bind:to="{name: 'CGU', params: {selected: 'Mentions-Legales'}}">Mentions Légales </router-link> |
                  <router-link v-bind:to="{name: 'CGU', params: {selected: 'RGPD'}}">Politique RGPD </router-link>
                </p>
                <ul class="list-inline pull-sm-right">
                    <li>
                        <a href="#" class="fa-facebook"></a>
                    </li>

                    <li>
                        <a href="#" class="fa-google"></a>
                    </li>
                    <li>
                        <a href="#" class="fa-rss"></a>
                    </li>
                </ul>
            </div>
        </v-container>-->
        <!-- Rd Mailform result field-->
        <!-- <div class="rd-mailform-validate"></div>-->
    </v-footer>
</template>

<script>
    import loginCentre from '../modules/Professional/espace-centre/login-centre'
    import colorsHandler from "../libraries/colorsHandler"
    export default {
        name: "FooterComponent",
        components:{
            loginCentre
        },
        data() {
            return {
                dialogLoginCentre:false,
                statusClient: true,
                switch2: true,
                root:null
            }
        },
        mounted(){
            this.root = document.documentElement
            colorsHandler.setStatusClientColors(this.$store.getters.statusClient)
        },
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    /* el.removeEventListener('mouseenter');
                     el.removeEventListener('mouseleave')*/
                }
            }
        },
        methods: {
            closeDialog(){
                this.dialogLoginCentre= false
            },
            espaceCentre(){
                if(this.$store.getters.authenticateCentre){
                    if(this.$route.name !== 'espace-centre')
                        this.$router.push({name:'espace-centre'})
                    this.dialogLoginCentre = false
                }else
                    this.dialogLoginCentre = true
            },
            espaceCentre(){
                if(this.$store.getters.authenticateCentre){
                    if(this.$route.name !== 'espace-centre')
                        this.$router.push({name:'espace-centre'})
                    this.dialogLoginCentre = false
                }else
                    this.dialogLoginCentre = true
            },
            redirectContact(){
                this.$router.push({name:'contact'})
            },
            updateData(data) {
                this.statusClient = data
                this.$store.commit("setStatusClient", data)
            }
        },
    }
</script>

<style scoped>
    footer
    {
        width: 100%;

    }
    .address > * {
        color: whitesmoke;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li ,
    p{
        color: black!important;
    }
    a{
        color :var(--status-color);
    }
    .abs-group{
        background-color: #1F5578!important;
    }
    .auto-bilan-systems{
        background-color: #89BA51!important;
    }
    .hoverClass {
        color: #F2811D!important;
        font-weight: 700;
    }
    .text-lemonway{
        font-family: Lato, sans-serif;
        color: #8f8f8f!important;
        font-size: 13px!important;
    }
    .hide-block{
        display: none;
    }
</style>
