<template>
    <v-col class="my-15 py-15" cols="12">
        <v-row justify="center">
            <v-col cols="5">
            <v-text-field
                label="Recherche par nom de centre ou agrément"
                placeholder="Recherche"
                outlined
                v-model="searchCentre"
            ></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-pagination
                v-model="page"
                :length="lengthPagination"
                :total-visible="10"
            ></v-pagination>
        </v-row>
        <v-row justify="center" v-for="(centre,index) in dataPagination[page-1]" v-bind:key="index">
            <v-col cols="10">
                <v-card
                    class="mx-auto"
                    max-width="800"
                    v-add-class-hover="'hover-field'"
                    v-on:click="setCentreEspace(centre)"
                >
                    <v-card-text>
                        <v-row class="pt-3" justify="center">
                            <p class="text-h4 text--primary">
                                {{centre.ctr_nom}}
                            </p>
                        </v-row>
                        <v-row justify="center">
                            <p class="text-h4 text--primary">
                                {{centre.ctr_agrement}}
                            </p>

                        </v-row>

                        <!--<v-row v-if="centre.tarifs.length > 0">
                            <span style="font-size: 20px" >Prestations et tarifs : </span>
                        </v-row>
                        <v-row justify="start">
                            <v-col cols="4" v-for="tarif in centre.tarifs">
                                <v-card class="pa-3">
                                    <v-row>
                                        <span>{{tarif.typ_vst_libele}}</span>
                                    </v-row>
                                    <v-row>
                                        <span>{{tarif.typ_vst_libele_long}}</span>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>-->

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!--<v-row justify="center">
            <v-pagination
                    v-model="page"
                    :length="lengthPagination"
                    :total-visible="10"
            ></v-pagination>
        </v-row>-->
    </v-col>

</template>

<script>
import colorsHandler from "../../../libraries/colorsHandler"
export default {
    name: "espace-centre",
    data(){
        return{
            searchCentre:'',
            root:null,
            centres:'',
            pagination:'',
            totalVisible:10,
            page:1,
            dataPagination:'',
            lengthPagination:4
        }
    },
    methods:{
        async getCentreData(){
            const {data} = await this.$store.dispatch("getDataCentre",localStorage.getItem('tokenCentre'))
            if(data===false)
                this.$router.push({name:'Pro'})
        },
        generatePage (array,index=0,length,acc=[]){ return array.length >= index ? this.generatePage(array,index+length,length,[...acc,array.slice(index,index+length)]):acc},
        getLengthPagination(){
            return parseInt(this.lengthPagination)
        },
        setCentreEspace(centre){
            this.$store.commit('setCentrePicked',centre)
            this.$router.push({name:'centre'})
        }
    },
    async mounted() {
        this.root = document.documentElement
        this.$store.commit("setStatusClient",false)
        const response = await this.getCentreData()
        this.centres = this.$store.getters.centresEspace
        this.dataPagination =  this.generatePage(this.centres,0,5)
        this.lengthPagination =this.dataPagination.length
        colorsHandler.setStatusClientColors()(false,this)
    },
    directives:{
        'add-class-hover':{
            bind(el, binding, vnode) {
                const { value="" } = binding;
                el.addEventListener('mouseenter',()=> {
                    el.classList.add(value)
                });
                el.addEventListener('mouseleave',()=> {
                    el.classList.remove(value)
                });
            },
            unbind(el, binding, vnode) {
                /* el.removeEventListener('mouseenter');
                 el.removeEventListener('mouseleave')*/
            }
        }
    },
    watch:{
        'searchCentre':{
            handler(value){
                const testCentres = this.centres.filter(({ctr_nom,ctr_agrement})=>ctr_nom.toUpperCase().match("^"+value.toUpperCase())!=null || ctr_agrement.match("^"+value.toUpperCase())!=null)
                this.page = 1
                this.dataPagination =  this.generatePage(testCentres,0,5)
                this.lengthPagination =this.dataPagination.length
            }
        }
    }

}
</script>

<style scoped>
.hover-field{
    background-color: #F5F5F5!important;
}
.bg-header-color-pro{
    background-color: #89B951!important;
}
</style>
