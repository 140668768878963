<template>
    <v-row justify="center" class="mb-3">
        <v-col cols="12" sm="12" xl="10" v-for="(centre,index) in $store.getters.centresSelected" v-bind:key="centre.id_centre">
            <v-row justify="center" align="start">
                <v-col cols="10" sm="10" md="8" lg="8" xl="8" class="mt-3" >
                    <v-row class=" rounded custom-elevation" justify="center">
                        <v-col cols="12">
                            <v-row align="start" justify="center">
                                <!--                      le plus proche-->
                                <v-col v-if="plusProche" cols="7" sm="6" md="3" lg="3" xl="3" class="pa-0 ma-0 mt-2">
                                    <p v-if="index==0" class="text-center rounded-pill text-md-caption light-blue lighten-1 pa-1" style="color: white">Le plus proche</p>
                                </v-col>
                                <!--                          carte-->
                                <v-col class="ma-0 pa-0 map-style" cols="12" md="4" lg="4" xl="4" order="1" v-if="getLatitude(centre)">
                                    <GmapMap v-if="getLatitude(centre)" class="center-block map" :center="{lat:parseFloat(getLatitude(centre)), lng:parseFloat(getLongitude(centre))}" :zoom="12" map-type-id="terrain">
                                        <GmapMarker :position="google && new google.maps.LatLng(parseFloat(getLatitude(centre)), parseFloat(getLongitude(centre)))" :clickable="true" :draggable="true"/>
                                    </GmapMap>
                                </v-col>

                                <!--                          info centre-->
                                <v-col class="pl-lg-8 pt-5 pl-5" cols="10" md="4" order-md="4" lg="4" xl="4" order="2" order-lg="3">
                                    <v-row>
                                        <span style="font-weight: bold">{{ centre.ctr_nom }}</span>
                                    </v-row>
                                    <v-row>
                                        <span style="font-weight: bold" v-if="isDistance()">à {{getDistanceValue()}}</span>
                                    </v-row>

                                    <v-row>
                                        <v-col class="py-1 pa-0 ma-0">
                                            <v-icon  size="20">
                                                {{ icons.mdiMapMarker }}
                                            </v-icon>
                                            <span>
                                                {{centre.adr_ville}} {{centre.adr_code_postal}}
                                             </span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-1 ps-0 ma-0">
                                            <v-icon  size="20">
                                                {{ icons.mdiHome }}
                                            </v-icon>
                                            <span>
                                          {{centre.adr_rue}}
                                        </span>
                                        </v-col>
                                    </v-row>
                                    <!--<v-row>
                                      <v-col class="py-1 ps-0 ma-0">
                                        <v-icon  size="20">
                                          {{ icons.mdiWheelchairAccessibility }}
                                        </v-icon>
                                        <span style="color: black">
                                                            Accès handicapés
                                                          </span>
                                      </v-col>
                                    </v-row>-->
                                    <v-row v-if="isTimeDistance()">
                                        <v-col class="py-1 ps-0 ma-0" >
                                            <v-icon  size="15">
                                                {{ icons.mdiTimer }}
                                            </v-icon>
                                            <span style="color: black">
                                        À moins de {{getDurationValue()}}
                                      </span>
                                        </v-col>
                                    </v-row>
                                    <!--<v-row v-if="!isTimeDistance()">
                                      <v-col class="py-1 ps-0 ma-0" >
                                        <v-icon  size="15">
                                          {{ icons.mdiCoffee }}
                                        </v-icon>
                                        <span style="color: black">
                                          Café
                                        </span>
                                      </v-col>
                                    </v-row>-->
                                    <v-row v-if="getBestTarif(centre).tarif" class="mt-16" style="position: relative;" align-content="end">
                                        <v-col cols="1" lg="1" md="1" xs="1" class="pl-1">
                                            <v-icon class="text-md-caption" size="20" style="color: var(--btn-color)">
                                                {{ icons.mdiThumbUpOutline }}
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="11"  lg="11" md="11" xs="11">
                                            <span  class="text-justify">Vous pouvez économiser jusqu'à <span style="font-weight: bold">{{parseInt(getBestTarif(centre).item.pourcentage) === 0 ? parseFloat(getBestTarif(centre).item.montant).toFixed(2): parseFloat(parseInt(centre.bestTarif.pst_tarif)*parseInt(getBestTarif(centre).item.pourcentage)/100).toFixed(2)}}&#8364;</span> sur votre contrôle technique dans ce centre.</span>
                                        </v-col>

                                    </v-row>
                                </v-col>


                                <!--tableau tarif-->
                                <v-col cols="10" md="4" lg="4" xl="4" order="2" order-lg="4" order-md="4" order-xl="4" class="px-0 px-sm-3">
                                    <v-card elevation="1" class="pa-0 ma-0" outlined height="100%" width="100%">
                                        <v-card-title  style="background-color: #4E5758;color:white" class="justify-center text-button pa-3">
                                      <span class="line-through-tarif text-sm-body-1 text-body-2 text-md-body-2 text-lg-body-1" style="color:white;font-weight: bold">
                                        Tarif initial : {{centre.bestTarif.pst_tarif }}&#8364;
                                      </span>
                                            <!--<span v-else class="line-through-tarif text-sm-body-1 text-body-2 text-md-body-2 text-lg-body-1" style="color:white;font-weight: bold">
                                              Pas de promotion
                                           </span>-->
                                        </v-card-title>
                                        <!--<v-row class="justify-end text-caption pb-0 pt-3" justify="center" v-if="getBestTarif(centre).tarif">
                                          <v-col class="col-6 ma-0 pb-0">
                                            <v-row justify="center">
                                              <p class="line-through-tarif text-sm-body-1 text-body-2 text-md-body-2 text-lg-body-1" style="color:red;text-decoration:line-through;">
                                                Tarif initial : {{centre.bestTarif.pst_tarif }}&#8364;
                                              </p>
                                            </v-row>
                                          </v-col>
                                        </v-row>-->
                                        <v-row justify="center" v-if="getBestTarif(centre).tarif"  align="center" >
                                            <v-col class="col-3 ma-0 pb-0 py-0">
                                                <v-row justify="center" class="rounded-pill mt-5" style="background-color: #F24C27;color: white;position: relative" align="center">
                                                      <span class="text-sm-body-1 text-body-2 text-lg-body-1" style="font-weight: bold;">
                                                        -{{parseInt(getBestTarif(centre).item.pourcentage) === 0 ? getBestTarif(centre).item.montant+ '\u20AC' :parseInt(getBestTarif(centre).item.pourcentage)+'%'}}
                                                      </span>
                                                </v-row>
                                            </v-col>
                                        </v-row>
<!--                                        <v-row justify="center" v-else  align="center" >
                                            <v-col class="col-3 ma-0 pb-0 py-0">
                                                <v-row justify="center" class="rounded-pill mt-5" style="background-color: #F24C27;color: white;position: relative" align="center">
                                                      <span class="text-sm-body-1 text-body-2 text-lg-body-1" style="font-weight: bold;">
                                                        -0%
                                                      </span>
                                                </v-row>
                                            </v-col>
                                        </v-row>-->

                                        <v-row class="ma-0 pa-0 mt-6" style="color: var(--btn-color)" justify="center">
                                            <v-col class="col-4 pa-0 ma-0">
                                                <v-row justify="center" align="center" class="mt-3">
                                                      <span class="mx-0 px-0 text-body-1 text-sm-body-1 text-body-2 text-lg-body-1" style="font-size: 1.2rem!important;font-weight: bold">
                                                         {{getBestTarif(centre).tarif ? parseFloat(getBestTarif(centre).tarif).toFixed(2) : parseFloat(getBestTarif(centre)).toFixed(2)}}&#8364;
                                                      </span>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="text-caption justify-center pb-3">
                      <span class="out-fee">
                          Hors frais de service 1.99&#8364;
                      </span>
                                        </v-row>
                                    </v-card>
                                    <b-button block depressed style="color:white" class="mt-2 center-block btn btn-sushi btn-sm" v-on:click="setCentre(centre)">
                                        <v-row align="baseline">
                                            <v-col class="pa-2 ma-0">
                                    <span class="submit-centre" style="color: white;font-size: 0.9em">
                                      J'en Profite
                                      <v-icon size="20" style="color: white">
                                      {{ icons.mdiArrowRight }}
                                    </v-icon>
                                    </span>
                                            </v-col>
                                        </v-row>
                                    </b-button>
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {
        mdiArrowRightDropCircle,
        mdiCoffee,
        mdiHome,
        mdiMapMarker,
        mdiWheelchairAccessibility,
        mdiArrowRight,
        mdiThumbUpOutline,
        mdiTimer
    } from '@mdi/js';
    import {gmapApi} from 'vue2-google-maps'
    export default {
        name: "CentreComponent",
        data(){
            return{
                plusProche: false,
                centresSelected: [],
                centre: '',
                icons:{
                    mdiArrowRightDropCircle,
                    mdiCoffee,
                    mdiHome,
                    mdiMapMarker,
                    mdiWheelchairAccessibility,
                    mdiArrowRight,
                    mdiThumbUpOutline,
                    mdiTimer
                },markers: [
                    {
                        position: { lat: 10.0, lng: 10.0}
                    },
                    {
                        position: { lat: 11.0, lng: 11.0}
                    }
                ],
                dialog:false,
                dialogTimeSlot:false,
                prestation:{pst_tarif:60,reduction:20},
                timeClicked:0,

            }
        },
        methods:{
            getBestTarif(centre){
                const {pst_tarif}=centre.bestTarif

                const sortBestPromotion=(array,index=0,acc={},tarif=pst_tarif,result=pst_tarif)=>{
                    const {pourcentage,montant}= array[index]
                    if(parseInt(pourcentage)===0){
                        if(parseInt(result) > parseInt(tarif)-parseInt(montant)){
                            result =  parseInt(tarif)-parseInt(montant)
                            acc=array[index]
                        }
                    }else{
                        if(parseInt(result) > parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)){
                            result =   parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)
                            acc=array[index]
                        }
                    }
                    return array.length-1  > index? sortBestPromotion(array,index+1,acc,tarif,result) : {item:acc,tarif:result}
                }
                return centre.bestTarif.promotions.length !==0 ? sortBestPromotion(centre.bestTarif.promotions): pst_tarif
            },
            getLatitude(value){

                return value.adr_coordonees.match(/^.+(?=,)/g) === null ? parseFloat(value.adr_coordonees.match(/^.+(?=\s)/g)[0]) : parseFloat(value.adr_coordonees.match(/^.+(?=,)/g)[0])

            },
            getLongitude(value){
                return parseFloat(value.adr_coordonees.match(/(?<=,).+/g)[0])
            },
            getDialog(){
                this.dialog=false
            },
            getDialogTimeSlot(){
                this.dialogTimeSlot=true
            },
            setCentre(centre){
                this.dialog = !this.dialog
                this.$store.commit("setCentre",centre)
                this.$store.commit("setTimeCentreSelected",this.timeClicked+1)
                this.timeClicked +=1
            },
            isDistance(){
                return this.centre.distance !== undefined
            },
            isTimeDistance(){
                return this.centre.duration !== undefined
            },
            getDistanceValue(){
                if(this.isDistance()) return this.centre.distance.text;
                else return ''
            },
            getDurationValue(){
                if(this.isTimeDistance()) return this.centre.duration.text.replace('mins','minutes').replace('hour','heure')
                else return ''
            },
        },
        computed:{
            google: gmapApi
        },watch:{

        },
        mounted() {
            this.centresSelected = this.$store.getters.centresSelected
        }

    }

</script>

<style>
    @media (min-width: 768px) and (max-width: 1275px) {
        .economie{
            font-size: 0.8em!important;
        }
        .line-through-tarif{

        }
        .from-price{
            font-weight: bold;
            font-size: 0.5em;
            margin-left: 5px;
        }
        .from-price-value{
            font-weight: bold;
            font-size: 1em;
        }
        .out-fee{
            font-size: 0.8em;
        }
        .submit-centre{
            font-size: 0.8em;
        }

    }
    @media (min-width: 1275px) {
        .from-price{
            font-weight: bold;
            font-size: 1em;
            margin-left: 5px;
        }
        .from-price-value{
            font-weight: bold;
            font-size: 2em;
        }

    }
    @media (min-width: 1400px) {
        .from-price{
            font-weight: bold;
            font-size: 1em;
        }
        .from-price-value{
            font-weight: bold;
            font-size: 2em;
        }
        .out-fee{
            font-size: 1em;
        }
    }
    .map {
        width: 97%;
        height: 215px;
    }
    .map-style{
        background-color: white!important;
    }
    .custom-elevation{
        -webkit-box-shadow: 12px 14px 9px -6px #A6A6A6;
        box-shadow: 12px 14px 9px -6px #A6A6A6;
    }
</style>


