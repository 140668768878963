<template>
  <div class="page">
    <section class="section-full text-left bg" style="height: 385px">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h1>L'indépendance<br> autrement</h1>
            <p></p>
            <ol class="breadcrumb">
              <li class="active"></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <!--Section popular categories 1-->
    <v-container>
      <v-row class="text-center" justify="center">
        <v-col cols="12" lg="3" md="12" sm="12" v-for="categorie in categories.slice(0, 3)" :key="categorie.id">
          <router-link v-bind:to="{name:categorie.link, params: { id:'articles' , art: true } }">
            <button class="img-thumbnail-variant-1" style="height: 283px; width: 283px">
              <img :src="categorie.img" alt="">
              <div class="caption">
                <h4 class="text-white">{{ categorie.titre }}</h4>
              </div>
            </button>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="text-center" justify="center">
        <v-col cols="12" lg="3" md="12" sm="12" v-for="categorie in categories.slice(3)" :key="categorie.id">
          <router-link v-bind:to="{name:categorie.link, params: { id:'articles' , art: true } }">
            <button class="img-thumbnail-variant-1">
              <img :src="categorie.img" alt="" style="height: 283px; width: 283px">
              <div class="caption">
                <h4 class="text-white" v-html="categorie.titre"></h4>
              </div>
            </button>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import colorsHandler from '../../libraries/colorsHandler'
export default {
  name: "Hub",
  methods:{
    setColors(color) {
     /* this.root.style.setProperty('--options-orange', color)
      this.root.style.setProperty('--btn-color', color)*/
    },

  },
  mounted(){
    this.$vuetify.goTo(0)
    this.root = document.documentElement
    this.$store.commit('setStatusClient',false)
    colorsHandler.setStatusClientColors()(false,this)
    this.$store.commit('syncStatusClient')


  },
  data() {
    return {
      root:null,
      categories: [
        {
          id: 1,
          img: 'images/img/VL.jpeg',
          titre: "Véhicules Légers",
          link: "vehiculesLegers"
        },
        {
          id: 2,
          img: 'images/img/PL.jpeg',
          titre: "Poids Lourds",
          link: "poidsLourds"
        },
        {
          id: 3,
          img: 'images/img/audit.jpeg',
          titre: "Audit",
          link: "audits"
        },
        {
          id: 4,
          img: 'images/img/formation.png',
          titre: "Formation",
          link: "formations"
        },
        {
          id: 5,
          img: 'images/img/I-info.jpg',
          titre: "Informatique",
          link: "informatique"
        },
        {
          id: 6,
          img: 'images/img/S-quiSommesNous.jpeg',
          titre: "Qui<br>sommes-nous ?",
          link: "societe"
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg
{
  background-image: url("../../../public/images/carroussel-4.jpg");
}
#articles
{
  text-align: center;
}
</style>
