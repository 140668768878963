module.exports = {
    daysInMonth(month, year, index = 1, result = []){
        const date = new Date(year, month, index)
        return month === date.getMonth() ? this.daysInMonth(month, year, index + 1, [...result, date]) : result
    },
    getDaysInWeeks :(daysInMonth, index = 0, counter = 0, result = [], line = [])=>{
        if (daysInMonth.length > index) {
            line.push(daysInMonth[index])
            result[counter] = line
            if (daysInMonth[index].getDay() === 0) {
                counter = counter + 1
                line = []
            }
            return module.exports.getDaysInWeeks(daysInMonth, index + 1, counter, result, [...line])
        } else {
            return result
        }

    },
    getWeeksOfMonth : (month,year) =>{
        const recurse = (year, index = 1, product = [])=>{
            const currentDate = new Date(year,month,index)
            return month === currentDate.getMonth() ? recurse(year,index+1,[...product,currentDate]): product
        }
        return module.exports.getDaysInWeeks(recurse(year))
    },
    compare2Date : (date1,date2)=> date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() &&date1.getFullYear() === date2.getFullYear(),
    getIndexWeekByDate: (date)=>{
        const objectWeeks = module.exports.getWeeksOfMonth(date.getMonth(),date.getFullYear())
        const indexIterate = (index =objectWeeks.length) =>{
            const recurse = (counter=0) => objectWeeks[counter].filter((dateWeek)=> module.exports.compare2Date(dateWeek,date)).length === 0 ? recurse(counter+1):counter
            return recurse()
        }
        return indexIterate()
    },
    getWeekObject: (month, year) => {
        const date = new Date(year, month + 1, 0)
        return Math.ceil(date.getDate() / 7)
    },
    generateWeekObjectPromo: (date) => {
        const generateProperties = (numWeek) => {
            const rec = (counter=0, product = {}) => {
                Object.defineProperty(product, counter, {value: 0, writable: true,enumerable:true})
                return counter >= numWeek - 1 ? product : rec(counter + 1, product)
            }
            return rec()
        }
        return generateProperties(module.exports.getWeeksOfMonth(date.getMonth(), date.getFullYear()).length)
    },
    diffDates : (dateDebut,dateFin) => ((dateFin.getFullYear() - dateDebut.getFullYear()) * 12) - dateDebut.getMonth()+1 + dateFin.getMonth(),
    generateMonthObjectPromo: (dateDebut, dateFin) => {
        const iterateMonth = (n) => {
            const recurse = (counter = 0,currentDate = new Date(dateDebut.getFullYear(),dateDebut.getMonth(),1), product = []) => {
                const currentObject = {}
                Object.defineProperties(currentObject,
                    {
                        monthIndex: {
                            value: currentDate.getMonth(),
                            writable: true,
                            enumerable:true
                        },
                        year: {
                            value: currentDate.getFullYear(),
                            writable: true,
                            enumerable:true
                        },
                        week: {
                            value: module.exports.generateWeekObjectPromo(currentDate),
                            writable: true,
                            enumerable:true
                        }
                    }
                )
                currentDate.setMonth(currentDate.getMonth() + 1)
                return counter < n ? recurse(counter + 1,currentDate, [...product, currentObject]) : product
            }
            return recurse()
        }
        return iterateMonth(module.exports.diffDates(dateDebut,dateFin))
    },
    setConsumePromo : (date,sicp) =>{
        const objectConsumePromo = sicp.slice(0)
        const indexItem = sicp.findIndex((value) => value.monthIndex === date.getMonth())
        objectConsumePromo[indexItem].week[module.exports.getIndexWeekByDate(date)] = sicp[indexItem].week[module.exports.getIndexWeekByDate(date)] +1
        return objectConsumePromo
    },
    getConsumedPromoByMonth : (monthIndex,sicp) =>  Object.values(sicp.filter((value)=> value.monthIndex === monthIndex)[0].week).reduce((acc,value)=> acc+value ,0),
    getConsumedPromoByWeekForDate : (date,sicp)=> sicp.filter(({monthIndex})=> date.getMonth() === monthIndex)[0].week[module.exports.getIndexWeekByDate(date)],
    isPromoAvailableInWeeks : ({usage_applicability,nb_hebdo})=>{
        const iterate = (n= usage_applicability.length-1) =>{
            const recurse = (counter =0 , isDispo=false)=> n>counter &&  isDispo === false ? recurse(counter+1,Object.values(usage_applicability[counter].week).some((val)=> val < nb_hebdo)) :Object.values(usage_applicability[counter].week).some((val)=> val < nb_hebdo)
            return recurse()
        }
        return iterate()
    },
    checkIfPromoAvailableInAllMonths : ({usage_applicability,nb_mensuel})=>{
        const iterate = (n= usage_applicability.length-1) =>{
            const recurse = (counter=0,isDispo= false)=> counter < n && isDispo ===false ? recurse(counter+1, module.exports.getConsumedPromoByMonth(usage_applicability[counter].monthIndex,usage_applicability) < nb_mensuel):module.exports.getConsumedPromoByMonth(usage_applicability[counter].monthIndex,usage_applicability) < nb_mensuel
            return recurse()
        }
        return iterate()
    },
    isPromoTotalAvailable : ({usage_applicability,nb_total}) => usage_applicability.reduce((acc,{monthIndex})=> acc + module.exports.getConsumedPromoByMonth(monthIndex,usage_applicability),0) < nb_total,
    isPromoAvailable : promo =>{
        if(module.exports.isPromoTotalAvailable(promo)){
            if(module.exports.checkIfPromoAvailableInAllMonths(promo)){
                if(module.exports.isPromoAvailableInWeeks(promo)){
                    return true
                }
            }
        }
        return false
    },
    getMonthIndexAvailable:({usage_applicability,nb_mensuel})=>{
        const iterate = (n= usage_applicability.length-1) =>{
            const recurse = (counter=0,product=[])=> n > counter ? recurse(counter+1,Object.values(usage_applicability[counter].week).reduce((acc,val)=> acc+val,0) < nb_mensuel ?[...product,usage_applicability[counter].monthIndex]: product ): Object.values(usage_applicability[counter].week).reduce((acc,val)=> acc+val,0) < nb_mensuel ?[...product,usage_applicability[counter].monthIndex]: product
            return recurse()
        }
        return iterate()
    },
    getWeekPropertiesAvailable:({usage_applicability,nb_hebdo})=>{
        const iterate = (n= usage_applicability.length-1) =>{
            const recurse = (counter=0,product=[])=>{
                const result = Object.keys(usage_applicability[counter].week).reduce((acc,value)=> usage_applicability[counter].week[value] < nb_hebdo ? [...acc,value]: acc,[])
                return counter < n ? recurse(counter+1,[...product,{weeks:result.length>0? result:false,year:usage_applicability[counter].year,monthIndex:usage_applicability[counter].monthIndex}]): [...product,{weeks:result,year:usage_applicability[counter].year,monthIndex:usage_applicability[counter].monthIndex}]
            }
            return recurse()
        }
        return iterate()
    },
    isWeekIndexAvailable:(indexOfWeek,indexOfMonth,{nb_hebdo,usage_applicability})=>{
        const month = usage_applicability.filter(({monthIndex})=>monthIndex===indexOfMonth)
        return month.length > 0 ? month[0].week[indexOfWeek] < nb_hebdo:false
    },
    isDateAvailable : (date, promo) => module.exports.isWeekIndexAvailable(module.exports.getIndexWeekByDate(date),date.getMonth(),promo),
}