<template>
    <div class="pa-0 ma-0">
                <v-col cols="12"class="rounded-lg">
                    <v-row>
                        <v-toolbar class="" color="var(--btn-color)" dark>
                            <v-row justify="center" align="center">
                                <span style="font-size: 1.2rem">Récupération du mot de passe</span>
                            </v-row>
                        </v-toolbar>
                    </v-row>
                    <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>
                        <v-row justify="center" class="ml-4">
                            <v-col cols="9">
                                <v-row justify="center" class="mt-3">
                                    <v-alert
                                            dense
                                            text
                                            type="error"
                                            v-if="result.email==false"
                                    >
                                        Cet email n'existe pas
                                    </v-alert>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="promptMail==true">
                            <v-alert
                                    dense
                                    text
                                    type="success"

                            >
                                    Un email pour réinitialiser votre mot de passe vous a été envoyé
                            </v-alert>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="9" class="mt-5 mb-0 pb-0">
                                <v-text-field
                                        :rules="emailRules"
                                        :prepend-icon="icons.mdiAccountBox"
                                        v-model="email"
                                        filled
                                        clear-icon="mdi-close-circle"
                                        clearable
                                        label="Saisir votre email"
                                        type="text"
                                        required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center" >
                            <v-col cols="3">
                                <b-button :disabled="!valid"  @click="validate" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Envoyer</b-button>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </div>
</template>

<script>
    import { mdiLock,mdiAccountBox } from '@mdi/js';
    export default {
        name: "DialogEmail",
        data(){
            return{
                promptMail:false,
                dialogEmail:true,
                email:'',
                icons:{mdiAccountBox,mdiLock},
                emailRules: [
                    v => !!v || 'Un E-mail est nécessaire',
                    v => /.+@.+\..+/.test(v) || 'E-mail non valide',
                ],
                valid:false,
                result:''

            }
        },
        methods:{
            async validate(){
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                    const result = await this.$store.dispatch("recoverPWD",{email:this.email})
                    this.result= result.data
                    const {email} = result.data
                    if(email){
                        this.promptMail = true
                        this.closeModal()
                    }
                }
            },
            closeModal(){
                setTimeout(()=>{
                    this.email = ''
                    this.promptMail=false
                    this.$emit('closeDialog',true)
                },3000)
            },
        }
    }
</script>

<style scoped>

</style>
