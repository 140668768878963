<template>
    <div>
        <section class="section-full text-left bg">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Formation</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                                <router-link v-bind:to="{name:'hub'}">
                                    <button>Accueil</button>
                                </router-link>
                            </li>
                            <li v-show="idSelected == null" class="active">Services</li>
                            <li v-show="idSelected !== null">
                                <button v-on:click="redirectRoot">Services</button>
                            </li>
                            <li v-if="idSelected !== null" class="active" v-html="articleSelected.titreArticle != undefined ? articleSelected.titreArticle : articleSelected.titre"></li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!--Section popular categories 1-->
        <v-container class="mt-5 mb-16">
            <!--<v-row justify="center"  v-if="idSelected== null">
                <v-col cols="6" md="3" >
                    <v-img src="/images/LogoQualiop.jpg" ></v-img>
                </v-col>
            </v-row>-->
            <v-row class="text-center" v-if="idSelected== null" justify="center">
                <v-col align-self="center" cols="12" sm="12" md="12" lg="3" class="pt-1" v-for="article in articles" :key="article.id">
                    <img :src="article.img" alt="" v-if="article.id==undefined">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)" v-if="article.id!=undefined">
                        <img :src="article.img" alt="">
                        <div style="align-self: center" class="caption" v-if="article.id!=undefined">
                            <h4 class="text-white" v-html="article.titre"></h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <!--<v-row class="text-center" v-if="idSelected== null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" class="pt-0" v-for="article in articles.slice(3)" :key="article.id">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>-->
            <v-row v-if="idSelected!==null">
                <ArtComponent :theme="articleSelected"></ArtComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import ArtComponent from "../../components/ArtComponent.vue";

    export default {
        name: "Formations",
        components: {
            ArtComponent
        },
        props: ['art', 'id','nameUrl'],
        mounted(){
            this.$vuetify.goTo(0)
            this.root = document.documentElement
            this.$store.commit('setStatusClient',false)
            this.root.style.setProperty('--btn-color', '#89B951')
            this.root.style.setProperty('--options-orange', '#89B951')
            if(this.nameUrl){
                this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==this.nameUrl)[0]
                this.idSelected = this.articleSelected.id
            }

        },
        methods:{
            redirectRoot(){
                this.resetArticle()
                this.$router.push(`/formations`)
            },
            setArticles(currentNameUrl){
                this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==currentNameUrl)[0]
                this.idSelected = this.articleSelected.id
            },
            setArt(val){
                this.articleSelected = val
                this.idSelected = val.id
                this.$router.push(`/formations/${val.nameUrl}`)
            },
            resetArticle(){
                this.idSelected= null
            }
        },
        watch:{
            'id':{
                handler(){
                    if(this.id===null){
                        this.resetArticle()
                    }
                }
            },
            'nameUrl':{
                handler(){
                    if(this.nameUrl){
                        this.setArticles(this.nameUrl)
                    }
                }
            }
        },
        data() {
            return {
                root:null,
                articleSelected :'',
                idSelected:null,
                articles: [
                    {
                        id: 1,
                        img: '../images/img/F-mQualif.png',
                        nameUrl:'maintien-qualification-controleurs',
                        texte:
                            "Les formations de maintien de la qualification des contrôleurs, sont organisées sur l'ensemble du territoire national. Conformément aux arrêtés modifiés du 18/06/1991 et du 27/07/2004, nos programmes de formations sont approuvés par le Ministère chargé des transports.",
                        titre: "Maintien de qualification contrôleurs",
                        textHighLight:"",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION VL",
                                buttonDesc: "Durée : 20h00",
                                buttonLink: "https://espace-client.autobilan-systems.com/formations/listes_formations/vl/2"
                            },
                            {
                                buttonText: "INSCRIPTION PL",
                                buttonDesc: ["Durée : 24 heures pour le Transport de Marchandises,"," 7 heures pour le Transport de Marchandises Dangereuses et","7 heures pour le Transport en Commun de Personnes"],
                                buttonLink: "https://espace-client.autobilan-systems.com/formations/listes_formations/pl/2"
                            }
                        ]
                    },
                    {
                        id: 2,
                        img: '../images/img/F-remiseNiveau.jpeg',
                        nameUrl:'remise-a-niveau-controleurs',
                        texte:
                            "Suivant la situation du contrôleur technique, nous proposons toutes les formations nécessaires pour sa remise à niveau.",
                        titre: "Remise <br>à niveau <br> contrôleurs",
                        titreArticle: "Remise à niveau contrôleurs",
                        textHighLight:"",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION VL",
                                buttonLink: "https://espace-client.autobilan-systems.com/formations/listes_formations/vl/3"
                            },
                            {
                                buttonText: "INSCRIPTION PL",
                                buttonLink: "https://espace-client.autobilan-systems.com/formations/listes_formations/pl/3"
                            },
                        ]
                    },
                    {
                        id: 3,
                        img: '../images/img/F-qualifReservoirGaz.jpg',
                        nameUrl:'qualification-controle-gpl',
                        texte:
                            "La formation initiale spécifique Contrôle technique des véhicules équipés d'un réservoir de gaz carburant est nécessaire pour obtenir la qualification et réaliser les visites techniques de ce type de véhicules. Des sessions de maintien de la qualification gaz sont également dispensées en téléformation.",
                        titre:
                            "Qualification contrôle véhicules réservoir Gaz",
                        textHighLight:"",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION VL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/vl/6"
                            },
                        ]
                    },
                    {
                        id: 4,
                        nameUrl:'habilitation-B2XL',
                        img: '../images/img/F-risques-electrique.jpg',
                        texte:
                            "AutoBilan-Systems organise chaque année plusieurs sessions de formation sur les risques d'origine électrique dans le cadre d'opérations d'ordre électrique ou non électrique permettant de se prémunir de tout accident susceptible d'être encouru lors d'opérations sur véhicules ou engins à motorisation : thermique, électrique ou hybride. <br>Cette formation est nécessaire pour que vos contrôleurs puissent réaliser les visites techniques des véhicules électriques et hybrides.",
                        titre: "Risques électriques pour habilitation B2XL",
                        textHighLight:"",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION VL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/vl/4"
                            },
                            {
                                buttonText: "INSCRIPTION PL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/pl/4"
                            },
                        ]
                    },
                    {
                        id: 5,
                        nameUrl:'exploitants-centres',
                        img: '../images/img/F-exploitantCentres.jpeg',
                        texte:
                            "Cette formation est exigée pour les exploitants de centre de contrôle technique ne disposant pas de la formation initiale de contrôleur technique.",
                        titre: "Exploitants centres",
                        textHighLight:"",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION VL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/vl/5"
                            },
                            {
                                buttonText: "INSCRIPTION PL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/pl/5"
                            },
                        ]
                    },
                    {
                        id: 6,
                        nameUrl:'formation-initiale-controleur',
                        img: '../images/img/F-formationInitialeControleur.png',
                        texte:
                            "Vous êtes passionné d’automobile ou attiré par la diversité des véhicules lourds. <br>Vous voulez un métier passionnant et valorisant au service de la sécurité des citoyens. Un métier sans routine et au contact des clients. <br>Le contrôleur technique met ses compétences au service de la prévention. Son activité fait partie d’un secteur qui propose de nombreuses possibilités d’évolution de carrière.<br><br> <span class='font-weight-bold'>AutoBilan-Systems organise chaque année des formations initiales de contrôleurs techniques VL et PL.</span>",
                        titre: "Formation initiale contrôleur",
                        textHighLight:"",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION VL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/vl/1"
                            },
                            {
                                buttonText: "INSCRIPTION PL",
                                buttonLink: "http://espace-client.autobilan-systems.com/formations/listes_formations/pl/1"
                            },
                        ]
                    },
                    {
                        id:7,
                        nameUrl:'formation-levage',
                        img: '../images/image_levage.png',
                        texte:"Les vérifications générales périodiques (VGP), des appareils de levage concernent tous les matériels utilisés pour l’élévation d’une charge ou d’un poste de travail. Elles sont obligatoires par application de l’arrêté du 01/03/2004. <br>Ces vérifications doivent être réalisées par des personnes qualifiées, appartenant ou non à l'établissement, dont la liste est tenue à la disposition de l'inspection du travail.<br><br> <span class='font-weight-bold'>Devenez contrôleur levage et réalisez les visites périodiques des systèmes courants </span>.",
                        titre: "Formation levage",
                        buttons: [
                            {
                                buttonText: "INSCRIPTION LEVAGE",
                                buttonLink: "https://espace-client.autobilan-systems.com/formations/listes_formations/lv/10"
                            },
                        ]
                    },
                    {
                        img:'/images/LogoQualiop.jpg'
                    },
                ],
            };
        },
    };
</script>

<style scoped>
    .bg {
        background-image: url("../../../public/images/bandeaux/bandeau-formation.png");
    }
</style>
