<template>
        <v-carousel height="400" cycle hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in condition ? slidesPar : slidesPro" :key="i">
            <v-sheet height="100%" v-bind:style="slide.bg" style="background-size: cover; background-position: center;">
                  <v-row>
                    <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
                    <v-col cols="10" sm="6" md="5" lg="4" xl="4" class="mt-10 mr-5">
                      <v-card class="py-2" style="background-color: rgba(0, 0, 0, 0.40); height: auto; width: auto">
                        <v-col class="col-12 my-0">
                          <p class="text-title mb-0 pa-1 pb-0 text-justify" style="color: white">{{ slide.titre }}</p>
                          <hr>
                          <p class="text-style" style=" color: white; margin-top: 12px; font-size: 16px" v-html="slide.texte"></p>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
</template>

<script>
export default {
  name: "CarrousselComponent",
  props: ['condition'],
  data () {
    return {
      slidesPar: [
        { bg: "background-image: url('images/carroussel-1.jpeg')", titre: "ABS-GROUP", texte: "La nouvelle génération de centres de contrôle" },
        { bg: "background-image: url('images/carroussel-2.jpeg')", titre: "Mon budget Contrôle Technique", texte: "Les ménages français consacrent 11% de leur budget total à l'automobile,<br> soit 3 807&#8364;/an.<br> MA BOURSE CT, la première cagnotte de gestion du budget « contrôle » des véhicules, conçue par ABS-GROUP." },
        { bg: "background-image: url('images/carroussel-3.jpeg')", titre: "Le contrôle technique", texte: "Pour la sécurité de ma famille, pour celle des autres automobilistes, pour l'environnement... J'agis !" }
      ],
      slidesPro: [
        { bg: "background-image: url('images/carroussel-4.png')", titre: "Pro", texte: "L'indépendance autrement" },
        { bg: "background-image: url('images/carroussel-4.png')", titre: "Mon budget Contrôle Technique", texte: "Les méngages français consacrent 11% de leur budget total à l'automobile, soit 3&nbsp;807&#8364;/an.<br> " },
        { bg: "background-image: url('images/carroussel-4.png')", titre: "Le contrôle technique volontaire", texte: "Pour la sécurité de ma famille, pour celle des autres automobilistes, pour l'environnement... J'agis !" }
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
    .text-title{
        font-family: Lato, sans-serif;
        font-size: 29px;
        font-weight: 900;
        letter-spacing: 0.02px;
        text-decoration-color: rgb(70,71,74);
    }
</style>
