<template>
    <section class="section-sm section-bottom-0">
        <div class="container position-margin-top undefined" style="text-align: center; max-width: 1200px;">
            <div class="search-form-wrap bg-white container-shadow-custom">
                <h3>Trouver votre centre de contrôle technique</h3>
                <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>
                    <v-row justify="center" align="start">


                        <!--                        geoloc result-->
                        <v-col cols="12" sm="6" md="3" lg="3" xl="3" v-if="geolocation">
                            <v-row justify="center" class="pa-0">
                                <v-subheader class="pl-0 pa-0 ma-0">
                                    <span style="font-weight: bold">À moins de {{distanceKm}} km de votre position</span>
                                </v-subheader>
                            </v-row>
                            <v-row justify="center" class="mt-2">
                                <v-slider
                                        step="10"
                                        max="50"
                                        min="30"
                                        v-model="distanceKm"
                                ></v-slider>
                            </v-row>
                        </v-col>


                        <!--                        Selecteur zone-->
                        <v-col v-if="!geolocation" cols="12" sm="6" md="3" lg="3" xl="3">
                            <v-autocomplete no-data-text="Aucun centre dans cette région" :filter="customFilter" :prepend-icon="city" :rules="basicRules" v-model="zone" :items="$store.getters.communes" item-text="custom" placeholder="Où, Ville , Code Postal" required></v-autocomplete>
                        </v-col>


                        <!--                        Selecteur Mois-->
                        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedDateFormatted" label="Choisir un mois" prepend-icon="mdi-calendar" hint="MM/YYYY format" :rules="basicRules" readonly locale="fr" v-bind="attrs" v-on="on" required></v-text-field>
                                </template>
                                <v-date-picker v-on:change="dateChanged" v-model="searchObject.date" :active-picker.sync="activePicker" :close-on-content-click="true" hint="MM/YYYY format" :allowed-dates="allowedMonths" locale="fr" type="month" required></v-date-picker>
                            </v-menu>
                        </v-col>


                        <!--                      Spinner-->
                        <v-overlay :value="spinner">
                            <v-progress-circular :size="100" :width="10" color="#1F5578" indeterminate></v-progress-circular>
                        </v-overlay>


                        <!--                      Type Vehicule-->
                        <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                            <v-select id="vehiculeElement" :prepend-icon="iconsVehicules[0]" :items="typesVehicules" v-model="choiceTypeVehicule" label="Type de véhicule" :rules="basicRules" v-on:change="changeTypeVehicule" required>
                                <template v-slot:activator="{ on, attrs,item }">
                                    <v-row class="justify-start" align="center">
                                        <v-col class="col-2 mt-1">
                                            <v-icon>{{iconsVehicules[typesVehicules.indexOf(data.item)]}}</v-icon>
                                        </v-col>
                                        <v-col class="col-8">
                                            <span class="cb-item" style="font-weight: bold">{{data.item}}</span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-select>
                        </v-col>


                        <!--                      Type Essence-->
                        <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                            <v-select id="energieElement":prepend-icon="iconEnergies[0]" v-model="searchObject.moteur" :items="energie" label="Energie" :rules="basicRules" required>
                                <template slot="item" slot-scope="data">
                                    <v-row class="justify-start" align="center">
                                        <v-col class="col-2 mt-1">
                                            <v-icon>{{iconEnergies[energie.indexOf(data.item)]}}</v-icon>
                                        </v-col>
                                        <v-col class="col-8">
                                            <span class="cb-item" style="font-weight: bold">{{data.item}}</span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>


                    <!--                  button-->
                    <v-row justify="center">
                        <b-button @click.prevent="preventDefault" :disabled="!valid" @click="validate" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Rechercher</b-button>
                        <b-button @click.prevent="preventDefault" v-if="geolocation"  v-on:click="desactivateGeo" style="color:white" class="btn btn-sushi btn-sm ml-2 rounded-pill">Désactiver Géolocalisation </b-button>
                        <b-button @click.prevent="preventDefault" v-if="!geolocation" v-on:click="getGeo" style="color:white" class="btn btn-sushi btn-sm ml-2 rounded-pill">Me Géolocaliser </b-button>
                    </v-row>
                </v-form>
            </div>
        </div>
        <v-row justify="center" v-if="geoPromptMessage.length>0" class="pa-4">
            <v-alert type="error">
                {{geoPromptMessage}}
            </v-alert>
        </v-row>
    </section>
</template>

<script>
    import {
        mdiCarSide,
        mdiTruck,
        mdiCarLiftedPickup,
        mdiRvTruck,
        mdiCarSports,
        mdiPowerPlug,
        mdiGasStation,
        mdiCity
    } from '@mdi/js'
    export default {
        name: "FormSearchRDV",
        data(){
            return {
                communes:null,
                isLocalStorageEmpty:true,
                city: mdiCity,
                geoPromptMessage:'',
                geoPrompt: false,
                distanceKm:0,
                minDistanceKm:30,
                typeVehicule : {'Véhicule léger':'VL','Poids lourd':'PL'},
                geolocation:false,
                hoverButton:false,
                typesVehicules: [
                    `Voiture`,
                    'Utilitaire',
                    '4x4',
                    'Camping-Car',
                    'Collection'
                ],
                spinner:false,
                valid:false,
                energie : ['Essence','Diesel','GPL','Hybride','Electrique'],
                activePicker: null,
                date: null,
                menu: false,
                zone:"",
                choiceTypeVehicule:"",
                filteredCentres : [],
                searchObject:{
                    codePostal:"",
                    ville:"",
                    date:"",
                    typeVehiculeChoice: "",
                    moteur:""
                },
                basicRules: [v => !!v || 'Champs vide'],
                months:['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre'],
                iconsVehicules: [mdiCarSide,mdiTruck,mdiCarLiftedPickup,mdiRvTruck,mdiCarSports],
                iconEnergies:[mdiGasStation,mdiGasStation,mdiGasStation,mdiPowerPlug,mdiPowerPlug]
            }
        },
        async mounted(){
            this.communes = this.$store.getters.communes
            this.$store.commit("setCurrentSearch",this.searchObject)
            /*const searchItem = JSON.parse(localStorage.getItem('searchItem'))
            if(searchItem !== null){
                if(Object.entries(searchItem).length>0){
                    this.isLocalStorageEmpty=false
                    const {date,moteur,typeVehiculeChoice,ville,zone} = searchItem
                    this.zone=zone
                    this.searchObject.date= date
                    this.choiceTypeVehicule = typeVehiculeChoice
                    this.searchObject.typeVehiculeChoice=typeVehiculeChoice
                    this.searchObject.ville=ville
                    this.searchObject.moteur = moteur
                }
            }*/
        },
        created() {
            this.boostrap()
        },
        methods: {
            customFilter(item, queryText, itemText){
               if(queryText){
                const{cp,ville} = this.destructureZone(itemText)
                return cp.match("^"+queryText)!=null || ville.match("^"+queryText.toUpperCase())!=null
               }
            },
            dateChanged(){
                document.getElementById('vehiculeElement').click()
            },
            preventDefault(e){
                e.preventDefault()
            },
            desactivateGeo(){
                this.geolocation=false
            },
            getLatitude(value){
                if(value.adr_coordonees.match(/^.+(?=,)/g) == null)
                    return false
                return parseFloat(value.adr_coordonees.match(/^.+(?=,)/g)[0])
            },
            getLongitude(value){
                if(value.adr_coordonees.match(/(?<=,).+/g) == null)
                    return false
                return parseFloat(value.adr_coordonees.match(/(?<=,).+/g)[0])
            },
            async getGeo(){

                const error = ()=>{
                    this.geoPromptMessage = 'Nous ne pouvons pas récupérer votre position'
                }

                const success =  async (position)=>{
                    this.spinner = true
                    const centresGeo = await this.$store.dispatch('reverseGeoCoding',position)
                    this.$store.commit('setAllCentresWithDistances',centresGeo)
                    this.spinner = false
                    this.geolocation = true
                    if(this.isLocalStorageEmpty && Object.entries(JSON.parse(this.isLocalStorageEmpty)).length===0)
                        this.menu=true
                }
                const browserIncompatible = () =>{
                    this.geoPromptMessage = 'La géolocalisation ne fonctionne pas avec votre navigateur'
                }

                !navigator.geolocation ? browserIncompatible() : navigator.geolocation.getCurrentPosition(success, error)



            },
            getDepartementFromCP(cp){
                return cp.match(/^\d{2}/)[0]
            },
            setCentresByDepartement(zone){
                const compareCP = (a,b) =>{
                    if(parseInt(b.adr_code_postal) === parseInt(zone)) {
                        return 1;
                    }else{
                        if(parseInt(a.adr_code_postal) < parseInt(b.adr_code_postal)){
                            return -1
                        }
                        if(parseInt(a.adr_code_postal) > parseInt(b.adr_code_postal)){
                            return 1
                        }
                        return 0
                    }
                }
                const departement = this.getDepartementFromCP(zone)
                const filteredCentres = this.$store.getters.centres.filter((data)=> {
                    const reg = new RegExp(departement+"\\d{3}");
                    return reg.test(data.adr_code_postal.toString())
                })
                const sortCentres = filteredCentres.sort(compareCP)
                this.filteredCentres = sortCentres
            },
            async boostrap(){
                await this.$store.dispatch('getCentresZones')

            },
            allowedMonths(val){
                const today = new Date()
                const maxDate = new Date()
                const currentDate = new Date()
                currentDate.setFullYear(parseInt(val.split('-')[0]),parseInt(val.split('-')[1]),today.getDate())
                maxDate.setMonth(today.getMonth()+6)
                return currentDate <= maxDate && currentDate > today
            },
            filterCommune(item, queryText, itemText){
                const libelle = item.libelle.toLowerCase()
                const codepostal = item.codepostal.toString()
                const searchText = queryText.toLowerCase()

                return libelle.indexOf(searchText) > -1 && libelle.startsWith(searchText) ||
                    codepostal.indexOf(searchText) > -1 && codepostal.startsWith(searchText)
            },
            getCPfromZone(value){
                return value.match(/^\d+\s{0}?/g)[0]
            },
            destructureZone(value){
              return {cp: value.match(/^\d+\s{0}?/g)[0],ville:value.match(/[^(^\d+\s)].+$/g)[0]}
            },
            getVilleFromZone(value){
                return value.match(/[^(^\d+\s)].+$/g)[0]
            },
            async validate () {
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                    if(!this.geolocation){
                    const centresFormated = this.filteredCentres.map((data)=> ({id_centre:data.id_centre,id_centre_global:data.id_centre_global}))
                    await this.$store.dispatch('getCentresFiltered',{centres:centresFormated,searchObject:this.searchObject})
                    }else{
                        this.$store.commit("setCentresSelected",this.filteredCentres)
                    }
                    //  localStorage.setItem('searchItem',JSON.stringify({...this.searchObject,zone:this.zone}))

                }
            },
            changeTypeVehicule(val){
                this.searchObject.typeVehiculeChoice = val
            },
            getMonth: value => parseInt(value.match(/(?<=-)\d+/)[0]),
            getYear: value => parseInt(value.match(/\d+(?=-)/)[0]),
            getDistanceFromText: value => parseFloat(value.match(/^[0-9.]+/)[0]),
            setFormatDate (value){
                if(!value) return null
                return `${this.months[parseInt(this.getMonth(value))-1]} ${this.getYear(value)}`
            }

        },
        computed:{
            computedDateFormatted(){
                return this.setFormatDate(this.searchObject.date)
            }
        },
        watch:{
            'geoPromptMessage':{
                handler(value){
                    setTimeout(()=>{
                        this.geoPromptMessage = ''
                    },3000)
                }
            },
            'zone':{
                handler(value){
                    this.setCentresByDepartement(this.getCPfromZone(value))
                    this.searchObject.codePostal = this.getCPfromZone(value)
                    this.searchObject.ville = this.getVilleFromZone(value)
                    this.$store.commit("setCurrentSearch",this.searchObject)
                    if(this.isLocalStorageEmpty && Object.entries(JSON.parse(this.isLocalStorageEmpty)).length===0)
                        this.menu=true
                }
            },
            /*'searchObject.date':{
                deep:true,
                handler(value){

                    if(this.isLocalStorageEmpty && Object.entries(JSON.parse(this.isLocalStorageEmpty)).length===0)
                        document.getElementById('vehiculeElement').click()

                }
            },*/
            'choiceTypeVehicule':{
                handler(value){
                    this.$store.commit("setCurrentSearch",this.searchObject)
                    if(this.isLocalStorageEmpty && Object.entries(JSON.parse(this.isLocalStorageEmpty)).length===0)
                        document.getElementById('energieElement').click()
                }
            },
            'searchObject.moteur':{
                async handler(value){
                    this.$store.commit("setCurrentSearch",this.searchObject)
                    if(this.isLocalStorageEmpty && Object.entries(JSON.parse(this.isLocalStorageEmpty)).length===0)
                        await this.validate()

                }
            },
            'distanceKm':{
                handler(value){
                    const centresFiltered = this.$store.getters.allCentresWithDistances.filter((centre) => parseInt(centre.distance.value) <= parseInt(value)*1000)
                    this.filteredCentres = centresFiltered
                    if(this.$refs.form.validate()){
                        this.$store.commit('setCentresSelected',centresFiltered)
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .button-color-hover{
        background-color : red;
    }
    .button-color{
        background-color: var(--btn-color);
    }
    .container-shadow-custom{
        box-shadow: 0 5px 6px 14px rgb(0 0 0 / 22%);
    }



</style>
