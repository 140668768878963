<template>
    <v-container>
        <v-row>
            <v-col class="col-10">
                <v-row class="mb-2" justify="start">
                    <p class="text-main-title mb-1">GESTION DES COOKIES</p>
                </v-row>
                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">La politique d’utilisation des cookies</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Qu’est-ce que les cookies et comment les gérer ? Lors de la consultation du site, des informations relatives à votre navigation sont ainsi enregistrées dans des fichiers
                                « cookies » installés sur votre terminal, sous réserve de votre consentement lors de votre première consultation du site (bannière cookies). Vos paramétrages concernant les
                                cookies peuvent être modifiés à tout moment dans les conditions décrites ci-dessous. Un cookie est un petit fichier texte transféré sur votre ordinateur, smartphone ou
                                tablette par le biais de votre navigateur Internet. Temporaires (cookies de session) ou permanents, les cookies sont enregistrés sur le disque dur de votre terminal lorsque
                                vous visitez notre site, afin de permettre à AutoBilan-Systems de vous reconnaître lors de vos visites ultérieures. Les cookies de session sont actifs le temps de votre visite
                                uniquement et supprimés lorsque vous fermez le navigateur. Les cookies permanents restent sur le disque dur de votre terminal lorsque vous quittez le site et y demeurent jusqu’à
                                ce que vous les supprimiez manuellement ou que le navigateur les purge après un certain délai.</p>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Usage et catégorie de cookies utilisés</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                AutoBilan-Systems utilise :
                                Des cookies techniques ne nécessitant pas votre consentement pour le bon fonctionnement du site.
                                Des cookies analytics nécessitant votre consentement. Ils permettent de surveiller la performance du site internet, par exemple pour déterminer le nombre de fois qu’une page est
                                consultée ainsi que le nombre d’utilisateurs uniques. Les services d’analyse web peuvent être conçus et exploités par des tiers. Les informations fournies par ces cookies sont utilisées
                                pour optimiser l’expérience de l’utilisateur ou pour identifier les parties du site Internet qui peuvent nécessiter des interventions de maintenance ou d’optimisation.
                                AutoBilan-Systems utilise Google Analytics pour recueillir des informations sur les visiteurs du site grâce à ces cookies. Ces informations peuvent être utilisées par les fournisseurs de ces solutions.
                            </p>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Données collectées et consentement</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Les cookies analytics ne collectent que des données de connexion et de navigation : adresse IP affectée au terminal, les dates, heures de connexion et de déconnexion, le lieu de connexion, les données
                                en cache, pages vues, … Ces données sont anonymisées et agrégées. Si vous les refusez, nous ne saurons pas lorsque vous avez visité notre site, et nous ne pourrons pas suivre les performances de ce site.</p><br>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Qui est destinataire de vos données via les cookies ?</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Seules les personnes habilitées ont accès à vos données. L’accès à vos données personnelles est strictement limité aux personnes habilitées AutoBilan-Systems et de ses partenaires, sociétés tierces dont
                                les dispositions techniques et organisationnelles pour la protection de vos données sont établies. Ces personnes sont celles qui, par leur fonction, sont légitimes à se voir communiquer vos données afin
                                de réaliser les finalités qui vous ont été préalablement décrites. Vos données peuvent être également transférées vers certains réseaux sociaux, qui implémentent des cookies utiles au partage du
                                contenu de notre site sur leur plateforme, ou pour l’élaboration de statistiques d’accès. N’ayant pas de contrôle sur l’usage de ces fonctionnalités par ces acteurs, nous vous invitons à prendre
                                connaissance de la politique de gestion de ces cookies sur le site du réseau social concerné. Vos données personnelles ne seront pas vendues, partagées, communiquées ou transférées à un tiers non
                                identifié par la présente politique de gestion des cookies.
                            </p>
                        </v-row>
                    </v-col>
                </v-row>


                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Quelle est la durée de vie de nos cookies ?</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Vos données sont conservées uniquement le temps nécessaire. Conformément aux dispositions légales en vigueur, AutoBilan-Systems ne conserve vos données personnelles que le temps nécessaire aux finalités indiquées :
                                la durée des cookies déposés sur votre terminal suite à l’expression de votre consentement est limitée à 24 mois correspondant à l’utilisation de Google Analytics. Cette durée de vie n’est pas prolongée lors de
                                nouvelles visites sur le site. A l’issue des 24 mois de validité, votre consentement est à nouveau sollicité.
                            </p>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Comment gérer et désactiver les cookies ?</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">Vous disposez de différents moyens pour gérer les cookies.</p>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-9">
                        <v-row>
                            <p class="text-title mb-0">Le paramétrage de votre navigateur internet</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Vous pouvez à tout moment choisir de désactiver ces cookies. Votre navigateur peut également être paramétré pour vous signaler les cookies qui sont déposés
                                dans votre ordinateur et vous demander de les accepter ou non. Vous pouvez accepter ou refuser les cookies au cas par cas ou bien les refuser systématiquement une fois pour toutes.
                                Nous vous rappelons que le paramétrage est susceptible de modifier vos conditions d’accès à nos services nécessitant l’utilisation de cookies.
                                *Afin de gérer les cookies au plus près de vos attentes nous vous invitons à paramétrer votre navigateur en tenant compte de la finalité des cookies telle que mentionnée ci-avant.<br>
                                Pour plus d’informations, vous pouvez consulter le site de <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/">la CNIL</a>.
                            </p>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row justify="start" class="ml-1">
                    <v-col class="col-10 mt-3">
                        <v-row>
                            <p class="text-title mb-0">La désactivation des Cookies de mesure et d’audience :</p>
                        </v-row>
                        <v-row>
                            <p class="text-style text-justify">
                                Pour modifier vos paramètres en rapport avec les Cookies de mesure d’audience et statistiques, nous vous invitons à vous rendre aux adresses suivantes :
                            </p>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">Google Analytics</a>
                        </v-row>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "CookiesUseComponent",
        mounted() {
            this.$vuetify.goTo(0)
        }
    }
</script>

<style scoped>
    .text-style{
        font-family: Lato, sans-serif;
        color: #8f8f8f;
        font-size: 15px!important;
    }
    .text-title{
        font-family: Lato, sans-serif!important;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .text-main-title{
        text-transform: uppercase;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.5px;
        text-decoration-color: rgb(70,71,74);
    }
</style>
