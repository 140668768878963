<template>
    <v-dialog :value="paymentFormDialog" max-width="800" id="time-slot" persistent class="my-10 theme--light" height="50%">
        <v-card v-if="$store.getters.dateSelected!==null">
            <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>

                <v-toolbar color="var(--btn-color)" dark>
                    <v-row justify="center" align="center">
                        <!--<v-col class="col-4">
                            <v-btn class="ma-1" color="white" plain @click="retourFormReservation">
                                Retour
                            </v-btn>
                        </v-col>-->
                        <v-col cols="11">
                            <v-row class="ma-0 pa-0" justify="center">
                                <v-tabs
                                        fixed-tabs
                                        background-color="var(--btn-color)"
                                        v-model="tab"
                                >
                                    <v-tab
                                            v-for="(item,index) in items"
                                            :key="item"
                                             class="px-0">
                                        <v-icon v-if="index===0" size="40">{{mdiCreditCard}}</v-icon>
                                        <v-icon v-if="index===1" size="40">{{mdiCreditCardMultipleOutline}}</v-icon>
                                        <v-icon v-if="index===2" size="40">{{mdiPiggyBankOutline}}</v-icon>
                                        <span class="ml-1" style="font-size: 0.8rem!important;">{{ item }}</span>

                                    </v-tab>

                                </v-tabs>
                            </v-row>
                        </v-col>
                        <v-col cols="1" class="">
                            <v-row justify="end" align="center">
                                <v-btn
                                        icon
                                        dark
                                        @click="closeDialogPayment"


                                >
                                    <v-icon color="white">mdi-close</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                            v-for="(item,index) in items"
                            :key="item"
                    >
                        <v-card
                                color="basil"
                                flat
                        >
                            <v-card-text v-if="index==0">
                                <v-row>
                                    <v-col>
                                        <v-alert
                                                color="primary"
                                                dark
                                                border="left"
                                                prominent
                                                size="50"
                                        >
                                            <v-row align="center">
                                                <v-col cols="3">
                                                    <v-row justify="center" align="center">
                                                        <v-icon size="100">
                                                            {{mdiCreditCard}}
                                                        </v-icon>
                                                    </v-row>
                                                </v-col>
                                                <v-col class="ml-11" cols="8">
                                                    <v-row justify="center">
                                                        <v-col class="px-0">
                                                        <span>
                                                            Vous allez être redirigé vers le service de paiement en ligne pour effectuer un paiment comptant.
                                                        </span>
                                                        </v-col>
                                                    </v-row>
                                                    <detail-payment></detail-payment>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" style="font-size: 16px" class="mb-3">
                                    <span>Veuillez confirmer pour passer à l'étape suivante.</span>
                                </v-row>
                                <v-row justify="center" class="mb-2">
                                    <v-col col="4">
                                        <v-row justify="center">
                                            <v-col lg="2" md="2">
                                                <v-row justify="center">
                                                    <b-button :disabled="spinner" v-on:click="startSinglePayment"  style="color:white" class="rounded-pill ma-0  btn btn-sushi btn-sm">Confirmer</b-button>
                                                </v-row>
                                            </v-col>
                                            <v-col lg="2" md="3">
                                                <v-row justify="center" align="center">
                                                    <b-button v-on:click="retour"  style="color:white;background-color: red" class="rounded-pill ma-0 btn btn-sushi btn-sm">Retour</b-button>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                            </v-card-text>

                            <v-card-text v-if="calculatePrice() > 50 && index===1">
                                <v-row>
                                    <v-col v-if="calculatePrice() > 50 && index===1">
                                        <v-alert
                                                color="primary"
                                                dark
                                                border="left"
                                                prominent
                                        >
                                            <v-row align="center">
                                                <v-col cols="3">
                                                    <v-row justify="center" align="center">
                                                        <v-icon size="100">
                                                            {{mdiCreditCardMultipleOutline}}
                                                        </v-icon>
                                                    </v-row>
                                                </v-col>
                                                <v-col class="ml-11" cols="8">
                                                    <v-row justify="center">
                                                        <v-col class="px-0">
                                                            <span>
                                                                Vous allez être redirigé vers un service de paiement pour effectuer un paiment en plusieurs fois sans frais.
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <detail-payment></detail-payment>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" style="font-size: 16px" class="mb-3">
                                    <span>Selectionnez le nombre de paiement souhaité.</span>
                                </v-row>
                                <v-row justify="center" class="mb-2" align="end">
                                    <v-col col="2">
                                        <v-row justify="center">
                                            <img style="width: 45%" src="/images/alma_logo.png">
                                        </v-row>
                                    </v-col>
                                    <v-col col="2">
                                        <v-row justify="center">
                                            <b-button v-on:click="choiceNbPayment(2)"  style="color:white" class="ma-0  btn btn-sushi btn-sm rounded-pill"><v-row align="center" class="pa-2 px-3"><span style="font-size: 25px">2</span><span style="font-size:18px">X</span></v-row></b-button>
                                        </v-row>
                                    </v-col>
                                    <v-col col="2">
                                        <v-row justify="center">
                                            <b-button v-on:click="choiceNbPayment(3)" style="color:white" class="ma-0  btn btn-sushi btn-sm rounded-pill"><v-row align="center" class="pa-2 px-3"><span style="font-size: 25px">3</span><span style="font-size:18px">X</span></v-row></b-button>
                                        </v-row>
                                    </v-col>
                                    <v-col col="2">
                                        <v-row justify="center">
                                            <b-button v-on:click="choiceNbPayment(4)" style="color:white" class="ma-0  btn btn-sushi btn-sm rounded-pill"><v-row align="center" class="pa-2 px-3"><span style="font-size: 25px">4</span><span style="font-size:18px">X</span></v-row></b-button>
                                        </v-row>
                                    </v-col>
                                    <v-col col="2">
                                        <v-row justify="center" align="center" class="mt-2">
                                            <b-button v-on:click="retour"  style="color:white;background-color: red" class="rounded-pill ma-0 btn btn-sushi btn-sm">Retour</b-button>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text v-if="calculatePrice() < 50 && index===1">
                                <v-row>
                                    <v-col>
                                        <v-alert
                                                dark
                                                border="left"
                                                prominent
                                                type="warning"
                                        >
                                            <v-row align="center">
                                                <v-col cols="9">
                                                    <v-row justify="center">
                                                        <v-col>
                                                            <span>
                                                                Paiement en plusieurs fois seulement possible sur un montant TTC supérieur à 50€.
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text v-if="index==2">
                                <v-row justify="center" v-if="!$store.getters.authenticate">
                                    <v-col>
                                        <login :paramReturn="true" @retourDialog="retour" :paramRegister="false"></login>
                                    </v-col>
                                </v-row>
                                <div v-if="$store.getters.authenticate">
                                <v-row justify="center" >

                                    <v-alert
                                            color="primary"
                                            dark
                                            border="left"
                                            prominent
                                    >
                                    <v-row align="center" v-if="$store.getters.authenticate">
                                        <v-col cols="3">
                                            <v-row justify="center" align="center">
                                                <v-icon size="100">
                                                    {{mdiPiggyBankOutline}}
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col class="ml-11" cols="8">
                                            <v-row justify="center">
                                                <v-col class="px-0">
                                                                <span>
                                                                    Vous allez être redirigé vers le service de paiement en ligne pour effectuer un paiment.
                                                                </span>
                                                </v-col>
                                            </v-row>
                                            <detail-payment :bct="true"></detail-payment>
                                        </v-col>
                                    </v-row>
                                    </v-alert>
                                </v-row>
                                    <v-row justify="center" style="font-size: 16px" class="mb-3">
                                        <span>Veuillez confirmer pour passer à l'étape suivante.</span>
                                    </v-row>
                                    <v-row justify="center" class="mb-2">
                                        <v-col col="6">
                                            <v-row justify="center" align="center" class=" py-1">
                                                <b-button v-on:click="retour"  style="color:white;background-color: red" class="rounded-pill ma-0 btn btn-sushi btn-sm">Retour</b-button>
                                            </v-row>
                                            <v-row justify="center" class="py-1">
                                                <v-col lg="3" md="3">
                                                    <v-row justify="center">
                                                        <b-button :disabled="spinner" v-on:click="startSinglePayment"  style="color:white" class="rounded-pill ma-0  btn btn-sushi btn-sm">Confirmer le paiement</b-button>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-form>
        </v-card>
        <v-overlay :value="spinner">
            <v-progress-circular :size="100" :width="10" color="#1F5578" indeterminate></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
    import detailPaymentComponent from './detailPaymentComponent'
    import { mdiCreditCard,mdiCreditCardOutline,mdiCreditCardMultipleOutline,mdiPiggyBankOutline } from '@mdi/js';
    import login from "../client/loginComponent";
    export default {
        name: "FormPayment",
        components:{
            'login':login,
            'detail-payment':detailPaymentComponent
        },
        mounted(){

        },
        data(){
            return{
                spinner:false,
                valuePayment:'',
                valid:false,
                tab:false,
                paymentFormDialog: false,
                items : ['Paiement Comptant','Paiement en x fois','Bourse CT'],
                mdiCreditCard:mdiCreditCardOutline,
                mdiCreditCardMultipleOutline:mdiCreditCardMultipleOutline,
                mdiPiggyBankOutline:mdiPiggyBankOutline,
                typePayment:''
            }
        },
        methods:{
            retour(){
                this.$store.commit('setRetourFromPayment')
                this.paymentFormDialog = false

            },
            getBestTarif(){
                const centre = this.$store.getters.centreSelected
                const {pst_tarif}=centre.bestTarif
                const sortBestPromotion=(array,index=0,acc={},tarif=pst_tarif,result=pst_tarif)=>{
                    const {pourcentage,montant}= array[index]
                    if(parseInt(pourcentage)===0){
                        if(parseInt(result) > parseInt(tarif)-parseInt(montant)){
                            result =  parseInt(tarif)-parseInt(montant)
                            acc=array[index]
                        }
                    }else{
                        if(parseInt(result) > parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)){
                            result =   parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)
                            acc=array[index]
                        }
                    }
                    return array.length-1  > index? sortBestPromotion(array,index+1,acc,tarif,result) : {item:acc,tarif:result}
                }
                return centre.bestTarif.promotions.length !==0 ? sortBestPromotion(centre.bestTarif.promotions): pst_tarif
            },
            calculatePrice(){
                if(this.$store.getters.promotionsSelected.length >0 && this.$store.getters.finalPrice.item !==undefined){
                    const {prixInitial,promo} =this.$store.getters.finalPrice
                    return promo.item.pourcentage === 0 ? parseFloat((prixInitial - promo.item.montant)+1.99).toFixed(2) : parseFloat((prixInitial - (prixInitial*promo.item.pourcentage/100))+1.99).toFixed(2)
                }else
                return parseFloat(this.$store.getters.centreSelected.bestTarif.pst_tarif +1.99).toFixed(2)
            },
            closeDialogPayment(){
               // this.paymentFormDialog = false
            },
            async choiceNbPayment(index){
                this.typePayment = index
                window.location.href= await this.generatePaymentUrl()
            },
            async generatePaymentUrl(){
                this.spinner= true
                const modString = (value)=> value.toString().length == 1 ? `0${value}` : value
                const modHour = (hour) => hour.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}/)[0]
                const object = {
                    HeureDebutRDV:modHour(this.$store.getters.dateHoraireSelected.debut),
                    HeureFinRDV:modHour(this.$store.getters.dateHoraireSelected.fin),
                    DateRDV:`${this.$store.getters.dateSelected.date.getFullYear()}-${modString(parseInt(this.$store.getters.dateSelected.date.getMonth())+1)}-${modString(this.$store.getters.dateSelected.date.getDate())}`,
                    RangLigne:this.$store.getters.dateHoraireSelected.idLigne,
                    centre:this.$store.getters.centreSelected,
                    tokenClientPayment: this.$store.getters.tokenPaymentClient,
                    idTypePayment:this.typePayment,
                    total_amount:parseFloat(this.calculatePrice())*100,
                    cvc:this.$store.getters.cvc
                }
                if(this.typePayment===1){
                    const {webToken} = await this.$store.dispatch('setLock',object)
                    //return `https://sandbox-webkit.lemonway.fr/absgroup/dev/?moneyintoken=${webToken}`
                    return `${process.env.VUE_APP_LEMONWAY_WEB_KIT_URL}?moneyintoken=${webToken}`
                }
                if(this.typePayment ===2 || this.typePayment===3 || this.typePayment===4){
                    const {url} = await this.$store.dispatch('setLock',object)
                    return await url
                }
            },
            async startSinglePayment(){
                this.typePayment = 1
                window.location.href = await this.generatePaymentUrl()

            }
        },
        watch:{
            '$store.getters.reservationClientInformations':{
                handler(){
                    this.paymentFormDialog=true
                }
            }
        }
    }
</script>

<style scoped>
    .text-style-1{
        font-family: Lato, sans-serif;
        color: white;
        font-weight: bold;
        font-size: 16px!important;
        vertical-align: center;
    }
    .text-style-2{
        font-family: Lato, sans-serif;
        color: black;
        font-weight: bold;
        font-size: 16px!important;
        background-color: white;
        vertical-align: center;
    }
</style>
