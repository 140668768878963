<template>
    <div>
        <section class="section-full text-left bg">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>Poids lourds</h1>
                        <p></p>
                        <ol class="breadcrumb">
                            <li>
                                <router-link v-bind:to="{name:'hub'}">
                                    <button>Accueil</button>
                                </router-link>
                            </li>
                            <li v-show="idSelected == null" class="active">Services</li>
                            <li v-show="idSelected !== null">
                                <button v-on:click="redirectRoot">Services</button>
                            </li>
                            <li v-if="idSelected !== null" class="active">{{ articleSelected.titre }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!--Section popular categories 1-->
        <v-container class="mt-5 mb-16">
            <v-row class="text-center" v-if="idSelected== null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" class="pt-1" v-for="article in articles.slice(0, 3)" :key="article.id">
                    <button class="img-thumbnail-variant-1" v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <v-row class="text-center" v-if="idSelected== null" justify="center">
                <v-col cols="12" sm="12" md="12" lg="3" class="pt-0" v-for="article in articles.slice(3)" :key="article.id">
                    <button class="img-thumbnail-variant-1"  v-on:click="setArt(article)">
                        <img :src="article.img" alt="">
                        <div class="caption">
                            <h4 class="text-white">{{ article.titre }}</h4>
                        </div>
                    </button>
                </v-col>
            </v-row>
            <v-row v-if="idSelected!==null">
                <ArtComponent :theme="articleSelected"></ArtComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ArtComponent from "../../components/ArtComponent.vue";

export default {
    name: "PoidsLourds",
    components: {
        ArtComponent
    },
    props: ['art', 'id','nameUrl'],
    mounted(){
        this.$vuetify.goTo(0)
        this.root = document.documentElement
        this.$store.commit('setStatusClient',false)
        this.root.style.setProperty('--btn-color', '#89B951')
        this.root.style.setProperty('--options-orange', '#89B951')

        if(this.nameUrl){
            this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==this.nameUrl)[0]
            this.idSelected = this.articleSelected.id
        }

    },
    methods:{
        redirectRoot(){
            this.resetArticle()
            this.$router.push(`/poids-lourds`)
        },
        setArticles(currentNameUrl){
            this.articleSelected =  this.articles.filter(({nameUrl})=> nameUrl==currentNameUrl)[0]
            this.idSelected = this.articleSelected.id
        },
        setArt(val){
            this.articleSelected = val
            this.idSelected = val.id
            this.$router.push(`/poids-lourds/${val.nameUrl}`)
        },
        resetArticle(){
            this.idSelected= null
        }
    },
    watch:{
        'id':{
            handler(){
                if(this.id===null){
                    this.resetArticle()
                }
            }
        },
        'nameUrl':{
            handler(){
                if(this.nameUrl){
                    this.setArticles(this.nameUrl)
                }
            }
        }
    },
    data() {
        return {
            idSelected:null,
            articleSelected :'',
            root:null,
            articles: [
                {
                    id: 1,
                    nameUrl:'suivi&rachat',
                    img: "../images/img/PL-suiviRachats.jpg",
                    texte:
                        "Pour concrétiser votre projet de création, ou de rachat, d'un centre de contrôle de véhicules lourds (PL), AutoBilan-Systems, met à votre disposition toute son expérience et son savoir-faire pour vous accompagner dans le choix des solutions techniques et règlementaires. L'obtention de l'agrément préfectoral de votre future installation est conditionnée au respect d'un cahier des charges précis et souvent complexe ; c'est pourquoi, AutoBilan-Systems prend également en charge la création et le suivi de vos dossiers de demande d'agrément centres et contrôleurs, ainsi que le dossier de demande d'accréditation de votre installation.",
                    titre: "Suivi & rachat",
                    textHighLight:"",
                    urlParam:"suivi-rachat"
                },
                {
                    id: 2,
                    nameUrl:'systeme-qualite',
                    img: "../images/img/PL-systemeQualite.jpeg",
                    texte:
                        "AutoBilan-Systems met à disposition de ses clients un système de management de la qualité conforme à la norme NF EN ISO/CEI 17020 et aux exigences de l'arrêté modifié du 27/07/2004. Le système qualité proposé est adapté à la taille de votre structure, sa simplicité de mise en œuvre permet à votre personnel de se consacrer pleinement à la production du centre. Accessible sur notre site internet, via un espace privé, AutoBilan-Systems en assure la mise à jour régulière.",
                    titre: "Système qualité",
                    textHighLight:""
                },
                {
                    id: 3,
                    nameUrl:'veille-reglementaire',
                    img: "../images/img/autres-veilleReg.jpg",
                    texte:
                        "AutoBilan-Systems se charge d'assurer la veille règlementaire afin que votre personnel dispose d'une documentation à jour pour exercer son métier en toute sérénité. Toute la base documentaire règlementaire, relative à l'activité de contrôle technique des véhicules légers, est consultable sur notre site internet, via un espace privé.",
                    titre: "Veille règlementaire",
                    textHighLight:""
                },
                {
                    id: 4,
                    nameUrl:'assistance',
                    img: "../images/img/autres-assistance.jpeg",
                    texte:
                        "Les collaborateurs d'AutoBilan-Systems sont à votre service pour répondre à toutes vos questions d'ordre technique, qualité, règlementaire et administratif. Nous vous assurons également une assistance dans la rédaction des courriers à faire au COFRAC et aux administrations de tutelles, telles que Préfecture ou DREAL.",
                    titre: "Assistance",
                    textHighLight:""
                },
                {
                    id: 5,
                    nameUrl:'proces-verbaux',
                    img: "../images/img/PL-procesVerbaux.png",
                    texte:
                        "AutoBilan-Systems c'est également la fourniture de procès-verbaux de contrôle technique obligatoire, conformes à l'annexe II de l'arrêté modifié du 27/07/2004 ainsi que des procès-verbaux de contrôle volontaire non réglementé.",
                    titre: "Procès-verbaux",
                    textHighLight:
                        "1 400 000 Procès-Verbaux VL & PL vendus en 2020 [Rapports de contrôles édités avec nos logiciels métiers]",
                    logoQualiopi:true
                },
                {
                    id: 6,
                    nameUrl:'audits&formations',
                    img: "../images/img/autres-Quali-Formation.jpeg",
                    texte:
                        "Organisme agréé par le Ministère des Transports (cf. CDC 21), AutoBilan-Systems réalise les audits règlementaires des centres et des contrôleurs PL indépendants. Nous pouvons également effectuer, en cas de besoins ponctuels, des audits internes de centres et de contrôleurs et vous assister lors des évaluations COFRAC.<br>Il assure, à travers son catalogue toutes les formations nécessaires à l'exercice de la profession.<br>AutoBilan-Systems est certifié QUALIOPI, gage de la conformité et de la qualité des processus de mise en œuvre de ses formations.",
                    titre: "Audits & Formations",
                    textHighLight:"",
                    logoQualiopiRight:true,
                    linkFormation :"https://espace-client.autobilan-systems.com/formations/liste_type_formations/pl"
                },
            ],
        };
    },
};
</script>


<style scoped>
.bg {
    background-image: url("../../../public/images/bandeaux/bandeau-pl.jpeg");
}
</style>
