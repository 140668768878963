<template>
    <div>
        <main-header-menu @updateStatusClient="updateStatusClient"></main-header-menu>
        <body>
            <div>
                <router-view></router-view>
            </div>
        </body>
        <cookie-message></cookie-message>
        <footer-component style="z-index: 0" class="ma-0 pa-0"></footer-component>
    </div>
</template>

<script>
    import MainHeaderMenu from "../components/MainHeaderMenu";
    import FooterComponent from "../components/FooterComponent";
    import CookieMessageComponent from "../components/CookieMessageComponent";
export default {
    name: "Home",
    async mounted(){
        await this.getCentreData()
    },
    data(){
        return {
            typeClient: true,
            componentKey:0
        }
    },
    components: {
        'main-header-menu' : MainHeaderMenu,
        'footer-component' : FooterComponent,
        'cookie-message': CookieMessageComponent


    },
    computed:{
        appName(){
            return this.$store.getters.appName
        },

    },
    methods:{
        async getCentreData(){
            const response = await this.$store.dispatch("getDataCentre",localStorage.getItem('tokenCentre'))

        },
        updateStatusClient(data){
            this.typeClient = data
        }
    }
}


</script>

<style scoped>
</style>
