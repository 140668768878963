<template>
    <div class="mt-12 mb-12">
        <v-row justify="center" class="mt-12 mb-12">
            <v-col cols="5"class="rounded-lg my-5">
                <v-row v-if="access">
                    <v-toolbar class="" color="var(--btn-color)" dark>
                        <v-row justify="center" align="center">
                            <span style="font-size: 1.2rem">Récupération du mot de passe</span>
                        </v-row>
                    </v-toolbar>
                </v-row>
                <v-row justify="center">
                    <v-alert
                            prominent
                            type="error"
                            v-if="!access"
                    >
                        <v-row align="center">
                            <v-col class="grow">
                               Vous n'avez pas accès à cette page. Vous allez être renvoyé à la page d'accueil.
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-row>
                <v-form ref="form" v-model="valid" class="form-variant-1 mt-3" lazy-validation v-if="access">
                    <v-row justify="center">
                        <v-col
                                cols="12"
                                md="5"
                        >
                            <v-text-field
                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show3 ? 'text' : 'password'"
                                    label="Mot de Passe"
                                    :rules="passwordRules"
                                    v-model="password"
                                    class="input-group--focused"
                                    @click:append="show3 = !show3"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col
                                cols="12"
                                md="5"
                        >
                            <v-text-field
                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show3 ? 'text' : 'password'"
                                    label="Confirmer mot de passe"
                                    v-model="passwordConfirmation"
                                    :rules="passwordConfirmationRules"
                                    :disabled="password.length===0"
                                    class="input-group--focused"
                                    @click:append="show3 = !show3"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                            <b-button :disabled="!valid"  @click="validate" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Envoyer</b-button>
                    </v-row>
                </v-form>
                <v-row justify="center">
                    <v-dialog
                            class="ma-0 pa-0"
                            v-model="dialogConfirmationChange"
                            persistent
                            width="unset"
                    >
                        <v-alert
                                class="ma-0 "
                                prominent

                                type="success"
                        >Votre mot de passe a été modifié.</v-alert>
                    </v-dialog>
                </v-row>
                <v-row justify="center">
                    <v-dialog
                            class="ma-0 pa-0"
                            v-model="tokenAvailable"
                            persistent
                            width="unset"
                    >
                        <v-alert class="ma-0" prominent type="error">
                            Ce lien est périmé.
                        </v-alert>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "resetPassword",
        data(){
            return{
                tokenAvailable:false,
                dialogConfirmationChange:false,
                response:false,
                token:'',
                access:false,
                valid:false,
                password:'',
                passwordConfirmation:'',
                show3:false,
                passwordRules:[
                    v => !!v || 'Saisir votre mot de passe',
                    v => /^[A-ZÉÈÊa-zéèê~"#'{(-|`_ç^à@)°=}+^¨$£%ùµ*?,;.:!§]{8,}$/.test(v) || '8 caractères nécessaires'
                ],
                passwordConfirmationRules:[
                    v => !!v || 'Saisir votre confirmation de mot de passe',
                    v=> v == this.password || 'Ne correspond pas au mot de passe ',
                    v => /^[A-ZÉÈÊa-zéèê~"#'{(-|`_ç^à@)°=}+^¨$£%ùµ*?,;.:!§]{8,}$/.test(v) || 'Confirmation mot de passe non valide'
                ],
            }
        },
        methods:{
            async validate(){
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                    const response = await this.$store.dispatch("resetPWD",{token:this.token,password:this.password})
                    const {password,tokenAvailable} = response.data

                    if(tokenAvailable !== undefined)
                        this.tokenAvailable=true
                    if(password !== undefined)
                        this.dialogConfirmationChange = password
                    this.redirectToBCT()
                }
            },
            redirectToIndex(){
                setTimeout(()=>{
                    this.$router.push('/')
                },3000)
            },
            redirectToBCT(){
                setTimeout(()=>{
                    this.$router.push('/budgetct')
                },3000)
            }
        },
        async mounted() {
            const { token } = this.$route.params
            const {access} = await this.$store.dispatch("checkTokenResetPassword",{token:token})
            this.access = access
            ! access ?
                this.redirectToIndex():
                this.token=token
        }
    }
</script>

<style scoped>

</style>
