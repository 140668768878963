<template>
    <v-row justify="center">
    <v-col xs="12" sm="12" md="7" lg="6" xl="4">
        <v-card class="mt-16 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 margin-card">
            <v-form ref="form" v-model="valid" class="form-variant-1" lazy-validation>
                <!--              toolbar-->
                <v-toolbar color="var(--btn-color)" dark>
                    <v-row justify="center" align="center">
                        <v-col class="col-4">
                            <v-btn class="ma-1" color="white" plain @click="retourFormReservation">
                                Retour
                            </v-btn>
                        </v-col>
                        <v-col>
                            <span style="font-size: 1.2rem">Paiement Bourse CT</span>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <!--              Info Vehicule-->
                <v-card-text class="mt-0 pt-0" elevation="4">
                    <v-card-title class="pa-0 ma-0 ml-4 mb-3 mt-4">
                        <v-row class="pa-0 ma-0 mt-3">
                            <v-col class="col-12 pa-0 ma-0">
                                <span class="ma-0 pa-0 rounded">Vos Informations Bancaires</span>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <!--                  Coordonnées-->
                    <v-row justify="center">
                        <v-col class="col-8">
                            <v-row justify="center" class="mt-0 pt-0">
                                <v-col xs="8" sm="7" md="9" lg="7" xl="8">
                                    <v-text-field v-model="lastName" :rules="nameRules" label="Nom du titulaire de carte" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-0 pt-0">
                                <v-col xs="8" sm="7" md="9" lg="7" xl="8">
                                    <v-text-field v-model="numberCardBank"  :value="numberCardBank" :rules="numberCardBankRules" label="Numéro de carte bancaire" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-0 pt-0">
                                <v-col xs="8" sm="7" md="9" lg="7" xl="8">
                                    <v-text-field label="Date d'expiration MM/AA" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-0 pt-0">
                                <v-col xs="8" sm="7" md="9" lg="7" xl="8">
                                    <v-text-field v-model="cvv" label="CODE CVV" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <div class="text-center">
                        <b-button style="color:white" @click="validate" :disabled="!valid" class="btn btn-sushi btn-sm">Enregistrer</b-button>
                    </div>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-col>
    </v-row>
</template>

<script>
    import { mdiLightbulb,mdiAccountHardHat,mdiShieldCar,mdiBellAlertOutline,mdiBoomGateAlert,mdiBell,mdiEye } from '@mdi/js';

    export default {
        name: "AlmaFormComponent",
        data(){
            return {
                cvv:'',
                numberCardBank:'',
                dialogTypeControle:false,
                typeControlePicked:'',
                immatriculation:'',
                radiosImmatriculation:true,
                valid:false,
                lastName:'',
                denomination:'',
                email:'',
                phone:'',
                emailRules: [
                    v => !!v || 'Un E-mail est nécessaire',
                    v => /.+@.+\..+/.test(v) || 'E-mail non valide',
                ],
                nameRules: [
                    v => !!v || 'Saisir votre nom',
                    v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

                ],
                numberCardBankRules : [
                    v => !!v || 'Saisir votre dénomination ',
                    v => /^([0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$)/.test(v) || 'Dénomination non valide'
                ],
                icons:{

                }
            }
        },
        created(){
            this.$store.dispatch('getVehicules')

        },
        methods:{
            retourFormReservation(){

            },
            validate(){
                this.$refs.form.validate()
            },


        },
        watch:{
            'vehiculeMarque':{
                handler(value){
                    this.modeles = value.modeles
                }
            },
            '$store.getters.dateHoraireSelected':{
                handler(value){
                    if(this.$store.getters.dateHoraireSelected != null){
                        this.dialogFormReservation=true
                    }
                }
            },
            'radiosImmatriculation':{
                handler(value){

                }
            },
            'immatriculation':{
                handler(value){
                    if(this.radiosImmatriculation === "true"){
                        if(/^[A-Z]{2}$/.test(value))
                            this.immatriculation = value.concat('-')

                        if(/^[A-Z]{2}-\d{3}$/.test(value))
                            this.immatriculation = value.concat('-')

                        if(/^\d{4}$/.test(value))
                            this.immatriculation = value.concat(' ')

                        if(/^\d{3,4} [A-Z]{2}$/.test(value))
                            this.immatriculation = value.concat(' ')
                    }
                    if(/[a-z]/.test(value))
                        this.immatriculation = value.toString().toUpperCase()
                }
            },
            'numberCardBank':{
                deep:false,
                handler(value){
                    if(/[0-9]$/gi.test(value)){
                       // this.numberCardBank = this.numberCardBank.substring(this.numberCardBank.length-1)
                        this.numberCardBank =  value*2
                    }
                }
            },
            'lastName':{
                handler(value){
                    this.lastName = value.toString().toUpperCase()
                }
            },
            'denomination':{
                handler(value){
                    this.denomination = value.toString().toUpperCase()
                }
            },
            'typeControlePicked':{
                handler(value){
                    if(this.typeControle.indexOf(value) > 1){
                        this.dialogTypeControle=true
                    }

                }
            },
        }
    }
</script>

<style scoped>
.margin-card{

}
</style>
