<template>
    <v-col lg="12">
        <v-row justify="center" class="pa-3">
            <v-col v-for="(formule, index) in formules" :key="index" col="4" class="ma-2 rounded" style="background-color: #FFF">
                <!--<v-card style="overflow: hidden" class="ma-0 pa-0 rounded-xl" :color="colors.card">-->
                <v-card-text class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0 rounded" justify="center">
                        <h6 class="my-2 pa-1 px-3 mt-5 font-weight-bold rounded-pill" style="background-color: #F24C27;font-size: 1.7em;color: white">FORMULE {{ formule.name }}</h6>
                    </v-row>
                    <!--<v-row class="pa-0 ma-0" justify="center">
                        <v-avatar class="font-weight-bold" :color="colors.icon" style="font-size: 1.2em">{{ index + 1 }}</v-avatar>
                    </v-row>-->
                    <!--<v-row class="pa-0 ma-0" justify="center">
                        <p class="pa-0 ma-0 font-weight-bold" :style="{'color':colors.text}">{{colors.texte}}</p>
                    </v-row>-->
                    <!--<v-row class="pa-0 ma-0" justify="center">
                        <p class="pa-0 ma-0 font-weight-bold" :style="{'color':colors.text}">{{ 1 + index }} VÉHICULE{{index===0?"":"S"}}</p>
                    </v-row>-->
                    <v-row class="pa-0 ma-0" justify="center">
                        <p class="font-weight-bold" :style="{'color':formule.text}">Abondement maximum 150€</p>
                    </v-row>
                    <v-row class="pa-0 ma-0" justify="center">
                        <p class="font-weight-bold" :style="{'color':formule.text}" style="font-size: 1.7em">{{(index + 1) * 5}}€/mois</p>
                    </v-row>
                    <v-row class="pa-2 ma-0" justify="center">
                        <b-button  @click="paymentBCT(formule.id)" style="color:white" class="btn btn-sushi btn-sm mr-2 pa-1 ma-0 px-2 rounded-pill"><span class="text-lg-caption" >Souscrire</span></b-button>
                    </v-row>
                </v-card-text>
                <!--</v-card>-->
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
    export default {
        name: "paymentFormulesBCT",
        data(){
            return{
                formules: [
                    {
                        id:1,
                        card: '#FFF',
                        icon: '#038B8BFF',
                        text: '#025578',
                        name:'Confort',
                        texte:'Je pense passer mon contrôle technique d\'ici 30 mois'
                    },
                    {
                        id:2,
                        card: '#FFF' ,
                        icon: '#dad11a',
                        text: '#025578',
                        name:'Sérénité',
                        texte:'Je pense passer mon contrôle technique d\'ici 15 mois'
                    },
                    {
                        id:3,
                        card: '#FFF',
                        icon: '#f14c27',
                        text: '#025578',
                        name:'Premium',
                        texte:'Je pense passer mon contrôle technique d\'ici 10 mois'
                    }
                ]
            }
        },
        methods:{
            async paymentBCT(id){
               const {webToken}= await this.$store.dispatch('initBctPayment',{id:id,email:this.$store.getters.user.email})
                window.location.href = `https://sandbox-webkit.lemonway.fr/absgroup/dev/?moneyintoken=${webToken}`
            }
        }
    }
</script>

<style scoped>

</style>
