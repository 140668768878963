import { zonefrStore } from "./modules/Particulier/store"
import { professionalStore } from "./modules/Professional/store";

export const RootStore = {
    state: {
        applicationName: "AutoBilan-Systems",
        menu:[
            {title: "Home", icon:"mdi-home",path:"/"}
        ],
        statusClient:true,
    },
    getters:{
        appName: state => state.applicationName,
        menuContent: state => state.menu,
        statusClient : state => state.statusClient,
    },
    mutations: {
        setStatusClient(state,statusClient){
            state.statusClient= statusClient
            localStorage.setItem('statusClient', statusClient)
        },
        syncStatusClient(state) {
            state.statusClient = localStorage.getItem('statusClient') === "true" ? true:false
        }
    },
    actions: {

    },
    modules:{
        frzone: {...zonefrStore},
        proStore: {...professionalStore}
    }
};
