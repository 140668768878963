<template>
    <header class="page-head rd-navbar-wrap header_corporate">
        <nav class="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fullwidth" data-md-layout="rd-navbar-fullwidth" data-lg-layout="rd-navbar-fullwidth" data-device-layout="rd-navbar-fixed" data-sm-device-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed"
             data-lg-device-layout="rd-navbar-fullwidth" data-stick-up-offset="100px">
            <!--RD Navbar Panel-->
            <div class="rd-navbar-top-panel" :class="!$store.getters.statusClient ? 'bg-header-color-pro' : 'bg-header-color-client'">
                <div class="rd-navbar-top-panel-wrap" :class="!$store.getters.statusClient ? 'bg-header-color-pro' : 'bg-header-color-client'">
                    <div class="top-panel-inner">
                        <dl class="dl-horizontal-mod-1">
                            <dt>
                                <span style="color: white" class="material-icons-local_phone icon-xxs-mod-2 text-white"></span>
                            </dt>
                            <dd>
                                <a class="text-white" href="callto:#">05 63 73 16 97</a>
                            </dd>
                        </dl>
                        <dl class="dl-horizontal-mod-1">
                            <dt>
                                <span style="color: white" class="material-icons-drafts icon-xxs-mod-2"></span>
                            </dt>
                            <dd>
                                <a class="text-white" href="mailto:#">{{$store.getters.statusClient ?'contact@abs-group.fr': 'contact@autobilan-systems.com'}}</a>
                            </dd>
                        </dl>
                        <address>
                            <dl class="dl-horizontal-mod-1">
                                <dt>
                                    <span style="color: white" class="mdi mdi-map-marker-radius icon-xxs-mod-2"></span>
                                </dt>
                                <dd>
                                    <span style="color: white;font-size: 14px;font-weight: 400!important;">Arteparc – Bât F
                                        Route de la Côte d’Azur
                                        - Lieudit Le Canet de Meyreuil -
                                        13590 MEYREUIL
                                    </span>
                                </dd>
                            </dl>
                        </address>
                    </div>
                    <!--<v-row align="center" justify="end" v-if="!$store.getters.statusClient">
                        <v-col cols="3" class="ma-0 pa-0 mt-1 mx-1 mr-16" v-if="this.$route.name !== 'espace-centre'">

                                <v-btn class="ma-0 pa-0" v-on:click="espaceCentre"> &lt;!&ndash;v-bind:to="{name:'espace-centre'}"&ndash;&gt;
                                    <span class="button-centre pa-5" style="font-size: 10px">Espace Centre</span>
                                </v-btn>
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0 mt-1 mx-1" v-if="$store.getters.authenticateCentre">
                                <v-btn class="ma-0 pa-0"  v-on:click="deconnectionCentre"> &lt;!&ndash;v-bind:to="{name:'espace-centre'}"&ndash;&gt;
                                    <span class="button-centre pa-5" style="font-size: 10px">Déconnexion</span>
                                </v-btn>

                        </v-col>
                    </v-row>-->
                    <ul class="list-inline">
                        <li>
                          <a href="https://www.linkedin.com/company/AutoBilan-Systems/" target="_blank">
                            <v-icon size="25" style="color: white">
                              {{ icons.mdiLinkedin }}
                            </v-icon>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/AutoBilan.Systems/" target="_blank">
                            <v-icon size="25" style="color: white">
                                {{ icons.mdiFacebook }}
                            </v-icon>
                          </a>
                        </li>
                        <li>
                          <a href="">
                              <a href="https://goo.gl/maps/svyAZkSK7hZor5ED9" target="_blank">
                                <v-icon size="25" style="color: white">
                                  {{ icons.mdiGoogle }}
                                </v-icon>
                              </a>
                          </a>
                        </li>
                    </ul>
                    <div class="btn-group">
                        <a href="submit-property.html" class="btn btn-sm btn-primary">Submit Property</a>
                    </div>
                </div>
            </div>
            <div class="rd-navbar-inner inner-wrap">
                <div class="rd-navbar-panel">
                    <!-- RD Navbar Toggle-->
                    <button data-rd-navbar-toggle=".rd-navbar-nav-wrap" class="rd-navbar-toggle">
                        <span></span>
                    </button>
                    <!-- RD Navbar Brand-->
                    <div class="rd-navbar-brand">
                        <router-link :to="'/'">
                            <img v-show="$store.getters.statusClient" src="/images/ABS_Group_72ppi.png" style="width: 240px; height: 41px; padding-top: 10px" alt="">
                            <img v-show="!$store.getters.statusClient" src="/images/AUTOBILAN_logo.png" style="width: 240px; height: 41px; padding-top: 10px" alt="">
                        </router-link>
                    </div>
                </div>

                <div class="rd-navbar-nav-wrap">
                    <!-- RD Navbar Nav-->
                    <ul class="rd-navbar-nav">
                        <li v-show="$store.getters.statusClient">
                            <router-link to="/">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Prendre un rendez-vous</a>
                            </router-link>
                        </li>
                        <li v-show="!$store.getters.statusClient">
                            <router-link v-bind:to="{name:'vehiculesLegers', params: { id:null } }">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Véhicules Légers</a>
                            </router-link>
                            <ul class="rd-navbar-dropdown">
                                <li v-for="(theme, index) in VL" :key="theme.id" class="color-pro_li">
                                    <router-link v-bind:to="{name:'vehiculesLegers', params: { art: false, nameUrl:theme.nameUrl } }">
                                        <span v-add-class-hover="'hover-submenu'" class="color-pro_li">{{ theme.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>


                        <li v-show="!$store.getters.statusClient">
                            <router-link v-bind:to="{name:'poidsLourds', params: { id:null } }">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Poids Lourds</a>
                            </router-link>
                            <ul class="rd-navbar-dropdown">
                                <li v-for="(theme, index) in PL" :key="theme.id">
                                    <router-link v-bind:to="{name:'poidsLourds', params: { art: false, nameUrl:theme.nameUrl } }">
                                        <span v-add-class-hover="'hover-submenu'" class="color-pro_li">{{ theme.name }}</span>
                                    </router-link>
                                </li>
                            </ul>

                        <li v-show="$store.getters.statusClient">
                            <router-link v-add-class-hover="'hover-menu'" :to="{name:'BudgetCT'}" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Mon Budget CT</router-link>
                            <!-- RD Navbar Dropdown-->
                            <!--<ul class="rd-navbar-dropdown">
                                <li>
                                    <a href="tabs-&amp;-accordions.html">Tabs &amp; Accordions</a>
                                </li>
                                <li>
                                    <a href="typography.html">Typography</a>
                                </li>
                                <li>
                                    <a href="forms.html">Forms</a>
                                </li>
                                <li>
                                    <a href="buttons.html">Buttons</a>
                                </li>
                                <li>
                                    <a href="grid-systems.html">Grid</a>
                                </li>
                                <li>
                                    <a href="icons.html">Icons</a>
                                </li>
                                <li>
                                    <a href="table-styles.html">Tables</a>
                                </li>
                                <li>
                                    <a href="progress-bars.html">Progress bars</a>
                                </li>
                            </ul>-->
                        </li>
                        <li v-show="!$store.getters.statusClient">
                            <router-link v-bind:to="{name:'audits', params: { id:null } }">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Audit</a>
                            </router-link>
                            <ul class="rd-navbar-dropdown">
                                <li v-for="(theme, index) in Audits" :key="theme.id">
                                    <router-link v-bind:to="{name:'audits', params: { art: false, nameUrl:theme.nameUrl } }">
                                        <span v-add-class-hover="'hover-submenu'" class="color-pro_li">{{ theme.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li v-show="$store.getters.statusClient">
                            <router-link v-add-class-hover="'hover-menu'" to="/alertect" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'" >Mon Alerte CT</router-link>
                            <!--  <a href="#" style="color: var(--link-color)" >Mon Alerte CT</a>-->
                            <!-- RD Navbar Dropdown-->
                            <!--<ul class="rd-navbar-dropdown">
                                <li>
                                    <a href="footer-center-dark.html">Header Center, Footer Dark</a>
                                </li>
                                <li>
                                    <a href="header-minimal.html">Header Minimal, Footer Dark</a>
                                </li>
                                <li>
                                    <a href="header-corporate-default.html">Header Corporate Default</a>
                                </li>
                                <li>
                                    <a href="header-hamburger.html">Header Hamburger Menu</a>
                                </li>
                                <li>
                                    <a href="footer-center-dark.html">Footer Center Dark</a>
                                </li>
                                <li>
                                    <a href="footer-light.html">Footer Light</a>
                                </li>
                                <li>
                                    <a href="footer-widget.html">Footer Widget Light</a>
                                </li>
                                <li>
                                    <a href="footer-widget-dark.html">Footer Widget Dark</a>
                                </li>
                            </ul>-->
                        </li>
                        <li  v-show="!$store.getters.statusClient">
                            <router-link v-bind:to="{name:'formations', params: { id:null } }">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Formation</a>
                            </router-link>
                            <ul class="rd-navbar-dropdown">
                                <li v-for="(theme, index) in Formations" :key="theme.id">
                                    <router-link v-bind:to="{name:'formations', params: { art: false, nameUrl:theme.nameUrl } }">
                                        <span v-add-class-hover="'hover-submenu'" class="color-pro_li">{{ theme.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                       <!-- <li v-show="$store.getters.statusClient">
                            <a v-add-class-hover="'hover-menu'" href="#" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Carte Grise en Ligne</a>-->
                            <!-- RD Navbar Dropdown-->
                            <!--<ul class="rd-navbar-dropdown">
                                <li>
                                    <a href="404.html">404</a>
                                </li>
                                <li>
                                    <a href="coming-soon.html">Coming soon</a>
                                </li>
                                <li>
                                    <a href="login.html">Login page</a>
                                </li>
                                <li>
                                    <a href="maintenance.html">Maintenance page</a>
                                </li>
                                <li>
                                    <a href="terms-of-service.html">Terms of Use</a>
                                </li>
                            </ul>-->
                      <!--  </li>-->

                        <li v-show="$store.getters.statusClient">

                            <router-link v-bind:to="{name:'recruit'}">
                                <a v-add-class-hover="'hover-menu'" href="#" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Les centres recrutent</a>
                            </router-link>
                        </li>
                        <li v-show="!$store.getters.statusClient">
                            <router-link v-bind:to="{name:'informatique', params: { id:null } }">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Informatique</a>
                            </router-link>
                            <ul class="rd-navbar-dropdown" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">
                                <li v-for="(theme, index) in Informatique" :key="theme.id">
                                    <router-link v-bind:to="{name:'informatique', params: { art: false, nameUrl:theme.nameUrl } }">
                                        <span v-add-class-hover="'hover-submenu'" class="color-pro_li">{{ theme.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li v-show="!$store.getters.statusClient">
                            <router-link v-bind:to="{name:'societe', params:{id:null}}">
                                <a v-add-class-hover="'hover-menu'" href="" :class="!$store.getters.statusClient ? 'bg-text-color-pro' : 'bg-text-color-client'">Qui sommes-nous ?</a>
                            </router-link>
                            <ul class="rd-navbar-dropdown">
                                <li v-for="(theme, index) in Societe" :key="theme.id">
                                    <router-link v-bind:to="{name:'societe', params: { art: false ,nameUrl:theme.nameUrl} }">
                                        <span v-add-class-hover="'hover-submenu'" class="color-pro_li">{{ theme.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="justify-end SwapStatusclient-li">
                            <SwapStatusClient class="SwapStatusclient" @clicked="updateData"></SwapStatusClient>
                        </li>
                        <!--<li>
                            <a href="#">Pages</a>
                            &lt;!&ndash; RD Navbar Megamenu&ndash;&gt;
                            <ul class="rd-navbar-megamenu">
                                <li>
                                    <p>Pages 1</p>
                                    <ul>
                                        <li>
                                            <a href="agency-page.html">Agency Page</a>
                                        </li>
                                        <li>
                                            <a href="our-team.html">Our Team</a>
                                        </li>
                                        <li>
                                            <a href="personal-page.html">Agent Personal Page</a>
                                        </li>
                                        <li>
                                            <a href="contact-us.html">Contact Us</a>
                                        </li>
                                        <li>
                                            <a href="pricing.html">Pricing</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Pages 2</p>
                                    <ul>
                                        <li>
                                            <a href="categories.html">Categories</a>
                                        </li>
                                        <li>
                                            <a href="clients.html">Client Page</a>
                                        </li>
                                        <li>
                                            <a href="faq.html">FAQ Page</a>
                                        </li>
                                        <li>
                                            <a href="services.html">Services</a>
                                        </li>
                                        <li>
                                            <a href="services-2.html">Services 2</a>
                                        </li>
                                        <li>
                                            <a href="submit-property.html">Submit Property</a>
                                        </li>
                                        <li>
                                            <a href="make-an-appointment.html">Make an Appointment</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Blog</p>
                                    <ul>
                                        <li>
                                            <a href="2-columns-blog.html">2 Columns Blog</a>
                                        </li>
                                        <li>
                                            <a href="2-columns-blog-masonry.html">2 Columns Blog Masonry</a>
                                        </li>
                                        <li>
                                            <a href="3-columns-grid-blog.html">3 Columns Blog</a>
                                        </li>
                                        <li>
                                            <a href="3-columns-masonry-layout.html">3 Columns Blog Masonry</a>
                                        </li>
                                        <li>
                                            <a href="left-sidebar.html">Blog Left Sidebar</a>
                                        </li>
                                        <li>
                                            <a href="right-sidebar.html">Blog Right Sidebar</a>
                                        </li>
                                        <li>
                                            <a href="post-page.html">Post Page</a>
                                        </li>
                                        <li>
                                            <a href="timeline.html">Timeline</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Layouts</p>
                                    <ul>
                                        <li>
                                            <a href="header-center.html">Header Center, Footer Dark</a>
                                        </li>
                                        <li>
                                            <a href="header-minimal.html">Header Minimal, Footer Dark</a>
                                        </li>
                                        <li>
                                            <a href="header-corporate.html">Header Corporate</a>
                                        </li>
                                        <li>
                                            <a href="header-corporate-default.html">Header Corporate Default</a>
                                        </li>
                                        <li>
                                            <a href="header-hamburger.html">Header Hamburger Menu</a>
                                        </li>
                                        <li>
                                            <a href="footer-center-dark.html">Footer Center Dark</a>
                                        </li>
                                        <li>
                                            <a href="footer-light.html">Footer Light</a>
                                        </li>
                                        <li>
                                            <a href="footer-widget.html">Footer Widget Light</a>
                                        </li>
                                        <li>
                                            <a href="footer-widget-dark.html">Footer Widget Dark</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Blog</a>
                            <ul class="rd-navbar-dropdown">
                                <li>
                                    <a href="2-columns-blog.html">2 Columns Blog</a>
                                </li>
                                <li>
                                    <a href="2-columns-blog-masonry.html">2 Columns Blog Masonry</a>
                                </li>
                                <li>
                                    <a href="3-columns-grid-blog.html">3 Columns Blog</a>
                                </li>
                                <li>
                                    <a href="3-columns-masonry-layout.html">3 Columns Blog Masonry</a>
                                </li>
                                <li>
                                    <a href="left-sidebar.html">Blog Left Sidebar</a>
                                </li>
                                <li>
                                    <a href="right-sidebar.html">Blog Right Sidebar</a>
                                </li>
                                <li>
                                    <a href="post-page.html">Post Page</a>
                                </li>
                                <li>
                                    <a href="timeline.html">Timeline</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Gallery</a>
                            <ul class="rd-navbar-dropdown">
                                <li>
                                    <a href="grid-padding-gallery.html">Grid Padding Gallery</a>
                                </li>
                                <li>
                                    <a href="without-padding-grid-gallery.html">Grid Without Padding Gallery</a>
                                </li>
                                <li>
                                    <a href="masonry-grid.html">Grid Masonry</a>
                                </li>
                                <li>
                                    <a href="cobbles-grid.html">Grid Cobbles</a>
                                </li>
                            </ul>
                        </li>-->
                        <!-- <li class="link-group">
                             <SwapStatusClient></SwapStatusClient>
                         </li>-->
                        <li class="rd-navbar-bottom-panel">
                            <div class="rd-navbar-bottom-panel-wrap">
                                <!--<dl class="dl-horizontal-mod-1 login" v-if="!$store.getters.statusClient">
                                    <dt>
                                        <span class="mdi mdi-login icon-xxs-mod-2"></span>
                                    </dt>
                                    <dd>
                                        <a href="login.html" class="text-sushi">Espace Centre</a>
                                    </dd>
                                </dl>-->
                                <div class="top-panel-inner">
                                    <dl class="dl-horizontal-mod-1">
                                        <dt>
                                            <span class="icon-xxs-mod-2 material-icons-local_phone"></span>
                                        </dt>
                                        <dd>
                                            <a href="callto:#"> 05 63 73 16 97 - 05 63 59 35 42</a>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal-mod-1">
                                        <dt>
                                            <span class="material-icons-drafts icon-xxs-mod-2"></span>
                                        </dt>
                                        <dd>
                                            <a href="mailto:#"> contact@autobilan-systems.com</a>
                                        </dd>
                                    </dl>
                                    <address>
                                        <dl class="dl-horizontal-mod-1">
                                            <dt>
                                                <span class="icon-xxs-mod-2 mdi mdi-map-marker-radius"></span>
                                            </dt>
                                            <dd>
                                                <a href="#" class="inset-11">Arteparc – Bat F
                                                    Route de la Cote d’Azur
                                                    Lieudit Le Canet de Meyreuil
                                                    13590 MEYREUIL</a>
                                            </dd>
                                        </dl>
                                    </address>
                                </div>
                                <ul class="list-inline">
                                    <li>
                                        <a href="#" class="fa-facebook"></a>
                                    </li>
                                    <li>
                                        <a href="#" class="fa-twitter"></a>
                                    </li>
                                    <!-- <li>
                                         <a href="#" class="fa-pinterest-p"></a>
                                     </li>
                                     <li>
                                         <a href="#" class="fa-vimeo"></a>
                                     </li>-->
                                    <li>
                                        <a href="#" class="fa-google"></a>
                                    </li>
                                    <!--<li>
                                        <a href="#" class="fa-rss"></a>
                                    </li>-->
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <v-dialog
                            v-model="dialogLoginCentre"
                            width="500"
                    >
                        <v-card class="pa-0 ma-0">
                            <loginCentre @closeDialog="closeDialog"></loginCentre>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    import SwapStatusClient from "./SwapStatusClient";
    import { mdiFacebook, mdiLinkedin, mdiGoogle } from '@mdi/js';
    import loginCentre from '../modules/Professional/espace-centre/login-centre'
    export default {
        name: "MainHeaderMenu",
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    el.removeEventListener('mouseenter');
                    el.removeEventListener('mouseleave')
                }
            }
        },
        data(){
            return{
                statusClient:true,
                dialogLoginCentre:false,
                icons: {
                  mdiFacebook,
                  mdiLinkedin,
                  mdiGoogle
                },
                VL: [
                    {
                        id: 1,
                        nameUrl:'suivi&rachat',
                        name: "Suivi & Rachat"
                    },
                    {
                        id: 2,
                        nameUrl:'systeme-qualite',
                        name: "Système qualité"
                    },
                    {
                        id: 3,
                        nameUrl:'veille-reglementaire',
                        name: "Veille règlementaire"
                    },
                    {
                        id: 4,
                        nameUrl:'assistance',
                        name: "Assistance"
                    },
                    {
                        id: 5,
                        nameUrl:'proces-verbaux',
                        name: "Procès-verbaux"
                    },
                    {
                        id: 6,
                        nameUrl:'audits&formations',
                        name: "Audits & Formations"
                    },
                ],
                PL:
                    [
                        {
                            id: 1,
                            nameUrl:'suivi&rachat',
                            name: "Suivi & Rachat"
                        },
                        {
                            id: 2,
                            nameUrl:'systeme-qualite',
                            name: "Système qualité"
                        },
                        {
                            id: 3,
                            nameUrl:'veille-reglementaire',
                            name: "Veille règlementaire"
                        },
                        {
                            id: 4,
                            nameUrl:'assistance',
                            name: "Assistance"
                        },
                        {
                            id: 5,
                            nameUrl:'proces-verbaux',
                            name: "Procès-verbaux"
                        },
                        {
                            id: 6,
                            nameUrl:'audits&formations',
                            name: "Audits & Formations"
                        },
                    ],
                Informatique:
                    [
                        {
                            id: 1,
                            nameUrl:'mercure-vl',
                            name: "Mercure VL"
                        },
                        {
                            id: 2,
                            nameUrl:'mercure-pl',
                            name: "Mercure PL"
                        },
                        {
                            id: 3,
                            nameUrl:'mercure-rdv',
                            name: "Mercure RDV"
                        },
                        {
                            id: 4,
                            nameUrl:'mercure-web',
                            name: "Mercure Web"
                        },
                        {
                            id: 5,
                            nameUrl:'mercure-qualité',
                            name: "Mercure Qualité"
                        },
                        {
                            id: 6,
                            nameUrl:'mercure-access',
                            name: "Mercure Access"
                        },
                    ],
                Societe:
                    [
                        {
                            id: 1,
                            nameUrl:'savoir-faire',
                            name: "Notre savoir-faire"
                        },
                        {
                            id: 2,
                            nameUrl:'controle-technique',
                            name: "Le contrôle technique"
                        },
                        {
                            id: 3,
                            nameUrl:'actualite',
                            name: "Actualités"
                        },
                        {
                            id: 4,
                            nameUrl:'partenaires',
                            name: "Partenaires"
                        }
                    ],
                Formations:
                    [
                        {
                            id: 0,
                            nameUrl:'maintien-qualification-controleurs',
                            name: "Maintien de qualification contrôleurs"
                        },
                        {
                            id: 1,
                            nameUrl:'remise-a-niveau-controleurs',
                            name: "Remise à niveau contrôleurs"
                        },
                        {
                            id: 2,
                            nameUrl:'qualification-controle-gpl',
                            name: "Qualification pour le contrôle des véhicules GPL"
                        },
                        {
                            id: 3,
                            nameUrl:'habilitation-B2XL',
                            name: "Risques électriques pour habilitation B2XL"
                        },
                        {
                            id: 4,
                            nameUrl:'exploitants-centres',
                            name: "Exploitants centres"
                        },
                        {
                            id: 5,
                            nameUrl:'formation-initiale-controleur',
                            name: "Formation initiale contrôleur"
                        },
                        {
                            id: 6,
                            nameUrl:'formation-levage',
                            name: "Formation levage"
                        },
                    ],
                Audits:
                    [
                        {
                            id: 1,
                            nameUrl:'audits-initiaux',
                            name: "Audits initiaux centres VL & PL"
                        },
                        {
                            id: 2,
                            nameUrl:'audits-reglementaires',
                            name: "Audits règlementaires centres VL & PL"
                        },
                        {
                            id: 3,
                            nameUrl:'audits-controleurs',
                            name: "Audits règlementaires contrôleurs VL & PL"
                        },
                        {
                            id: 4,
                            nameUrl:'audits-internes',
                            name: "Audits internes"
                        },
                    ],
            }
        },
        components:{
            SwapStatusClient,
            loginCentre
        },
        methods:{
            updateData(data){
                this.statusClient = data
                this.$store.commit("setStatusClient", data)
            },
            espaceCentre(){
                if(this.$store.getters.authenticateCentre){
                    if(this.$route.name !== 'espace-centre')
                        this.$router.push({name:'espace-centre'})
                    this.dialogLoginCentre = false
                }else
                    this.dialogLoginCentre = true
            },
            closeDialog(){
                this.dialogLoginCentre= false
            },
            deconnectionCentre(){
                this.$store.commit('deconnectCentre')
                this.$router.push({name:'Pro'})
            }
        },
    }
</script>

<style scoped>


    @media (min-width: 1800px) {
        .SwapStatusclient-li{
            display: block;
            display: inline-block;
            position: absolute;
        }
        .SwapStatusclient{
            width: 50px;
        }
    }

    @media (min-width: 768px) and (max-width: 1799px) {
        .rd-navbar-nav-wrap{
            padding: 0 0 0 0;
            display: block;

        }
        .rd-navbar-fullwidth .rd-navbar-inner {
            padding: 0 0 0 0;
        }
        .rd-navbar-nav>li>a {
            font-size: 13px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }

    }
    @media (min-width: 768px) and (max-width: 1125px) {
        .rd-navbar-nav>li>a {
            font-size: 11px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }
    }

    @media (min-width: 768px) and (max-width: 1010px) {
        .rd-navbar-nav>li>a {
            font-size: 9px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }
    }


    @media (min-width: 768px) and (max-width: 900px) {
        .rd-navbar-nav>li>a {
            font-size: 7px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }
    }

.text-white{
    color: white;
}

.hover-menu{
    text-decoration: underline;
    text-decoration-color: var(--link-color);
}
    .bg-header-color-pro{
        background-color: #89B951!important;
    }
    .bg-header-color-client{
        background-color: #025578!important;
    }
    .bg-text-color-client{
        color: #025578!important;
    }
    .bg-text-color-pro{
        color: #89B951!important;
    }
    .color-pro_li{
        color:black!important;
    }
    .hover-submenu{
        color: #F2811D!important;
    }
    .button-centre{
        font-size: 12px;
        font-weight: 200px;
        letter-spacing: 0.5px;
        color: #89B951!important;
    }
</style>
