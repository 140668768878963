<template>
  <v-row justify="center" class="mt-3">
    <v-col class="text-center" cols="12" md="4" sm="12" lg="3">
      <img style="height: auto; width: auto" :src="theme.img" alt="test">
    </v-col>
    <v-col cols="10" sm="10" md="12" lg="6" class="ml-5">
      <v-row>
        <v-col class="col-12" :class="$vuetify.breakpoint.lgOnly ? 'ml-4' : ''">
          <v-row >
            <p class="text-title my-2"v-if="theme.titreArticle ==undefined">{{ theme.titre }}</p>
            <p class="text-title my-2"v-if="theme.titreArticle !=undefined">{{ theme.titreArticle }}</p>

          </v-row>
          <hr class="ma-0"/>
          <v-row class="mt-1">
            <p class="text-style text-justify" v-html="theme.texte"></p>
            <div class="col-6 buttonDesc" style="text-align: center" v-for="button in theme.buttons" :key="button.id">
              <a :href="button.buttonLink" target="_blank" class="btn btn-sushi btn-sm btn-min-width-sm btn-shape-3">{{ button.buttonText }}</a>
              <div  v-if="typeof button.buttonDesc ==='object'" class="py-4">
                <p v-for="description in button.buttonDesc" class="py-0 my-0">
                  {{description}}
                </p>

              </div>
                <div v-else class="py-4">
                    <p>{{ button.buttonDesc }}</p>
                </div>
            </div>
          </v-row>
            <v-row align="center" justify="end" v-if="theme.logoQualiopiRight">
                <v-col cols="2">
                    <a :href="theme.linkFormation" target="_blank" class="btn btn-sushi btn-sm btn-min-width-sm btn-shape-3">Formations</a>
                </v-col>
                <v-col cols="10">
                    <v-row justify="end">
                        <v-col cols="8" md="5">
                            <v-img src="/images/LogoQualiop.jpg" ></v-img>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
          <v-row v-if="theme.logoQualiopi===true">
              <v-col cols="2">
                  <v-icon size="60" style="color: #89B951">
                      {{ mdiChartAreaspline }}
                  </v-icon>
              </v-col>
              <v-col cols="10">
                  <span class="text-style font-weight-bold">{{theme.textHighLight}}</span>
              </v-col>

          </v-row>
          <v-row v-if="theme.textList!==undefined">
            <ul class="list-text">
              <li style="list-style-position: outside;" v-for="line in theme.textList"><span class="list-text text-style text-justify" v-html="line">{{line}}</span></li>
            </ul>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!--<v-sheet class="mt-10" v-if="testTheme(theme)">
      <v-row class="row flow-offset-1 clearleft-custom-2">
        <v-col v-for="clef in theme.clefs" :key="clef.id" class="col-xs-12 col-sm-6">
          <div class="media media-mod-1 inset-4">
            <div class="media-left">
              <span class="bg-primary" :class="clef.icon"></span>
            </div>
            <div class="media-body">
              <p class="text-title">{{clef.title}}</p>
              <p class="text-justify text-style">{{clef.fact}}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>-->
  </v-row>
</template>

<script>
    import { mdiChartAreaspline } from '@mdi/js';
export default {
  name: "Info",
  props: ["theme"],
  methods: {
    testTheme(value) {
      if (value.clefs)
        return true
      else
        return false
    }
  },
    data(){
      return {
          mdiChartAreaspline : mdiChartAreaspline
      }
    }
};
</script>

<style scoped>
p
{
  font-size: 16px;
  text-align: justify;
  color: #8f8f8f;
}
.btn
{
  color: white;
}
.buttonDesc
{
  font-size: 24px;
  text-align: justify;
}

.text-style{
  font-family: Lato, sans-serif;
  color: #8f8f8f;
  font-size: 17px!important;
}
.texthl-style{
  font-family: Lato, sans-serif;
  color: #8f8f8f;
  font-size: 18px!important;
  font-weight: 900;
}
.text-title{
  font-family: Lato, sans-serif!important;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0.78px;
  text-decoration-color: rgb(70,71,74);
}
.text-main-title{
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-decoration-color: rgb(70,71,74);
}
.list-text{
  list-style: inside!important;
}
</style>

