<template>
    <div class="pa-0 ma-0" ref="">
        <v-col cols="12">
            <v-form ref="form" v-model="valid" class="" lazy-validation>
                <v-row justify="center">
                    <v-col class="col-12">
                        <v-card elevation="0">
                            <v-card-title >
                                <v-row class="pa-0 ma-0 mt-3">
                                    <v-col class="col-12 pa-0 ma-0">
                                        <span class="ma-0 pa-0 rounded">Vos Informations personnelles</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-row class="justify-center">
                                <v-col class="col-8">
                                    <v-row justify="start">
                                    <!--                                  Selection genre/société-->
                                    <v-radio-group row class="justify-center" v-model="civility" :rules="basicRules" v-on:change="resetNames">
                                        <v-row class="justify-start" align="center">

                                            <v-col cols="3" class="ml-4">
                                                <v-radio label="Madame" value="Madame"></v-radio>
                                            </v-col>

                                            <v-col cols="3" class="ml-4">
                                                <v-radio label="Monsieur" value="Monsieur"></v-radio>
                                            </v-col>
                                            <v-col cols="4" class="mx-0 px-0 ml-7" >
                                                <v-radio class="" label="Autre : société, association..." value="Autre"></v-radio>
                                            </v-col>

                                        </v-row>
                                    </v-radio-group>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="justify-center">
                                <v-col class="col-8">
                                    <v-row justify="start">
                                        <v-col
                                                cols="3"
                                                md="5"
                                                v-if="civility!=='Autre'"
                                        >
                                            <v-text-field v-model="lastName" :rules="nameRules" label="Nom" required></v-text-field>
                                        </v-col>

                                        <v-col
                                                cols="12"
                                                md="5"
                                                v-if="civility!=='Autre'"
                                        >
                                            <v-text-field v-model="firstName" :rules="prenomRules" label="Prénom" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="civility==='Autre'" cols="3" md="5">
                                            <v-text-field v-model="denomination" :rules="denominationRules" label="Dénomination" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="start">
                                        <v-col
                                                cols="12"
                                                md="5"
                                        >
                                            <v-text-field
                                                    v-model="email"
                                                    :rules="emailRules"
                                                    label="E-mail"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                md="5"
                                        >
                                            <v-text-field v-model="phone" :rules="phoneRules" label="Téléphone mobile" required></v-text-field>

                                        </v-col>
                                    </v-row>
                                    <v-row justify="start">
                                        <v-col
                                                cols="12"
                                                md="5"
                                        >
                                            <v-text-field
                                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show3 ? 'text' : 'password'"
                                                    label="Mot de Passe"
                                                    :rules="passwordRules"
                                                    v-model="password"
                                                    class="input-group--focused"
                                                    @click:append="show3 = !show3"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                md="5"
                                        >
                                            <v-text-field
                                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show3 ? 'text' : 'password'"
                                                    label="Confirmer mot de passe"
                                                    v-model="passwordConfirmation"
                                                    :rules="passwordConfirmationRules"
                                                    :disabled="password.length===0"
                                                    class="input-group--focused"
                                                    @click:append="show3 = !show3"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col class="col-12">
                        <v-card class="ma-0 pa-0" elevation="0">
                            <v-card-title class="">
                                <v-row class="pa-0 ma-0 mt-3">
                                    <v-col class="col-12 pa-0 ma-0">
                                        <span class="ma-0 pa-0 rounded">Votre Adresse</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-row class="justify-center">
                                <v-col class="col-8">
                                    <v-row justify="start">
                                        <v-col
                                                cols="3"
                                                md="4"
                                        >
                                            <v-text-field
                                                    label="Numéro voie"
                                                    v-model="numVoie"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                                cols="12"
                                                md="6"
                                        >
                                            <v-text-field
                                                    label="Libellé voie"
                                                    v-model="libelleVoie"
                                                    :rules="libelleVoieRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                                cols="10"

                                        >
                                            <v-text-field
                                                    label="Complément d'adresse"
                                                    v-model="complementAdresse"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="start">
                                        <v-col
                                                cols="12"
                                                md="5"
                                        >
                                            <v-text-field
                                                    v-model="codepostal"
                                                    label="Code Postal"
                                                    :rules="codepostalRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                md="5"
                                        >
                                            <v-text-field
                                                    v-model="ville"
                                                    label="Ville"
                                                    :rules="villeRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center"  v-if="vehicules.length > 0">
                    <v-col class="col-12">
                            <v-card-title class="pa-0 ma-0">
                                <v-row class="pa-0 ma-0">
                                    <v-col class="col-12 ">
                                        <span class="rounded">Vos Vehicules</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="vehicules.length > 0" v-for="(elem,index) in vehicules" v-bind:key="index">
                    <v-col class="col-12">
                        <v-card class="pa-4">
                            <v-card-title class="pa-0 ma-0 ml-4">
                                <v-row class="pa-0 ma-0">
                                    <v-col class="col-2 pa-0 ma-0">
                                        <span class="ma-0 pa-0 rounded">Vehicule {{index+1}}</span>
                                    </v-col>
                                    <v-col class="col-10 pa-0 ma-0">
                                        <v-row class="justify-end" align="end">
                                            <v-btn
                                                    depressed
                                                    color="error"
                                                    v-on:click="deleteVehicule(elem,index)"
                                            >
                                                Supprimer
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-row align="center" class="justify-start pa-0 ma-0 ml-2">
                                <v-col class="8 pa-0">
                                    <v-radio-group v-model="elem.immatriculationOrigin" v-on:change="checkRadioImma(elem)" mandatory>
                                        <v-row justify="start">
                                            <v-col class="col-sm-6 ma-0 pb-0">
                                                <v-radio label="Immatriculation française" value="true"></v-radio>
                                            </v-col>
                                            <v-col class="col-sm-6 ma-0 pb-0">
                                                <v-radio label="Immatriculation étrangère" value="false"></v-radio>
                                            </v-col>
                                        </v-row>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="4" class="pa-0 ma-0">
                                    <v-menu bottom offset-y rounded="2">
                                        <template v-slot:activator="{ on, attr }">
                                            <v-btn v-on="on" icon class="ma-2" v-bind="attr">
                                                <v-icon>
                                                    {{ help }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="pa-0 ma-0">
                                            <v-card class="pa-3">
                                                <p class="text-body pa-0 ma-0">
                                                    Pour obtention d’une carte grise française.
                                                </p>
                                            </v-card>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>


                            <!--                          Infos Vehicule-->
                            <v-row align="center" class="justify-start ma-0 pt-3">

                                <!--                              Immatriculation-->
                                <v-col cols="12" sm="12" md="4" class="ma-0 pt-0 pb-0">
                                    <v-text-field class="ma-0 pt-2 pb-0" v-model="elem.immatriculation" v-on:keyup="editImmatriculation(elem)"  label="Immatriculation" :rules="elem.immatriculationOrigin === 'true' ? immatriculationFrRules : immatriculationForeignRules" required></v-text-field>
                                </v-col>

                                <!--                              Marque-->
                                <v-col class="ma-0 pt-0 pb-0" cols="6" sm="6" md="4">
                                    <v-autocomplete class="ma-0 pt-2 pb-0"  v-model="elem.vehiculeMarque" :rules="basicRules"  :items="$store.getters.vehicules" item-text="ma_lib" placeholder="Marque Véhicule" required return-object></v-autocomplete>
                                </v-col>

                                <!--                              Modèle-->
                                <v-col class="ma-0 pt-0 pb-0" cols="6" sm="6" md="4">
                                    <v-autocomplete class="ma-0 pt-2 pb-0"  v-model="elem.modeleVehicule" :rules="basicRules" :items="getModeles(elem)"  item-text="movl_lib_modele" placeholder="Modèle Véhicule" required></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="end" class="ml-2">
                    <!--<v-col class="col-6">
                        <v-row justify="start"class="my-2 mr-2">
                            <v-btn
                                    depressed
                                    color="primary"
                                    v-on:click="addVehicule"
                            >
                                Ajouter un véhicule
                            </v-btn>
                        </v-row>
                    </v-col>-->
                    <v-col class="col-6">
                        <v-row justify="end"class="my-2 mr-2">
                            <b-button
                                    style="color:white"
                                    v-on:click="validate"
                                    class="btn btn-sushi btn-sm mr-2 rounded-pill"
                            >
                                Valider
                            </b-button>
                        </v-row>
                    </v-col>
                </v-row>
                <!--<v-row justify="center" v-if="alertNbVehicule">
                    <v-col cols="6">
                        <v-alert
                                dense
                                outlined
                                type="error"
                        >
                            Vous devez ajouter au moins un véhicule
                        </v-alert>
                    </v-col>
                </v-row>-->
                <v-row justify="center">
                    <v-col class="col-6" v-if="returnMessage">
                        <v-alert
                                dense
                                text
                                type="success"
                        >
                            Un email de confirmation vous a été envoyé
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col class="col-6" v-if="!returnMessage && returnMessage!=null">
                        <v-alert
                                dense
                                outlined
                                type="error"
                        >
                            Cet email est déjà utilisé
                        </v-alert>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </div>
</template>

<script>
    import { mdiHelpCircle } from '@mdi/js'
    export default {
        name: "FormRegisterComponent",
        components: {
        },
        data(){
            return{
                denomination:'',
                help:mdiHelpCircle,
                returnMessage:null,
                civility:'Monsieur',
                firstName:'',
                lastName:'',
                email:'',
                numVoie:'',
                libelleVoie:'',
                complementAdresse:'',
                phone:'',
                codepostal:'',
                ville:'',
                vehicules:[],
                nbVehicules:0,
                valid: false,
                denominationRules: [
                    v => !!v || 'Saisir votre dénomination ',
                    v => /^([A-ZÉÈÊ ]{2,}$|^[A-ZÉÈÊ ]{2,}-[A-ZÉÈÊ ]{1,}$)/.test(v) || 'Dénomination non valide'

                ],
                nameRules: [
                    v => !!v || 'Name is required',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ],
                show1: false,
                show2: true,
                show3: false,
                show4: false,
                password: '',
                passwordConfirmation:'',
                basicRules: [v => !!v || 'Champs vide'],
                passwordRules:[
                    v => !!v || 'Saisir votre mot de passe',
                    v => /^[A-ZÉÈÊa-zéèê~"#'{(-|`_ç^à@)°=}+^¨$£%ùµ*?,;.:!§]{8,}$/.test(v) || '8 caractères nécessaires'
                ],
                passwordConfirmationRules:[
                    v => !!v || 'Saisir votre confirmation de mot de passe',
                    v=> v == this.password || 'Ne correspond pas au mot de passe ',
                    v => /^[A-ZÉÈÊa-zéèê~"#'{(-|`_ç^à@)°=}+^¨$£%ùµ*?,;.:!§]{8,}$/.test(v) || 'Confirmation mot de passe non valide'
                ],
                immatriculationFrRules:[
                    v => !!v || 'Une immatriculation est nécessaire',
                    v => /^\d{3,4} [A-Z]{2,3} \d{2,3}$/.test(v) || /^[A-Z]{2}-\d{3,4}-[A-Z]{2}$/.test(v) || 'Format-SIV : AA-999-AA Format-FNI : 999 AAA 999 ou 9999 AA 999',
                ],
                immatriculationForeignRules:[
                    v => !!v || 'Une immatriculation est nécessaire',
                ],
                prenomRules:[
                    v => !!v || 'Saisir votre prénom',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || 'Prénom non valide'

                ],
                codepostalRules:[
                    v => !!v || 'Saisir votre Code Postal',
                    v => /^[0-9]{5}$/.test(v) && v.length <=5 || 'Code Postal non valide'

                ],
                numVoieRules:[
                    v => !!v || 'Saisie le numéro de rue,avenue...',
                    v => /^[0-9]{1,4}[ ||A-Za-z ]*$/.test(v) || /^[0-9]{1,4} [A-Za-z]+$/.test(v) || 'Numéro voie non valide'
                ],
                libelleVoieRules:[
                    v => !!v || 'Saisir votre avenue,rue,boulevard...',
                    v => /^^[(A-ZÉÈÊa-zéèê) || \s]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+-[A-ZÉÈÊÔ\']{1}[a-zéèêô]+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+-[A-ZÉÈÊÔ\']{1}[a-zéèêô]+-[A-ZÉÈÊÔ]{1}[a-zéèê]+$/.test(v)
                        || / [A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+$/.test(v)
                        || /-[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+$/.test(v)
                        || /^[A-ZÉÈÊa-zéèêô\']{1}[a-zéèêô\']+ [A-ZÉÈÊÔ\']{1}[a-zéèêô\']+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+ [A-ZÉÈÊÔ\']{1}[\'a-zéèêô]+$/.test(v)
                        || 'Libellé voie non valide'
                ],
                villeRules:[
                    v => !!v || 'Saisir votre ville',
                    v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || / [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || /^[A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+ [A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v)
                        || 'Ville non valide'

                ],
                emailRules: [
                    v => !!v || 'Un E-mail est nécessaire',
                    v => /.+@.+\..+/.test(v) || 'E-mail non valide',
                ],
                nameRules: [
                    v => !!v || 'Saisir votre nom',
                    v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

                ],
                phoneRules: [
                    v => !!v || 'Un téléphone est nécessaire',
                    v => /^0[67]\d{8}$/.test(v) && v.length <=10|| 'Mobile non valide',
                ],//[0-9] = \d
                alertNbVehicule:false
            }
        },
        methods:{
            resetValue(){
              this.denomination = ''
              this.civility = ''
              this.firstName = ''
              this.lastName = ''
              this.email = ''
              this.numVoie = ''
              this.libelleVoie = ''
              this.complementAdresse = ''
              this.phone = ''
              this.codepostal = ''
              this.ville = ''
              this.vehicules=[]
              this.nbVehicules=0
              this.returnMessage=null
              this.valid= false
            },
            resetNames(){
                this.firstName = ''
                this.lastName = ''
                this.denomination = ''

            },
            addVehicule(){
                this.nbVehicules +=1
                const vehicule = {
                    immatriculationOrigin:true,
                    immatriculation:'',
                    vehiculeMarque:'',
                    modeleVehicule:''
                }
               this.vehicules = [...this.vehicules,vehicule]
            },
            deleteVehicule(elem,index){
                this.vehicules = [...this.vehicules.slice(0,index),...this.vehicules.slice(index+1)]
            },
            checkRadioImma(elem){
                elem.immatriculation=''
            },
            getModeles(elem){
                return elem.vehiculeMarque ? elem.vehiculeMarque.modeles : []
            },
            editImmatriculation(elem){
                if(/[a-z]/.test(elem.immatriculation))
                    elem.immatriculation = elem.immatriculation.toString().toUpperCase()
                if(elem.immatriculationOrigin === "true"){
                    if(/^[A-Z]{2}$/.test(elem.immatriculation)){
                        elem.immatriculation = elem.immatriculation.concat('-')
                    }
                    if(/^[A-Z]{2}-\d{3}$/.test(elem.immatriculation))
                        elem.immatriculation = elem.immatriculation.concat('-')

                    if(/^\d{4}$/.test(elem.immatriculation))
                        elem.immatriculation = elem.immatriculation.concat(' ')

                    if(/^\d{3,4} [A-Z]{2}$/.test(elem.immatriculation))
                        elem.immatriculation = elem.immatriculation.concat(' ')
                }

            },
            async validate(){
                this.$refs.form.validate()
               /* if(this.$refs.form.validate() && this.nbVehicules === 0){
                    this.alertNbVehicule= true
                    setTimeout(()=>{
                        this.alertNbVehicule = false
                    },3000)
                }*/

                if(this.$refs.form.validate()) {
                    const object = {
                        civility: this.civility,
                        lastName: this.lastName,
                        firstName: this.firstName,
                        email: this.email,
                        phone: this.phone,
                        password: this.password,
                        adresse: {
                            numVoie: this.numVoie,
                            libelleVoie: this.libelleVoie,
                            codePostal: this.codepostal,
                            ville: this.ville,
                            complement_adresse : this.complementAdresse
                        }

                    }
                    const result = await this.$store.dispatch("reg", object)

                    if(result===false){
                        setTimeout(()=>{
                            this.returnMessage = false
                        },2000)
                        this.returnMessage=null
                    }
                    if(result===true){
                        this.returnMessage = result
                        this.$emit('closeDialogRegister')
                    }


                }
            }
        },
        mounted() {
            this.$store.dispatch('getVehicules')
        },
        watch:{
            'denomination':{
                handler(value){
                    this.denomination = value.toString().toUpperCase()
                }
            },
            'password':{
                handler(){
                    this.passwordConfirmation=''
                }
            },
            'libelleVoie':{
                handler(value){
                    if(/^[A-ZÉÈÊa-zéèê]{1}$/.test(value)) {
                        const char = value.substr(value.length - 1).toUpperCase()
                        this.libelleVoie = value.substr(0, value.length - 1).concat(char)
                    }
                    if(/^[(A-Za-zÉÈÊéèê)||\s]+[A-Z]$/.test(value)){
                        const char = value.substr(value.length - 1).toLowerCase()
                        this.libelleVoie = value.substr(0, value.length - 1).concat(char)
                    }
                }
            },
            deep:true,
            'vehicules':{
                handler(value){
                    if(this.radiosImmatriculation === "true"){
                        if(/^[A-Z]{2}$/.test(value))
                            this.immatriculation = value.concat('-')

                        if(/^[A-Z]{2}-\d{3}$/.test(value))
                            this.immatriculation = value.concat('-')

                        if(/^\d{4}$/.test(value))
                            this.immatriculation = value.concat(' ')

                        if(/^\d{3,4} [A-Z]{2}$/.test(value))
                            this.immatriculation = value.concat(' ')
                    }
                    if(/[a-z]/.test(value))
                        this.immatriculation = value.toString().toUpperCase()
                }
            },
            'firstName':{
                handler(value){
                    if(/^[a-zéèê]{1}$/.test(value)){
                        this.firstName=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.firstName = value.substr(0,value.length-1).concat(char)
                    }

                }
            },
            'ville':{
                handler(value){
                    if(/^[a-zéèê]{1}$/.test(value)){
                        this.ville=value.toString().toUpperCase()
                    }
                    if(/^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊa-zéèê]{1}$/.test(value)
                        || / [A-ZÉÈÊa-zéèê]{1}$/.test(value)
                        || /-[A-ZÉÈÊa-zéèê]{1}$/.test(value)){
                        const char = value.substr(value.length-1).toUpperCase()
                        this.ville = value.substr(0,value.length-1).concat(char)
                    }

                    if(/((?<=^[A-Z]{1})[a-zA-Z]{1,}$)|((?<=\1-[A-Z]{1})[A-Za-z]{1,}$)/.test(value)){
                        const char = value.substr(value.length-1).toLowerCase()
                        this.ville = value.substr(0,value.length-1).concat(char)
                    }

                }
            },
            'lastName':{
                handler(value){
                    this.lastName = value.toString().toUpperCase()
                }
            },
            '$store.getters.registerCounter':{
                handler(value){
                   this.resetValue()
                }
            },
        }
    }
</script>

<style scoped>

</style>
