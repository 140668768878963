<template>
    <v-dialog :value="dialogFormReservation" max-width="1300" id="time-slot" persistent class="theme--light pa-0 ma-0">
        <v-card style="overflow-x: hidden;overflow-y: hidden;" class="ma-0 pa-0" v-if="$store.getters.dateSelected!==null">
            <v-form ref="form" v-model="valid" class="form-variant-1 ma-0 pa-0" lazy-validation>

                <!--              toolbar-->
                <v-toolbar color="var(--btn-color)" dark>
                    <v-row justify="start" align="center">
                        <v-col class="col-2">
                            <v-btn
                                class="ma-1 green rounded-pill"
                                color="white"
                                plain
                                @click="retourFormReservation"
                            >
                                Retour
                            </v-btn>
                        </v-col>
                        <v-col class="col-8 ma-0 pa-0">
                            <v-row justify="center" class="ma-0 pa-0">
                                <span style="font-size: 1.5rem">Vos informations</span>
                            </v-row>
                        </v-col>
                        <v-col cols="2">
                            <v-row justify="end" align="center">
                                <v-btn
                                        icon
                                        dark
                                        @click="closeDialogFormReservation"


                                >
                                    <v-icon color="white">mdi-close</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row class="" justify="center">
                    <v-col cols="3" class="elevation-10 my-5 mr-2" style="background-color:var(--btn-color)">
                        <v-row justify="start" class="pl-2 pt-2">
                            <span class="text-main-title">Récapitulatif rendez-vous</span>
                        </v-row>

<!--                        place-->

                        <v-row align="center" justify="start" class="pl-2 pt-2">
                            <v-col cols="4">
                                <v-icon style="color: white" size="60">
                                    {{icons.city}}
                                </v-icon>
                            </v-col>
                            <v-col cols="8">
                                <v-row justify="start" class="pt-2">
                                    <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{$store.getters.centreSelected.ctr_nom}}</span>
                                </v-row>
                                <v-row justify="start" class="pt-2">
                                    <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{$store.getters.centreSelected.adr_rue}}</span>
                                </v-row>
                                <v-row justify="start" class="pt-2" v-if="$store.getters.centreSelected.adr_complement">
                                    <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{$store.getters.centreSelected.adr_complement}}</span>
                                </v-row>
                                <v-row justify="start" class="pt-2">
                                    <span class="text-style ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{$store.getters.centreSelected.adr_code_postal}} {{$store.getters.centreSelected.adr_ville}}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="start" class="pl-2 pt-2">
                            <v-col cols="4">
                                <v-icon size="60" style="color: white">
                                    {{icons.car}}
                                </v-icon>
                            </v-col>
                            <v-col cols="8">
                                <v-row justify="start" class="pt-2">
                                    <span class="text-style ml-1" style="text-transform: uppercase;font-weight: bold">{{$store.getters.currentSearch.typeVehiculeChoice}} </span>
                                </v-row>
                                <v-row justify="start" class="pt-2">
                                    <span class="text-style ml-1" style="text-transform: uppercase;font-weight: bold">{{$store.getters.currentSearch.moteur}}</span>
                                </v-row>
                            </v-col>
                        </v-row>

<!--                        date-->

                        <v-row justify="start" align="center" class="pl-2 pt-2">
                            <v-col cols="4" class="pr-0 mr-0">
                                <v-icon style="color: white" size="60">
                                    {{icons.calendar}}
                                </v-icon>
                            </v-col>
                            <v-col cols="8">
                                <v-row justify="start" class="pt-2" v-if="$store.getters.dateHoraireSelected">
                                    <span class="text-style ml-1" style="text-align: center;font-weight: bold">{{ dayOfWeeks[$store.getters.dateSelected.date.getDay()] }} {{$store.getters.dateSelected.date.getDate()}} {{months[$store.getters.dateSelected.date.getMonth()]}} {{$store.getters.dateSelected.date.getFullYear()}} à {{formatTimeSlot($store.getters.dateHoraireSelected.debut)}}</span>
                                </v-row>
                            </v-col>
                        </v-row>

<!--                        price-->

                        <!--<v-row align="center" justify="start" class="pl-2 pt-2">
                            <v-col cols="12">
                                <v-row class="tableau" justify="space-between">
                                    <v-col cols="8">
                                            <span class="text-style-1" >Tarif initial:</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row class="pa-0 ma-0" justify="end">
                                            <span class="text-style-1">{{$store.getters.finalPrice.prixInitial}}€</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="tableau" justify="space-between">
                                    <v-col cols="8">
                                        <span class="text-style-1" >Réduction:</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row class="pa-0 ma-0" justify="end">
                                            <span class="text-style-1">{{ $store.getters.finalPrice.promo.item.pourcentage === 0 ? $store.getters.finalPrice.promo.item.montant+"€" : $store.getters.finalPrice.promo.item.pourcentage+"%" }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="tableau" justify="space-between">
                                    <v-col cols="8">
                                        <span class="text-style-1" >Frais de services:</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row class="pa-0 ma-0" justify="end">
                                            <span class="text-style-1">1.99€</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="tableau" justify="space-between">
                                    <v-col cols="8">
                                        <span style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;color: white;" class="text-style-1" >Total TTC:</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row class="pa-0 ma-0" justify="end">
                                            <span style="font-weight: bolder !important; font-size: 18px!important; font-family: Lato, sans-serif;color: white;">{{calculatePrice()}}€</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>-->
                        <payment-detail :bct="false" :cvc="cvc" class="ml-2"></payment-detail>
                    </v-col>
                    <v-col cols="8" class="elevation-10 my-5 ml-2">
                        <!--              Info Vehicule-->
                        <v-card-text class="mt-0 pt-0" elevation="4">

                            <v-card-title class="pa-0 ma-0 ml-4 mb-3 ">
                                <v-row class="pa-0 ma-0">
                                    <v-col class="col-12 pa-0 ma-0">
                                        <span class="ma-0 pa-0" style="text-decoration:underline;text-underline-offset: 0.2em;">Informations véhicule</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>


                            <v-row justify="center">
                                <v-col class="col-8 pa-0 ma-0">


                                    <!--                          Selection fr ou autres-->
                                    <v-row align="center" class="justify-start pa-0 ma-0 ml-2">
                                        <v-col class="col-12 pa-0">
                                            <v-radio-group v-model="radiosImmatriculation" v-on:change="checkRadioImma" :rules="basicRules" mandatory>
                                                <v-row justify="start">
                                                    <v-col class="col-sm-6 ma-0 pb-0">
                                                        <v-radio label="Immatriculation française" value="true"></v-radio>
                                                    </v-col>
                                                    <v-col class="col-sm-6 ma-0 pb-0">
                                                        <v-radio label="Immatriculation étrangère" value="false"></v-radio>
                                                    </v-col>
                                                </v-row>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>


                                    <!--                          Infos Vehicule-->
                                    <v-row align="center" class="justify-start ma-0 pt-3">

                                        <!--                              Immatriculation-->
                                        <v-col class="ma-0 pt-0 pb-0" cols="6" sm="6" md="12">
                                            <v-text-field class="ma-0 pt-2 pb-0" v-model="immatriculation" label="Immatriculation" :rules="radiosImmatriculation === 'true' ? immatriculationFrRules : immatriculationForeignRules" required></v-text-field>
                                        </v-col>
                                        <!-- <v-col class="ma-0 pt-0 pb-0" cols="6" sm="6" md="6">
                                             <v-autocomplete class="ma-0 pt-2 pb-0" v-model="energie" :rules="basicRules" :items="energies" item-text="ma_lib" placeholder="Energie véhicule" required return-object></v-autocomplete>
                                         </v-col>-->



                                    </v-row>
                                    <v-row align="center" class="justify-start ma-0 pt-3">

                                        <v-col class="ma-0 pt-0 pb-0" cols="6" sm="6" md="6">
                                            <v-autocomplete class="ma-0 pt-2 pb-0" v-model="vehiculeMarque" :rules="basicRules"  :items="$store.getters.vehicules" item-text="ma_lib" placeholder="Marque Véhicule" required return-object></v-autocomplete>
                                        </v-col>


                                        <v-col class="ma-0 pt-0 pb-0" cols="6" sm="6" md="6">
                                            <v-autocomplete class="ma-0 pt-2 pb-0" v-model="modeleVehicule" :rules="basicRules" :items="getModeles()"  item-text="movl_lib_modele" placeholder="Modèle Véhicule" required></v-autocomplete>
                                        </v-col>

                                    </v-row>


                                    <v-row align="center" class="justify-start pa-0 ma-0 mt-2 pt-1">

                                        <!--                              type contrôle-->
                                        <v-col cols="12" class="mt-0 pt-0">
                                            <v-select class="mt-0" :items="typeControle" v-model="typeControlePicked" label="Contrôle souhaité" :rules="typeControleRules"></v-select>
                                        </v-col>

                                        <v-row justify="center">

                                            <!--                                  Dialogues informations-->
                                            <v-dialog v-model="dialogTypeControle" max-width="500" v-on:click:outside="closeDialogTypeControle">
                                                <v-card>
                                                    <v-card-title class="text-h5">
                                                        <v-row class="ma-1" justify="center">{{typeControlePicked}}</v-row>
                                                    </v-card-title>
                                                    <v-card-text v-if="typeControle.indexOf(typeControlePicked) === 2">
                                                        Vous souhaitez prendre un rendez-vous qui concerne une <span>{{typeControlePicked}}</span>.<br>
                                                        <v-row justify="center" class="ma-3"><span class="" style="font-weight: bold">Nous vous invitons à contacter directement votre centre de contrôle technique aux coordonnées qui suivent pour prendre votre rendez-vous :</span></v-row>
                                                        <v-row justify="center" class="mt-2 mr-5"><v-icon>{{icons.mdiPhone}}</v-icon> <span style="font-weight: bold"> {{this.$store.getters.centreSelected.ctr_telephone}}</span></v-row><br>
                                                        ​Si votre rapport de contrôle technique est défavorable pour présence de défaillance(s) majeure(s) : Lettre S sur le timbre certificat d'immatriculation, vous avez deux mois pour réaliser votre contre-visite.<br><br>
                                                        ​Si votre rapport de contrôle technique est défavorable pour défaillance(s) critique(s) : Lettre R sur le timbre certificat d'immatriculation (véhicule soumis à contre-visite), la contre-visite doit être réalisé le jour du contrôle technique défavorable.<br><br>
                                                        Faute de quoi un nouveau contrôle technique règlementaire sera à réaliser.<br><br>
                                                    </v-card-text>
                                                    <v-card-text v-if="typeControle.indexOf(typeControlePicked) === 3">
                                                        Vous souhaitez prendre un rendez-vous qui concerne un <span>{{typeControlePicked}}</span>.
                                                        <v-row justify="center" class="mx-2 px-4 mt-4"><span class="" style="font-weight: bold">Nous vous invitons à contacter directement votre centre de contrôle technique aux coordonnées qui suivent pour prendre votre rendez-vous :</span></v-row>
                                                        <v-row justify="center" class="mt-5 mr-5 pb-1"><v-icon>{{icons.mdiPhone}}</v-icon> <span style="font-weight: bold"> {{this.$store.getters.centreSelected.ctr_telephone}}</span></v-row><br>
                                                        Le contrôle technique complémentaire tel que défini au II de l'article R. 323-22 du code de la route est effectué dans les deux mois précédant l'expiration d'un délai d'un an à compter de la date du dernier contrôle technique périodique.
                                                        Sont soumis au contrôle technique complémentaire, les véhicules de catégorie N1 à l'exception des véhicules suivants :
                                                        <ul class="mt-2" style="list-style-type: disc">
                                                            <li>Les véhicules équipés de moteur à allumage commandé (essence) mis pour la première fois en circulation avant le 1er octobre 1972.</li>
                                                            <li>Les véhicules équipés de moteur à allumage par compression (diesel) mis pour la première fois en circulation avant le 1er janvier 1980.</li>
                                                            <li>Les véhicules dont l'énergie utilisée par le moteur est : GA, EL, AC, H2, HE, HH.</li>
                                                        </ul>
                                                    </v-card-text>
                                                    <v-card-text v-if="typeControle.indexOf(typeControlePicked) == 4">
                                                        Vous souhaitez prendre un rendez-vous qui concerne une <span>{{typeControlePicked}}</span>.
                                                        <v-row justify="center" class="mx-2 px-4 mt-4"><span class="" style="font-weight: bold">Nous vous invitons à contacter directement votre centre de contrôle technique aux coordonnées qui suivent pour prendre votre rendez-vous :</span></v-row>
                                                        <v-row justify="center" class="mt-5 mr-5 pb-1"><v-icon>{{icons.mdiPhone}}</v-icon> <span style="font-weight: bold"> {{this.$store.getters.centreSelected.ctr_telephone}}</span></v-row><br>
                                                        Si votre rapport de contrôle technique complémentaire est défavorable pour présence de défaillance(s) majeure(s) : Lettre S sur le timbre certificat d'immatriculation, vous avez deux mois pour réaliser votre contre-visite.<br><br>
                                                        Si votre rapport de contrôle technique complémentaire est défavorable pour défaillance(s) critique(s) : Lettre R sur le timbre certificat d'immatriculation (véhicule soumis à contre-visite), la contre-visite doit être réalisé le jour du contrôle technique défavorable.<br><br>
                                                        Faute de quoi un nouveau contrôle technique complémentaire ou un nouveau contrôle technique est à réaliser.
                                                    </v-card-text>
                                                    <v-card-text v-if="typeControle.indexOf(typeControlePicked) == 6">
                                                        Vous souhaitez prendre un rendez-vous qui concerne un <span>{{typeControlePicked}}</span>.
                                                        <v-row justify="center" class="mx-2 px-4 mt-4"><span class="" style="font-weight: bold">Nous vous invitons à contacter directement votre centre de contrôle technique aux coordonnées qui suivent pour prendre votre rendez-vous :</span></v-row>
                                                        <v-row justify="center" class="mt-5 mr-5 pb-1"><v-icon>{{icons.mdiPhone}}</v-icon> <span style="font-weight: bold"> {{this.$store.getters.centreSelected.ctr_telephone}}</span></v-row><br>
                                                        <!--Le contrôle volontaire vous permet de faire contrôler votre véhicule sur la même base règlementaire que celle appliquée lors d’un contrôle technique périodique.<br>
                                                        Le contrôle volontaire est un contrôle non obligatoire qui ne remplace pas un contrôle technique réglementaire conformément aux exigences de l’article R. 323-22 du Code de la route. .-->
                                                    </v-card-text>
                                                    <v-card-actions class="mt-0 pt-0">
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="green darken-1" text @click="closeDialogTypeControle">
                                                            OK
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-row>
                                    </v-row>
                                </v-col>
                            </v-row>


                         <!--   <v-row class="justify-start">
                                <v-divider style="border-width: 1px!important;border-color: #546E7A!important; max-width: 100%"></v-divider>
                            </v-row>-->


                            <v-card-title class="pa-0 ma-0 ml-4">
                                <v-row class="pa-0 ma-0">
                                    <v-col class="col-12 pa-0 ma-0">
                                        <span class="ma-0 pa-0 rounded" style="text-decoration:underline;text-underline-offset: 0.2em;">Vos coordonnées</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>

                            <!--                  Coordonnées-->
                            <v-row justify="center">
                                <v-col class="col-8">
                                    <v-row class="justify-start">
                                        <v-col class="col-12 mb-0 pb-0" >

                                            <!--                                  Selection genre/société-->
                                            <v-radio-group row class="justify-center" v-model="civility" :rules="basicRules">
                                                <v-row class="justify-start" align="center">

                                                    <v-col cols="8" sm="8" md="2" class="ml-4">
                                                        <v-radio label="Madame" value="Mme"></v-radio>
                                                    </v-col>

                                                    <v-col cols="8" sm="8" md="2" class="ml-4">
                                                        <v-radio label="Monsieur" value="Mr"></v-radio>
                                                    </v-col>

                                                    <v-col cols="8" sm="8" md="6" class="mx-0 px-0 ml-7" >
                                                        <v-radio class="" label="Autre : société, association..." value="Societe"></v-radio>
                                                    </v-col>

                                                </v-row>
                                            </v-radio-group>

                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" class="mt-0 pt-0">

                                        <!--                              Nom-->
                                        <v-col v-if="civility!=='Autre'" cols="6">
                                            <v-text-field v-model="lastName" :rules="nameRules" label="Nom" required></v-text-field>
                                        </v-col>

                                        <!--                              Prénom-->
                                        <v-col v-if="civility!=='Autre'" cols="6">
                                            <v-text-field v-model="firstName" :rules="prenomRules" label="Prénom" required></v-text-field>
                                        </v-col>

                                        <!--                              Si autre-->
                                        <v-col v-if="civility==='Autre'" cols="6">
                                            <v-text-field v-model="denomination" :rules="denominationRules" label="Dénomination" required></v-text-field>
                                        </v-col>

                                        <!--                              Email-->
                                        <v-col class="mt-0 pt-0" cols="6">
                                            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                        </v-col>

                                        <!--                              Phone-->
                                        <v-col class="mt-0 pt-0" cols="6">
                                            <v-text-field v-model="phone" :rules="phoneRules" label="Téléphone mobile" required></v-text-field>
                                        </v-col>


                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-row class="justify-end pa-0 ma-0">

                                <b-button @click.prevent="preventDefault" style="color:white" @click="validate" :disabled="!valid" class="btn btn-sushi btn-sm ma-0 rounded-pill">Valider</b-button>

                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import detailPaymentComponent from './payment/detailPaymentComponent'
import {
    mdiPhone,
    mdiCarSide,
    mdiTruck,
    mdiCarLiftedPickup,
    mdiRvTruck,
    mdiCarSports,
    mdiClock,
    mdiCalendar,
    mdiCurrencyEur,
    mdiCity
} from '@mdi/js';
export default {
    name: "FormReservation",
    components:{
      'payment-detail':detailPaymentComponent
    },
    data(){
        return {
            headers: [
                { text: '', value: 'category' },
                { text: '', value: 'value' },
            ],
            prices: [
                { category: 'Prix', value: '60€' },
                { category: 'Reduction', value: '50%' },
                { category: 'Total', value: '30€' },
            ],
            dayOfWeeks:['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            choicePayment:'',
            dialogTypeControle:false,
            typeControlePicked:'',
            typeControle:['Contrôle technique périodique obligatoire','Contrôle technique avant vente du véhicule','Contre-visite','Contrôle technique complémentaire','Contre-visite complémentaire','Contrôle Volontaire Complet','Contrôle Volontaire Partiel'], //'Contrôle Volontaire Complet',
            immatriculation:true,
            radiosImmatriculation:true,
            valid:false,
            energies : ['Essence','Diesel','GPL','Hybride','Electrique'],
            dialogFormReservation:false,
            firstName:'',
            lastName:'',
            denomination:'',
            email:'',
            phone:'',
            cvc:false,
            emailRules: [
                v => !!v || 'Un E-mail est nécessaire',
                v => /.+@.+\..+/.test(v) || 'E-mail non valide',
            ],
            nameRules: [
                v => !!v || 'Saisir votre nom',
                v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{2,}$)/.test(v) || 'Nom non valide'

            ],
            denominationRules: [
                v => !!v || 'Saisir votre dénomination ',
                v => /^([A-ZÉÈÊ]{2,}$|^[A-ZÉÈÊ]{2,}-[A-ZÉÈÊ]{1,}$)/.test(v) || 'Dénomination non valide'

            ],
            prenomRules:[
                v => !!v || 'Saisir votre prénom',
                v => /^[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || /^[A-ZÉÈÊ]{1}[a-zéèê]+-[A-ZÉÈÊ]{1}[a-zéèê]+$/.test(v) || 'Prénom non valide'

            ],
            phoneRules: [
                v => !!v || 'Un téléphone est nécessaire',
                v => /^0[67]\d{8}$/.test(v) && v.length <=10|| 'Mobile non valide',
            ],//[0-9] = \d
            typeControleRules:[
                v => !!v || 'Choisir le contrôle souhaité'
            ],
            vehiculeMarque:'',
            civilite:'',
            modeles:'',
            energie:'',
            basicRules: [v => !!v || 'Champs vide'],
            civility:'Mr',
            modeleVehicule:'',
            prestationSelected:'',
            energieObject:{
                'Essence':1,
                'Diesel':2,
                'GPL':5,
                'Hybride':4,
                'Electrique':4
            },
            immatriculationFrRules:[
                v => !!v || 'Une immatriculation est nécessaire',
                v => /^\d{3,4} [A-Z]{2,3} \d{2,3}$/.test(v) || /^[A-Z]{2}-\d{3,4}-[A-Z]{2}$/.test(v) || 'Format-SIV : AA-999-AA Format-FNI : 999 AAA 999 ou 9999 AA 999',
            ],
            immatriculationForeignRules:[
                v => !!v || 'Une immatriculation est nécessaire',
            ],
            icons:{
                mdiPhone : mdiPhone,
                car: mdiCarSide,
                truck: mdiTruck,
                lift: mdiCarLiftedPickup,
                RvTruck: mdiRvTruck,
                carSport: mdiCarSports,
                cash: mdiCurrencyEur,
                clock: mdiClock,
                calendar: mdiCalendar,
                city: mdiCity,
            }
        }
    },
    created(){
        this.$store.dispatch('getVehicules')

    },
    methods:{
        calculatePrice(){
            if(this.$store.getters.promotionsSelected.length>0) {
                const {prixInitial, promo} = this.$store.getters.finalPrice
                return promo.item.pourcentage === 0 ? parseFloat((prixInitial - promo.item.montant) + 1.99).toFixed(2) : parseFloat((prixInitial - (prixInitial * promo.item.pourcentage / 100)) + 1.99).toFixed(2)
            }
            return parseFloat(this.$store.getters.centreSelected.bestTarif.pst_tarif +1.99).toFixed(2)
        },
        formatTimeSlot: value =>value.match(/^[0-9]+:[0-9]+/)[0] !== undefined ?value.match(/^[0-9]+:[0-9]+/)[0] : false,
        retourFormReservation(){
            this.$store.commit("setRetourFormReservation",1)
            // this.$store.commit('setdateHoraireSelected',null)
            this.dialogFormReservation=false
        },
        preventDefault(e){
            e.preventDefault()
        },
        async validate(){
            this.$refs.form.validate()
            if(this.$refs.form.validate()){
                const idModele = this.vehiculeMarque.modeles.filter((modele)=> this.modeleVehicule === modele.movl_lib_modele)
                const vehicule = {
                    origineImmatriculation :this.radiosImmatriculation !== 'true',
                    immatriculation:this.immatriculation,
                    marqueVehicule:this.vehiculeMarque,
                    modeleVehicule: this.modeleVehicule,
                    idModeleVehicule:idModele[0].id_code_modeles,
                    controle:'VTP',
                    energie:this.energieObject[this.energie]
                }
                const getUser = (param) => param === 'Autre' ?
                    {
                        denomination : this.denomination,
                        email : this.email,
                        phone : this.phone
                    }:
                    {
                        civility : this.civility,
                        firstName : this.firstName,
                        lastName : this.lastName,
                        email: this.email,
                        phone: this.phone
                    }

                this.$store.commit('setReservationVehiculesInformations',vehicule)
                this.$store.commit('setReservationClientInformations',getUser(this.civility))

                await this.$store.dispatch("setClient",{client:getUser(this.civility),vehicule:vehicule})
                this.closeDialogFormReservation()
                //this.dialogFormReservation = false




            }
        },
        checkRadioImma(){
            this.immatriculation=''
        },
        closeDialogFormReservation(){
            this.dialogFormReservation = false

        },
        closeDialogTypeControle(){
            this.typeControlePicked='';
            this.dialogTypeControle=false

        },
        getModeles(){
            return this.vehiculeMarque ? this.vehiculeMarque.modeles : []

        },

        setFormReservation(){

            const item = {
                firstName:this.firstName,
                lastName:this.lastName,
                email:this.email,
                phone:this.phone,
                civility:this.civility,
                modele:this.modeleVehicule,
                marque:this.vehiculeMarque,
                prestation: this.$store.getters.prestationSelected,
                centre: this.$store.getters.centreSelected,
                dateHoraireSelected: this.$store.getters.dateHoraireSelected

            }
            this.$store.dispatch('addRdv',item)
        },
        resetNames(){
            this.firstName = '';
            this.lastName = '';
            this.denomination = ''
        }

    },
    watch:{
        'energie':{
            handler(value){

            }
        },
        'vehiculeMarque':{
            handler(value){
                this.modeles = value.modeles
            }
        },
        '$store.getters.dateHoraireSelected':{
            handler(value){
                if(this.$store.getters.dateHoraireSelected != null){
                    this.dialogFormReservation=true
                    this.energie = this.$store.getters.currentSearch.moteur
                }
            }
        },
        '$store.getters.retourFromFormPayment':{
            handler(value){
                this.dialogFormReservation=true
            }
        },
        'radiosImmatriculation':{
            handler(value){

            }
        },
        'immatriculation':{
            handler(value){
                if(this.radiosImmatriculation === "true"){
                    if(/^[A-Z]{2}$/.test(value))
                        this.immatriculation = value.concat('-');

                    if(/^[A-Z]{2}-\d{3}$/.test(value))
                        this.immatriculation = value.concat('-');

                    if(/^\d{4}$/.test(value))
                        this.immatriculation = value.concat(' ');

                    if(/^\d{3,4} [A-Z]{2}$/.test(value))
                        this.immatriculation = value.concat(' ')
                }
                if(/[a-z]/.test(value))
                    this.immatriculation = value.toString().toUpperCase()
            }
        },
        'lastName':{
            handler(value){
                this.lastName = value.toString().toUpperCase()
            }
        },
        'denomination':{
            handler(value){
                this.denomination = value.toString().toUpperCase()
            }
        },
        'firstName':{
            handler(value){
                if(/^[a-zéèê]$/.test(value)){
                    this.firstName=value.toString().toUpperCase()
                }
                if(/^[A-ZÉÈÊ][a-zéèê]+-[A-ZÉÈÊa-zéèê]$/.test(value)){
                    const char = value.substr(value.length-1).toUpperCase()
                    this.firstName = value.substr(0,value.length-1).concat(char)
                }

                if(/((?<=^[A-Z]{1})[a-zA-Z]+$)|((?<=\1-[A-Z]{1})[A-Za-z]+$)/.test(value)){
                    const char = value.substr(value.length-1).toLowerCase()
                    this.firstName = value.substr(0,value.length-1).concat(char)
                }

            }
        },
        'typeControlePicked':{
            handler(value){
                if(this.typeControle.indexOf(value) != 1 && this.typeControle.indexOf(value) != 0 &&  this.typeControlePicked!='' && this.typeControle.indexOf(value) != 5){
                    this.dialogTypeControle=true
                }
                if(this.typeControle.indexOf(value) == 5){
                    this.cvc=true
                    this.$store.commit('setCVC',true)
                }
                else{
                    this.cvc=false
                    this.$store.commit('setCVC',false)
                }

            }
        },
    }
}
</script>

<style scoped>


.text-main-title{
    font-family: Lato, sans-serif;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.78px;
    text-decoration-color: rgb(70,71,74);
    color: white;
    text-decoration:underline;
    text-underline-offset: 0.2em;
}
.text-title{
    font-family: Lato, sans-serif;
    font-size: 17px;
    font-weight: 900;
    letter-spacing: 0.78px;
    text-decoration-color: rgb(70,71,74);
    color: white;
}
.text-style{
    font-family: Lato, sans-serif;
    color: white;
    font-size: 14px!important;
}
.text-style-1{
    font-family: Lato, sans-serif;
    color: white;
    font-weight: bold;
    font-size: 16px!important;
}
</style>
