<template>
    <v-dialog
            :value="dialogTimeSlot"
            transition="dialog-top-transition"
            max-width="850"
            id="time-slot"
            persistent
            class="theme--light ma-0 pa-0"
            height="500"
            style="margin-top: 200px!important;"
            v-if="this.$store.getters.promotionsSelected"

    >
        <v-card style="overflow-x: hidden;">
            <v-toolbar style="background-color: var(--btn-color)" dark>
                <v-row justify="start" align="center">
                    <v-col class="col-2">
                        <v-btn
                                class="ma-1 green rounded-pill"
                                color="white"
                                plain
                                @click="retourTimeSlot"
                        >
                            Retour
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <v-row justify="start" class="pl-2 pt-2"><span class="text-style text-title">Centre de contrôle technique : </span><span class="text-title ml-1" style="text-align: center;text-transform: uppercase;font-weight: bold">{{$store.getters.centreSelected.ctr_nom}} {{$store.getters.centreSelected.adr_code_postal}} {{$store.getters.centreSelected.adr_ville}} </span></v-row>
                        <!--<span class="text-h8" style="font-size: 1.2rem">{{$store.getters.centreSelected.ctr_nom}} {{$store.getters.centreSelected.adr_ville}} </span><span v-if="dateSelected" style="font-size: 1.2rem"> {{ dayOfWeeks[dateSelected.getDay()]}} {{dateSelected.getDate()}} {{months[dateSelected.getMonth()]}} {{dateSelected.getFullYear()}}</span>-->
                    </v-col>
                    <v-col cols="2">
                        <v-row justify="end" align="center">
                            <!--<v-btn @click="closeDialogTimeSlot" style="background-color: white;">
                                <v-icon>{{mdiClose}}</v-icon>
                            </v-btn>-->
                            <v-btn
                                    icon
                                    dark
                                    @click="closeDialogTimeSlot"

                            >
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                    <!--<v-col>
                      <span v-if="dateSelected" style="font-size: 1.2rem"> {{ dayOfWeeks[dateSelected.getDay()]}} {{dateSelected.getDate()}} {{months[dateSelected.getMonth()]}} {{dateSelected.getFullYear()}}</span>
                    </v-col>-->
                </v-row>
            </v-toolbar>
            <v-row justify="center" class="mt-4">
                <span class="text-title">Choisissez votre heure de rendez-vous</span>
            </v-row>
            <v-row justify="center">
                <v-col cols="12">
                    <v-sheet class="my-5">
                        <v-slide-group center-active mandatory justify="center" v-model="daySelected" :value="daySelected" show-arrows>
                            <v-row justify="center" class="px-3">
                                <v-slide-item  class="my-5"  v-for="(day,index) in $store.getters.dispoDays" :key="index" v-show="!testDate(day)">
                                    <v-card class="mx-1" :elevation="index === daySelected ? '12' : ''" :class="index === daySelected ? 'selected' : ''"  v-if="day.dispo" v-on:click="refreshDays(day)" width="120px">
                                        <v-card-title  style="background-color: #0379AA;color: white" class="justify-center pa-0">
                                            <span style="text-align: center" class="">{{ months[day.date.getMonth()] }}</span>
                                        </v-card-title>
                                        <v-col class="ma-0">
                                            <v-row justify="center">
                                                <span v-if="day.dispo" class="line-through-tarif mt-0" style="font-size: 1.2em">{{ dayOfWeeks[day.date.getDay()] + ' - ' + day.date.getDate() }}</span>
                                                <span v-else class="line-through-tarif mt-0" style="font-size: 1.2em">Complet</span>
                                            </v-row>
                                            <v-row justify="center">
                                                <span v-if="getBestPrice(getPromoDay(day.date)).tarif" :class="$vuetify.breakpoint.smAndDown ? 'text-xs-price' : ''" class="line-through-tarif mt-0 text-md-body pa-0 ma-0" style="color:#F24C27;text-decoration:line-through">{{setDecimal($store.getters.centreSelected.bestTarif.pst_tarif) }}&#8364;</span>
                                                <span :class="$vuetify.breakpoint.smAndDown ? 'text-xs-price' : ''" class="ml-1 pa-0 ma-0">{{getBestPrice(getPromoDay(day.date)).tarif ? setDecimal(getBestPrice(getPromoDay(day.date)).tarif):setDecimal($store.getters.centreSelected.bestTarif.pst_tarif)}}&#8364;</span>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                </v-slide-item>
                            </v-row>
                        </v-slide-group>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-card-text>
                <v-row v-for="(time,index) in timeSlots" v-bind:key="index" align="center" class="blue-grey lighten-4 mt-3 rounded pa-0 ma-2">
                    <v-col>
                        <span v-if="getBestPrice(applyPromo(time)).tarif" class="line-through-tarif mt-1" style="color:#F24C27;text-decoration:line-through;font-size: 1em">{{setDecimal($store.getters.centreSelected.bestTarif.pst_tarif) }}&#8364;</span>
                        <span class="text-bold" style="font-size: 1.5rem"> {{getBestPrice(applyPromo(time)).tarif ? setDecimal(getBestPrice(applyPromo(time)).tarif): setDecimal($store.getters.centreSelected.bestTarif.pst_tarif)}}&#8364;</span>
                    </v-col>
                    <!-- <v-col>
                         -20%
                     </v-col>-->
                    <v-col>
                        <span class="text-sm-caption">Horaire</span> <span class="text-bold text-xl-normal" style="font-size: 1.5rem"> {{formatTimeSlot(time.debut)}}</span>
                    </v-col>
                    <v-col v-on:click="setTimeSlot(time)">
                        <b-button style="color:white" class="pa-2 px-0 ma-0 btn btn-sushi btn-sm rounded-pill" block>
                            Je réserve
                        </b-button>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import promotionHandler from '../../libraries/promotionHandler'
    export default {
        name: "TimeSlotComponent",
        data(){
            return {
                selectedPrestation:'',
                datesTimeSlot:'',
                daySelected: '',
                dialogTimeSlot:false,
                dateSelected:'',
                prestation:{pst_tarif:60,reduction:20},
                dayOfWeeks:['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                today:'',
                testHour:12,
                timeClicked:0,
                listeLignes:[],
                timeSlots:[]

            }
        },
        mounted(){
            const today = new Date()
            this.today = today
        },
        methods:{
            setDecimal(value){
                return parseFloat(value) % 1 === 0 ? parseFloat(value).toFixed(0) : parseFloat(value).toFixed(2)
            },
            getBestPrice(promotions){
                const {pst_tarif}=this.$store.getters.centreSelected.bestTarif
                const sortBestPromotion=(array,index=0,acc={},tarif=pst_tarif,result=pst_tarif)=>{
                    const {pourcentage,montant}= array[index]
                    if(parseInt(pourcentage)===0){
                        if(parseInt(result) > parseInt(tarif)-parseInt(montant)){
                            result =  parseInt(tarif)-parseInt(montant)
                            acc=array[index]
                        }
                    }else{
                        if(parseInt(result) > parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)){
                            result =   parseInt(tarif) - (parseInt(tarif)*parseInt(pourcentage)/100)
                            acc=array[index]
                        }
                    }
                    return array.length-1 > index? sortBestPromotion(array,index+1,acc,tarif,result) : {item:acc,tarif:result}
                }

                return promotions.length >0 || !promotions ? sortBestPromotion(promotions):false
            },
            applyPromoByDay(date){
                const centre = this.$store.getters.centreSelected
                if(date){
                    const currentDate = new Date(date)
                    const promotions = centre.bestTarif.promotions.filter((promo)=>{
                        const dateDebut = new Date(promo.dateDebut)
                        const dateFin = new Date(promo.dateFin)
                        return ((dateDebut<currentDate && dateFin> currentDate) || (this.compareDate(dateDebut,currentDate)) || (this.compareDate(dateFin,currentDate))) && promo.jours_usage[currentDate.getDay()] ==="true" && (promo.nb_total>0 || promo.nb_mensuel>0 || promo.nb_hebdo>0) && promotionHandler.isDateAvailable(date,promo)
                    })
                    return promotions
                }
                return []

            },
            applyPromo(item){
                const debut = this.formatTimeSlot(item.debut)
                const fin = this.formatTimeSlot(item.fin)

                const promotions = this.$store.getters.promotionsSelected
                const getMinute= value=>value.match(/(?<=:)\d{2}/)[0]
                const getHour=value =>value.match(/[0-9]{2}(?=\:)/)[0]


                const result = promotions.filter((promo)=>{

                    if(parseInt(getHour(promo.heureDebut)) < parseInt(getHour(debut)) && parseInt(getHour(promo.heureFin))> parseInt(getHour(fin))){
                        return true
                    }


                    if(parseInt(getHour(promo.heureDebut)) === parseInt(getHour(debut))){
                        if(parseInt(getMinute(promo.heureDebut)) <= parseInt(getMinute(debut))){
                            return true
                        }
                    }

                    if(parseInt(getHour(promo.heureFin)) === parseInt(getHour(fin))){
                        if(parseInt(getMinute(promo.heureFin)) >= parseInt(getMinute(fin))){
                            return true
                        }
                    }
                    return false
                })
                return result


            },
            getPromoDay(date){
                const centre = this.$store.getters.centreSelected
                if(date){
                    const currentDate = new Date(date)
                    const promotions = centre.bestTarif.promotions.filter((promo)=>{
                        const dateDebut = new Date(promo.dateDebut)
                        const dateFin = new Date(promo.dateFin)
                       // console.log(`${date}  is available == ${promotionHandler.isDateAvailable(date,promo)}`)
                        return ((dateDebut<currentDate && dateFin> currentDate) || (this.compareDate(dateDebut,currentDate)) || (this.compareDate(dateFin,currentDate))) && promo.jours_usage[currentDate.getDay()] ==="true" && (promo.nb_total>0 || promo.nb_mensuel>0 || promo.nb_hebdo>0) && promotionHandler.isDateAvailable(date,promo)
                    })
                    return promotions
                }
                return []
            },
            refreshDays(value) {
                this.$store.commit("setPromotionsSelected",this.applyPromoByDay(value.date))
                this.$store.commit("setDateSelected",value)
                this.bootstrap()
            },
            bootstrap(){
                const allPlages = this.$store.getters.dateSelected.dispo.ListeLignes[0].Ligne.map((value)=>({idLigne:value.IdentifiantLigne[0],plage:value.ListePlages[0].Plage}))

                const sortData = (array,index=0,acc=[])=>
                    array.length -1 > index  ?
                        sortData(
                            array,
                            index+1,[...acc,...array[index].plage.map((item)=>({idLigne:array[index].idLigne,debut:item.Debut[0],fin:item.Fin[0]}))]
                        ) :
                        [
                            ...acc,
                            ...array[index].plage.map((item)=>({idLigne:array[index].idLigne,debut:item.Debut[0],fin:item.Fin[0]}))
                        ]

                const res = sortData(allPlages)
                const isInArray = (array,param=0) => array.some((val)=>val.debut===param.debut && val.fin === param.fin)
                const plagesCleaned = res.reduce((acc,item) => isInArray(acc,item) ? [...acc] : [...acc,item],[])

                const ascending = (a, b) =>{
                    if(parseInt(a.debut.match(/^[0-9]{2}/)[0]) < parseInt(b.debut.match(/^[0-9]{2}/)[0])) return -1;
                    if(parseInt(a.debut.match(/^[0-9]{2}/)[0]) > parseInt(b.debut.match(/^[0-9]{2}/)[0])) return 1;
                    if(parseInt(a.debut.match(/(?<=:)[0-9]{2}(?=:)/)[0]) < parseInt(b.debut.match(/(?<=:)[0-9]{2}(?=:)/)[0])) return -1;
                    if(parseInt(a.debut.match(/(?<=:)[0-9]{2}(?=:)/)[0]) > parseInt(b.debut.match(/(?<=:)[0-9]{2}(?=:)/)[0])) return 1;
                    return 0
                }

                this.timeSlots = plagesCleaned.sort(ascending)
                this.dateSelected = this.$store.getters.dateSelected.date
                this.selectedPrestation = this.$store.getters.prestationSelected

            },
            formatTimeSlot: value =>value.match(/^[0-9]+:[0-9]+/)[0],
            setTimeSlot(date){
                if(date === this.$store.getters.dateHoraireSelected)
                this.$store.commit('setdateHoraireSelected',null)
                this.$store.commit('setdateHoraireSelected',date)
                this.$store.commit('setFinalPrice',{prixInitial:parseFloat(this.$store.getters.centreSelected.bestTarif.pst_tarif).toFixed(2),promo:this.getBestPrice(this.applyPromo(date))})
                this.dialogTimeSlot= false
            },
            closeDialogTimeSlot(){
                this.dialogTimeSlot=false
            },
            formatMinute(minutes){
                return minutes.toString().length == 1 ? `0${minutes}` : minutes
            },
            retourTimeSlot(){
                this.$store.commit("setRetourTimeSlot",1)
                this.$store.commit("setDateSelected",null)
                this.daySelected = ''
                this.dialogTimeSlot = false
            },
            getDate(date){
                const today = new Date()
                if(date.getFullYear() == today.getFullYear()){
                    if(date.getMonth() == today.getMonth()){
                        if(date.getDate() == today.getDate()){
                            if(date.getHours() < today.getHours())
                                return false

                        }
                    }
                    return true
                }
            },
            testDate(day) {
                let today = new Date()
                today.setHours(0,0,0,0)
                return day.date < today
            },
            compareDate : (date1,date2)=> date1.getFullYear() === date2.getFullYear()
                && date1.getMonth() === date2.getMonth()
                && date1.getDate() === date2.getDate()
        },
        watch:{
            '$store.getters.dateSelected':{
                handler(){
                    if(this.$store.getters.dateSelected != null){
                        this.daySelected = this.$store.getters.dispoDays.indexOf(this.$store.getters.dateSelected)
                        this.dialogTimeSlot = true
                        this.bootstrap()
                    }
                }
            },
            '$store.getters.retourFormReservation':{
                deep:true,
                handler(){
                    this.dialogTimeSlot = true
                }
            }
        }
    }// background-color: #DBD21A !important;
</script>

<style>
    .text-title{
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.78px;
        text-decoration-color: rgb(70,71,74);
    }
    .selected
    {
        bottom: 10px;
    }
    .v-dialog{
        margin-top: 120px;
    }
</style>
